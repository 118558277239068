import api from '@api/api.js';

import Logger from '@utils/logger.js';
const logger = new Logger('curated-lists');

const curatedListsUrl = 'collections';
export const defaults = {
  curatedLists: null,
  error: null,
  fetching: false,
};

const state = { ...defaults };

const actions = {
  getAllCuratedLists ({ commit }) {
    commit('setFetching');
    api.get(`${curatedListsUrl}`).then((response) => {
      commit('setCuratedLists', response);
    })
      .catch((error) => {
        logger.error(error);
        commit('setError', error);
      });
  },
};

export const mutations = {
  setCuratedLists (state, response) {
    state.curatedLists = response;
    state.fetching = false;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

