/**
 * Contains bundle and standalone plans available to a user while
 * maintaining their current billing interval, and the calls for switching between those.
 */

// TODO: Rename 'plan-types' ? Something that makes sense w/ plan-intervals

import api from '@api/api.js';
import config from '@configs/__BUILDENV__.json';

import { ADDON_STATUSES } from '@utils/constants.js';

// Payload inlcudes the LTO coupon codes set per-product.
// TODO: Remove once the P+ LTO period is over.
import bundlePayload from '@pages/enhanced-purchase/bundle-payload.json';

export const URLS = {
  updatePlan: 'user/plan',
  availableBundles: 'user/availableplans/bundleonly',
  pendingPlan: 'user/plan/pending',
};

/**
 * Parses bundle API response into more useable data format.
 * This method should only be called on bundle plans.
 * @param {Object} plan - Plan object from API
 * @returns {Object}
 */
export const buildBundleMetadata = (plan) => {
  if (!plan.addOns || !plan.addOns.length) {
    throw 'Method can only be called on bundle plans.';
  }

  const bundle = {
    ...plan,
  };

  // Assume 1 addon for now - use addOn id, name, and status to represent bundle metadata
  bundle.addOn = bundle.addOns[0];
  bundle.id = bundle.addOn.addOnId;
  bundle.status = bundle.addOn.status;
  bundle.bundleName = bundle.addOn.name;
  delete bundle.addOns;

  return bundle;
};

const defaults = {
  currentPlan: null,
  plans: [],
  fetching: false,
  fetched: false,
  error: null,
};

const state = { ...defaults };

export const getters = {
  /**
   * Bundles available to the user. Includes current plan if they have a bundle.
   * NOTE: This was to populate the 'manage bundle' page, hence adding their current bundle, which probably was a mistake.
   * @param {*} state
   * @returns {Object[]}
   */
  availableBundles (state) {
    let bundles = [];

    if (state.currentPlan && state.currentPlan.addOns) {
      bundles.push(state.currentPlan);
    }

    bundles = bundles.concat(state.plans.filter((plan) => !!plan.addOns && plan.addOns.length > 0));

    if (bundles && bundles.length) {
      // HACK: Sort '_PREMIUM' bundle ids last
      return bundles.map(buildBundleMetadata).sort((bundle) => bundle.id.indexOf('_PREMIUM'));
    }

    return null;
  },
  /**
   * Bundles available to the user, NOT including their current plan; a slight hack to account for the above getter.
   * @param {*} state 
   * @returns 
   */
  availableBundlesWithoutCurrent (state) {
    const bundles = state.plans.filter((plan) => !!plan.addOns && plan.addOns.length > 0);

    if (bundles && bundles.length) {
      // HACK: Sort '_PREMIUM' bundle ids last
      return bundles.map(buildBundleMetadata).sort((bundle) => bundle.id.indexOf('_PREMIUM'));
    }

    return null;
  },
  /**
   * Standalone plans available to the user.
   * If the user has a bundle, this will be the plan they shift to
   * when canceling their bundle/addon. There _should_ only ever be
   * one available standalone plan returned from this endpoint.
   * @param {*} state
   * @returns {(Object|null)}
   */
  availableStandalonePlan (state) {
    return state.plans.find((plan) => !plan.addOns || !plan.addOns.length) || null;
  },
  /**
   * If a user has a bundle, returns parsed bundle data.
   * @param {*} state
   * @returns {(Object|null)}
   */
  currentBundle (state) {
    if (!state.currentPlan || !state.currentPlan.addOns || !state.currentPlan.addOns.length) {
      return null;
    }

    return buildBundleMetadata(state.currentPlan);
  },
  /**
   * If a user has a pending bundle, returns parsed bundle data.
   * @param {*} state
   * @param {*} getters
   * @returns {(Object|null)}
   */
  pendingBundle (state, getters) {
    return getters.availableBundles && getters.availableBundles.find((bundle) => bundle.status === ADDON_STATUSES.pending) || null;
  },
};

export const actions = {
  /**
   * Gets a list of plans and bundles available to the user at the
   * user's current billing duration.
   * @param {Object} context - Vuex context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   * @returns {Promise} - Promise resolving with available plans
   */
  async getAvailableBundles ({ commit, state }) {
    // Make an API call if one is not in progress
    if (!state.fetching) {
      try {
        commit('setFetching', true);
        // todo: WEB-4 move non-reactive config options to a static file
        const payload = config.LTOCouponEnabled ? bundlePayload : { plans: [] };
        const response = await api.post(URLS.availableBundles, payload);
        commit('setResponse', response);
      } catch (error) {
        commit('setError', error);
        commit('setFetching', false);
        commit('setFetched', true);
      }
    }
  },
  /**
   * Attempt to update user's bundle.
   * @param {*} context
   * @param {Object} payload - Bundle purchase payload
   * @param {string} payload.productCode - Plan product code
   * @param {string} [payload.couponCode] - Optional coupon code
   * @param {string[]} [payload.addOns] - Optional array of addOn IDs
   * @returns
   */
  submitBundle (context, payload) {
    return api.put(URLS.updatePlan, payload);
  },
  /**
   * Makes a delete call to the pending plan URL.
   * @returns {Promise}
   */
  restoreBundle () {
    return api.del(URLS.pendingPlan);
  },
  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  setResponse (state, response) {
    Object.assign(state, defaults);
    state.currentPlan = response.currentPlan || null;
    state.plans = response.plans || [];
    state.fetching = false;
    state.fetched = true;
  },
  setFetching (state, value) {
    state.fetching = value;
  },
  setFetched (state, value) {
    state.fetched = value;
  },
  setError (state, error) {
    state.error = error;
  },
  reset (state) {
    Object.assign(state, defaults);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
