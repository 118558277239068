import api from '@api/api.js';
import { imageBuilder } from '@utils/image-builder.js';
import { ASSET_TYPES } from '@utils/constants.js';
import Logger from '@utils/logger.js';

import config from '@configs/__BUILDENV__.json';

const logger = new Logger('home-modules');

const modulesUrl = `modules?supportedModules=${config.supportedHomeModules}`;

export const defaults = {
  batchId: null,
  modules: [],
  error: null,
  fetching: false,
};

const state = { ...defaults };

export const getters = {
  homePromos: (state) => (state.modules[0] && state.modules[0].promotions ? state.modules[0].promotions : null),
  homeShelves (state) {
    // disregard promos  when handing off modules for the shelves
    const shelves = state.modules.filter((m) => m.moduleType.toLowerCase() !== 'carousel');
    const parsedShelves = parseShelves(shelves);
    return parsedShelves;
  },
};

const actions = {
  /**
   * @param {Object} context - Vuex context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   */
  getModules ({ commit }, options = {}) {
    if (!state.modules.length || options.cacheBust) {
      commit ('setFetching');
      return api.get(`${modulesUrl}`).then((response) => {
        commit('setModules', response);
      })
        .catch((error) => {
          logger.error(error);
          commit('setError', error);
        });
    }
  },
};

export const mutations = {
  setModules (state, response) {
    Object.assign(state, response);
    state.fetching = false;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
};


const parseShelves = (shelves) => shelves.map((shelf) => {
  // TODO: This parsing should get moved to a wrapper component,
  // similar to PromoCarouselWrapper.
  const shelfData = shelf;
  const shelfContext = `${shelf.moduleType}_SHELF`;
  const shelfType = shelf.moduleType.toLowerCase();
  if (shelfType === 'free_full_episodes') {
    if (shelfData.titles) {
      shelfData.titles.forEach((title) => {
        title.displayImage = title.images.find((img) => img.type === 'MODULAR_HOMEPAGE_COLLECTION_FFE_EPISODE_LIST_DISPLAY')?.url;
        title.hoverImage = title.images.find((img) => img.type === 'MODULAR_HOMEPAGE_COLLECTION_FFE_EPISODE_LIST_DISPLAY_HOVER')?.url;
      });
    }
  } else {
    if (shelfData.cards) {
      shelfData.cards.forEach((card) => {
        // everywhere else we expect a type of 'SERIES', but this API returns 'Series'
        if (card.type === 'Series') {
          card.type = ASSET_TYPES.series;
        }
        card.images.forEach((image) => {
          image.url = imageBuilder(image.url, shelfContext, shelfType, true);
          card.displayImage = image.url;
        });
      });
    }
  }
  return { ...shelfData };
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
