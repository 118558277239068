/**
 * Vuex module for managing an enhanced purchase flow.
 */

const STORAGE_VAR = 'sho:purchase';

const getDefaults = () => ({
  purchaseType: null,
  addOns: [],
  couponCode: null,
  productCode: null,
});

export const state = getDefaults();

export const mutations = {
  initializeState (state) {
    const session = localStorage.getItem(STORAGE_VAR);
    if (session) {
      const { purchaseType, productCode, addOns, couponCode } = JSON.parse(session);
      state.purchaseType = purchaseType || null;
      state.productCode = productCode || null;
      state.addOns = addOns || null;
      state.couponCode = couponCode || null;
    }
  },
  setPurchaseType (state, purchaseType) {
    state.purchaseType = purchaseType;
    localStorage.setItem(STORAGE_VAR, JSON.stringify(state));
  },
  setProducts (state, { productCode, addOns = [], couponCode = null }) {
    state.productCode = productCode;
    state.addOns = addOns;
    state.couponCode = couponCode;
    localStorage.setItem(STORAGE_VAR, JSON.stringify(state));
  },
  resetProducts (state) {
    state.productCode = null;
    state.addOns = null;
    state.couponCode = null;
    localStorage.setItem(STORAGE_VAR, JSON.stringify(state));
  },
  reset (state) {
    Object.assign(state, getDefaults());
    localStorage.removeItem(STORAGE_VAR);
  },
};

export const actions = {
  /**
   * Sets purchase type to state and localStorage.
   * @param {Object} context - Vuex module context.
   * @param {string} purchaseType - Purchase type
   */
  setPurchaseType ({ commit }, purchaseType) {
    commit('setPurchaseType', purchaseType);
  },
  /**
   * Caches products selected by the plan selector.
   * @param {Object} context - Vuex module context.
   * @param {Object} selectedProducts
   * @param {string} selectedProducts.productCode - Plan product code
   * @param {string[]} [selectedProducts.addOns] - Optional array of bundled addons
   * @param {string} [selectedProducts.couponCode] - Pre-applied coupon code 
   */
  setProducts ({ commit }, { productCode, addOns, couponCode }) {
    commit('setProducts', { productCode, addOns, couponCode });
  },
  /**
   * Resets selected products.
   * @param {Object} context - Vuex module context.
   */
  resetProducts ({ commit }) {
    commit('resetProducts');
  },
  /**
   * Resets state and localStorage.
   * @param {Object} context - Vuex module context.
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
