const PaywallLoader = () => import('@pages/paywall/PaywallLoader.vue');
const PartnerAuthRedirectPage = () => import('@pages/paywall/PartnerAuthRedirectPage.vue');
const StartFlowPage = () => import('@pages/paywall/StartFlowPage.vue');

export default [
  /**
   * Requests the link account page from the Paywall Loader.
   */
  {
    path: '/link-account',
    name: 'linkAccount',
    component: PaywallLoader,
    props: { page: 'linkAccount' },
    meta: {
      section: 'paywall',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },

  /**
   * Full-screen external auth redirect page.
   */
  {
    path: '/confirm-auth/:partnerId',
    name: 'externalAuthConfirmation',
    component: PartnerAuthRedirectPage,
    props: (route) => ({
      partnerId: route.params.partnerId,
      code: route.query.code,
      error: route.query.error,
    }),
    meta: {
      section: 'paywall',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },

  /**
   * Optimum Partner Auth Route
   */
  {
    path: '/optimum',
    name: 'partnerAuthOptimum',
    component: StartFlowPage,
    props: { flowId: 'partner-auth', partnerId: 'optimum' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * Paramount+ Partner Auth Route
   */
  {
    path: '/paramountplus',
    alias: ['/cbs', '/cbsaa'],
    name: 'partnerAuthParamountPlus',
    component: StartFlowPage,
    props: { flowId: 'partner-auth', partnerId: 'paramountplus' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * Optimum Partner Auth Route
   */
  {
    path: '/spotify',
    name: 'partnerAuthSpotify',
    component: StartFlowPage,
    props: { flowId: 'partner-auth', partnerId: 'spotify' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * Sam's Club Partner Auth Route
   */
  {
    path: '/samsclub',
    name: 'partnerAuthSamsClub',
    component: StartFlowPage,
    props: { flowId: 'partner-auth', partnerId: 'samsclub' },
    meta: {
      section: 'paywall',
    },
  },
];
