import api from '@api/api.js';
const planUrl = 'user/plan';
const reactivateUrl = 'user/plan/pending';

export const defaults = {
  currentPlan: {
    productCode: null,
    priceInCents: 0,
    basePriceInCents: 0,
    billingInterval: null,
    name: null,
    nextBillingDate: null,
    subscriptionPlanSummary: null,
    addOns: [],
  },
  pendingPlan: {
    productCode: null,
    priceInCents: 0,
    basePriceInCents: 0,
    billingInterval: null,
    name: null,
    nextBillingDate: null,
    subscriptionPlanSummary: null,
  },
  accountInfo: {
    billingType: null,
    accountCode: null,
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zipcode: null,
    lastFourDigits: null,
    paymentMethod: null,
    cardType: null,
    year: null,
    month: null,
    eligibleForWinback: false,
    hasPurchased: false,
    subscriptionStatus: null,
    subscriptionExpiration: 0,
    trialStatus: null,
    trialExpiration: 0,
    accountCredit: 0,
    transactions: [],
    inDunningPeriod: false,
  },
  accessError: null,
  formError: null,
  error: null,
  hasPendingPlan: false,
  fetching: false,
  fetched: false,
};

export const state = { ...defaults };

export const getters = {
  /**
   * Boolean indicating if user's current plan contains add ons
   * @param {Object} state - Vuex module state
   * @returns {boolean} - True if user's current plan contains add ons
   */
  hasAddOns: (state) => !!(state.currentPlan.addOns &&
    state.currentPlan.addOns.length),

  /**
   * Boolean indicating if user's pending plan contains add ons
   * @param {Object} state - Vuex module state
   * @returns {boolean} - True if user's pending plan contains add ons
   */
  hasPendingAddOns: (state) => !!(state.pendingPlan.addOns &&
    state.pendingPlan.addOns.length),

  /**
   * Boolean indicating if user has any add ons not in a canceling state
   * @param {Object} state - Vuex module state
   * @param {Object} getters - Vuex module getters
   * @returns {boolean} - True is user has any add ons not in a canceling state
   */
  hasAddOnsNotCanceling: (state, getters) => {
    let addOnsNotCanceling = [];
    let currentAddOnsNotCanceling = [];
    let pendingAddOnsNotCanceling = [];

    if (getters.hasAddOns) {
      currentAddOnsNotCanceling = state.currentPlan.addOns.filter((addOn) => addOn.status !== 'canceling');
      addOnsNotCanceling = addOnsNotCanceling.concat(currentAddOnsNotCanceling);
    }

    if (getters.hasPendingAddOns) {
      pendingAddOnsNotCanceling = state.pendingPlan.addOns.filter((addOn) => addOn.status !== 'canceling');
      addOnsNotCanceling = addOnsNotCanceling.concat(pendingAddOnsNotCanceling);
    }

    return !!addOnsNotCanceling.length;
  },

  /**
   * Returns primary add on from current plan state, if it exists
   * @param {Object} state - Vuex module state
   * @param {Object} getters - Vue module getters
   * @returns {null|Object} - primary add on object or false
   */
  primaryAddOn: (state, getters) => {
    if (!getters.hasAddOns) {
      return null;
    }
    // Even though the primary addon is currently Paramount+, the type is still 'CBS' in the backend,
    // and there are no plans to change this, according to Dan.
    return state.currentPlan.addOns.find((addOn) => addOn.type === 'CBS') || null;
  },

  /**
   * Returns a pending interval change plan, if present.
   * @param {Object} state - Vuex module state
   * @returns {(Object|null)}
   */
  pendingIntervalPlan: (state) => {
    const currentPlan = state.currentPlan?.productCode ? state.currentPlan : null;
    const pendingPlan = state.pendingPlan?.productCode ? state.pendingPlan : null;
    
    if (currentPlan && pendingPlan && currentPlan.billingInterval !== pendingPlan.billingInterval) {
      return pendingPlan;
    }

    return null;
  },

  /**
   * Returns a pending bundle change plan, if present.
   * NOTE: As is, this will return a pending bundle for an interval change as addOn IDs are different for different intervals.
   *       To make this more accurate, bundles need an ID, or _ESSENTIAL_/_PREMIUM_ would need to be checked.
   * @param {Object} state - Vuex module state
   * @returns {(Object|null)}
   */
  pendingBundlePlan: (state) => {
    // Assumes single addon per bundle for now, keys off of addOn ID
    const currentBundle = state.currentPlan?.addOns && state.currentPlan.addOns.length ? state.currentPlan.addOns[0] : null;
    const pendingBundle = state.pendingPlan?.addOns && state.pendingPlan.addOns.length ? state.pendingPlan.addOns[0] : null;
    
    if (currentBundle && pendingBundle && currentBundle.addOnId !== pendingBundle.addOnId) {
      return state.pendingPlan;
    }

    return null;
  },
};

export const actions = {
  /**
   * Gets user's current plan.
   * @param {Object} context - Vuex context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   * @returns {Promise} - Promise resolving with current plan, pending plan, and account info
   */
  async getUserPlan ({ commit, state }) {
    // Make an API call if user has requested fresh data,
    // or if data has never been fetched, and a fetch is not in progress
    if (!state.fetching) {
      commit('setFetching', true);
      commit('resetAccessError');
      try {
        const response = await api.get(planUrl);
        commit('setUserPlan', response);
      } catch (error) {
        commit('setAccessError', error);
        commit('setFetched', true);
      }
    }
  },

  /**
   * Reactivates user's plan via DELETE to user/plan/pending
   * If reactivate is successful, assigns the response to currentPlan in the store.
   * If not successful, assigns the response error to state.
   * @param {Object} context - Store context
   * @returns {Promise} - Promise resolving with current plan, pending plan, and account info
   */
  async reactivate ({ commit }) {
    try {
      await api.del(reactivateUrl);
      const response = await api.get(planUrl);
      commit('setUserPlan', response);
    } catch (error) {
      commit('setError', error);
    }
  },

  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

const mutations = {
  setUserPlan (state, response) {
    state.currentPlan = { ...defaults.currentPlan, ...response.currentPlan };
    state.accountInfo = { ...defaults.accountInfo, ...response.accountInfo };
    // Why is this using 'hasPendingPlan' instead of pendingPlan: null default? Reactivity?
    if (response.pendingPlan) {
      state.pendingPlan = { ...defaults.pendingPlan, ...response.pendingPlan };
      state.hasPendingPlan = true;
    } else {
      state.pendingPlan = defaults.pendingPlan;
      state.hasPendingPlan = false;
    }
    state.fetching = false;
    state.fetched = true;
  },

  // page-level access error, ie plans unavailable (gets roadblock layout)
  setAccessError (state, error) {
    state.accessError = error;
    state.fetching = false;
  },

  resetAccessError (state) {
    state.accessError = null;
  },

  // error local to form, ie incorrect password (error appears in form)
  setFormError (state, error) {
    state.formError = error.body || error;
    state.fetching = false;
  },

  // generic server error
  setError (state, error) {
    state.error = error.body || error;
    state.fetching = false;
  },

  resetFormError (state) {
    state.formError = null;
  },

  setFetching (state, value) {
    state.fetching = value;
  },
  
  setFetched (state, value) {
    state.fetched = value;
  },

  reset (state) {
    Object.assign(state, defaults);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
