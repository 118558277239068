/**
 * AdobeLaunchTracker class to hold analytics data and make tracking calls
 * to launch
 */
import Logger from '@utils/logger.js';

const logger = new Logger('Adobe Launch');

export default class AdobeLaunchTracker {
  constructor () {
    this.adobeMedia = null;
    this.tracker = null;
    this.mediaObject = null;
    this.contextData = null;
    this.adObject = null;
    this.adBreakObject = null;
    this.tveUserId = null;
  }

  init () {
    if (this.tracker) {
      return;
    }

    try {
      this.adobeMedia = window.MEDIA_ANALYTICS.Media;
      this.tracker = this.adobeMedia.getInstance();
    } catch (e) {
      logger.error('Adobe Media Tracker not installed', e);
    }
  }

  setTveUserId (id) {
    this.tveUserId = id;
  }

  // This should be used to call all of the other methods
  // it protects against the global analytics object not being available
  callMethod (methodName, ...args) {
    if (!this[methodName]) {
      console.error('[Adode Analaytics] method doesn\'t exist.');
      return;
    }

    // fail silently, message about tracker not being available sent during init
    if (!this.tracker) {
      return;
    }

    try {
      this[methodName](...args);
    } catch (e) {
      console.error('[Adobe Analytics]', e);
    }
  }

  createMediaObject ({ name, id, length = 0, streamType, metaData = {}, hasResumePoint = false }) {
    this.mediaObject = this.adobeMedia.createMediaObject(name, String(id), length, streamType, 'video');

    // Set to true if this is a resume playback scenario (not starting from playhead 0)
    this.mediaObject[this.adobeMedia.MediaObjectKey.MediaResumed] = hasResumePoint;

    if (this.tveUserId) {
      metaData.tveUserId = this.tveUserId;
    }
    this.contextData = metaData;
  }

  trackSessionStart () {
    logger.log('trackSessionStart', this.mediaObject['media.name']);
    this.tracker.trackSessionStart(this.mediaObject, this.contextData);
  }

  // Track the end of a viewing session. Call this method even if the user does not view the media to completion.
  trackSessionEnd () {
    if (this.mediaObject) {
      logger.log('trackSessionEnd', this.mediaObject['media.name']);
      this.tracker.trackSessionEnd();
      this.mediaObject = null;
    }
  }

  onPlay () {
    logger.log('onPlay');
    this.tracker.trackPlay();
  }

  onPause () {
    logger.log('onPause');
    this.tracker.trackPause();
  }

  onSeekStart () {
    this.tracker.trackEvent(this.adobeMedia.Event.SeekStart);
  }

  onSeekComplete () {
    this.tracker.trackEvent(this.adobeMedia.Event.SeekComplete);
  }

  onBufferStart () {
    this.tracker.trackEvent(this.adobeMedia.Event.BufferStart);
  }

  onBufferComplete () {
    this.tracker.trackEvent(this.adobeMedia.Event.BufferComplete);
  }

  onAdStart ({ refid, id, runtime, type }) {
    logger.log('onAdStart');

    // AdBreak Info - For now we only have pre-roll, so it will always be the first ad break and always at time 0
    this.adBreakObject = this.adobeMedia.createAdBreakObject('pre-roll', 1, 0);

    // Ad Info
    this.adObject = this.adobeMedia.createAdObject(type, String(id), 1, runtime);

    // Ad context data - advertiser, campaign, etc..
    const adContextData = {};
    adContextData['a.media.ad.creative'] = refid;

    this.tracker.trackEvent(this.adobeMedia.Event.AdBreakStart, this.adBreakObject);
    this.tracker.trackEvent(this.adobeMedia.Event.AdStart, this.adObject, adContextData);
  }

  onAdComplete () {
    if (this.adObject && this.adBreakObject) {
      logger.log('onAdComplete');
      this.tracker.trackEvent(this.adobeMedia.Event.AdComplete);
      this.tracker.trackEvent(this.adobeMedia.Event.AdBreakComplete);
      this.adBreakObject = null;
      this.adObject = null;
    }
  }

  onAdSkip () {
    if (this.adObject && this.adBreakObject) {
      logger.log('onAdSkip');
      this.tracker.trackEvent(this.adobeMedia.Event.AdSkip);
      this.tracker.trackEvent(this.adobeMedia.Event.AdBreakComplete);
      this.adBreakObject = null;
      this.adObject = null;
    }
  }

  // Track media complete. Call this method only when the media has been completely viewed.
  onComplete () {
    if (this.mediaObject) {
      logger.log('onComplete', this.mediaObject['media.name']);
      this.tracker.trackComplete();
    }
  }

  // Current playhead in seconds. For video-on-demand (VOD), the value is specified in seconds from the beginning of the media item.
  // For live streaming, the value is specified as the number of seconds since midnight UTC on that day.
  onPlayheadUpdate (playhead) {
    this.tracker.updatePlayhead(playhead);
  }

  onMuteChange (isMuted) {
    logger.log('onMuteChange', isMuted);
    const info = this.adobeMedia.createStateObject(this.adobeMedia.PlayerState.Mute);
    const event = isMuted ? this.adobeMedia.Event.StateStart : this.adobeMedia.Event.StateEnd;

    this.tracker.trackEvent(event, info);
  }


  onFullscreenChange (fullscreen) {
    logger.log('onFullscreenChange', fullscreen);
    const info = this.adobeMedia.createStateObject(this.adobeMedia.PlayerState.FullScreen);
    const event = fullscreen ? this.adobeMedia.Event.StateStart : this.adobeMedia.Event.StateEnd;

    this.tracker.trackEvent(event, info);
  }

  onCaptionsChange (captionsEnabled) {
    logger.log('onCaptionsChange', captionsEnabled);
    const info = this.adobeMedia.createStateObject(this.adobeMedia.PlayerState.ClosedCaption);
    const event = captionsEnabled ? this.adobeMedia.Event.StateStart : this.adobeMedia.Event.StateEnd;

    this.tracker.trackEvent(event, info);
  }

  destroy () {
    this.mediaObject = null;
    this.adBreakObject = null;
    this.adObject = null;
    if (this.tracker) {
      logger.log('destroy');
      this.tracker.destroy();
      this.tracker = null;
    }
  }
}
