import Logger from '@utils/logger.js';
const logger = new Logger('settings-notification');

// How long a notification should stay visible
const notificationTimeMs = 5 * 1000;

/**
 * Defaults for the Settings Noticiation store.
 * @typedef {Object} SettingsNotificationDefaults
 * @property {Object} notification - Item metadata for notification display.
 */

/**
  * Notification properties
  * @typedef {Object} SettingsNotification
  * @property {String} content - Text content to display in the notification
  * @property {String} linkContent - Link text to display
  * @property {Boolean} static - Wheather or not notification should auto-close
  * @property {String} cta - Value to emit on link or banner click
  */

/**
 * Returns immutable Settings Notification state defaults.
 * @returns {SettingsNotificationDefaults} - Default state data.
 */
export const getDefaults = () => ({
  notification: null,
  notificationAction: '',
});

export const state = getDefaults();

export const mutations = {
  /**
   * Set the notification
   * @param {Object} state 
   * @param {SettingsNotification} notification 
   */
  setNotification (state, notification) {
    state.notification = notification;
  },
  /**
   * Set the notification action
   * @param {Object} state
   * @param {String} action
   */
  setNotificationAction (state, action) {
    state.notificationAction = action;
  },
  /**
   * Reset state
   * @param {Object} state 
   */
  dismissNotification (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
     * Populates notification data in state and optionally sets a timer to automatically close it.
     * @param {Object} context - Vuex store context.
     * @param {Object} notification - Notification payload.
     */
  setNotification ({ commit, dispatch, state }, notification) {
    if (!state.notification) {
      commit('setNotification', notification);

      if (!notification.static) {
        setTimeout(() => {
          dispatch('dismissNotification'); 
        }, notificationTimeMs);
      }
    } else {
      logger.log('[SettingsNotification store] Active notification already exists, ignoring update');
    }
  },

  /**
   * Sets notificiation action (happens on click of the component)
   * @param {Object} context - Vuex store context
   * @param {String} action - Name of action
   */
  setNotificationAction ({ commit }, action) {
    commit('setNotificationAction', action);
  },
  
  /**
     * Responds to a dismiss notification action.
     * @param {Object} context - Vuex store context.
     */
  dismissNotification ({ commit }) {
    commit('dismissNotification');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
