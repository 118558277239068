import api from '@api/api.js';
import { STORAGE_KEYS } from '@utils/constants.js';

const BASE_URL = 'device/activate';

/**
 * Successful activation device status.
 */
const SUCCESS_STATUS = 'REGISTERED_ACTIVE';

// TODO: STAT-37286 Move activation code session to registration store - centralize all reg session logic, decouple activation API calls from app session logic
export const getDefaults = () => ({
  code: null,
});

export const state = getDefaults();

export const mutations = {
  /**
   * Loads any state data stored in localStorage - to be called on app startup.
   * @param {Object} state - Module state
   */
  initializeState (state) {
    const code = localStorage.getItem(STORAGE_KEYS.activationCode);
    if (code) {
      state.code = code;
    }
  },
  /**
   * Sets an activation code to state and saves it to localStorage.
   * @param {Object} state - Module state
   * @param {string} code - Activation code to set
   */
  setCode (state, code) {
    state.code = code;
    localStorage.setItem(STORAGE_KEYS.activationCode, code);
  },
  /**
   * Resets state to defaults and clears any code from localStorage.
   * @param {Object} state - Module state
   */
  reset (state) {
    localStorage.removeItem(STORAGE_KEYS.activationCode);
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Sets an activation code to state to be validated later.
   * @param {Object} context - Vuex module context
   * @param {string} code - Activation code to set
   */
  setCode ({ commit }, code) {
    commit('setCode', code);
  },
  /**
   * Resets the module state.
   * @param {Object} context - Vuex module context
   */
  reset ({ commit }) {
    commit('reset');
  },
  /**
   * Attempts device activation, clears any cached codes.
   * @param {Object} context - Vuex module context
   * @param {string} activationCode - Activation code
   */
  async activate ({ commit, state }, activationCode = null) {
    if (activationCode) {
      commit('setCode', activationCode);
    }
    const response = await api.post(`${BASE_URL}/${state.code}`);
    if (response.deviceStatus === SUCCESS_STATUS) {
      // Reset state on successful activation
      commit('reset');
      return true;
    } else {
      throw new Error('Invalid device status.');
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
