import Logger from '@utils/logger.js';

const logger = new Logger('Storage');

const LocalStorage = {
  setItem (key, value) {
    try {
      const jsonString = JSON.stringify(value);
      window.localStorage.setItem(key, jsonString);
    } catch (e) {
      logger.warn('Could not set localStorage item', e);
    }
  },
  getItem (key) {
    try {
      const item = window.localStorage.getItem(key);
      return JSON.parse(item);
    } catch (e) {
      logger.log('Could not get localStorage item', e);
      return false;
    }
  },
  removeItem (key) {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      logger.log('Could not remove localStorage item', e);
    }
  },
};

const SessionStorage = {
  setItem (key, value) {
    try {
      const jsonString = JSON.stringify(value);
      window.sessionStorage.setItem(key, jsonString);
    } catch (e) {
      logger.warn('Could not set sessionStorage item', e);
    }
  },
  getItem (key) {
    try {
      const item = window.sessionStorage.getItem(key);
      return JSON.parse(item);
    } catch (e) {
      logger.log('Could not get sessionStorage item', e);
      return false;
    }
  },
  removeItem (key) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (e) {
      logger.log('Could not remove sessionStorage item', e);
    }
  },
};

export {
  LocalStorage,
  SessionStorage,
};
