/**
 * Maintains subscription details for BACON billing-type users.
 */

import api from '@api/api.js';
import { RECURLY_PAYMENT_METHODS } from '@utils/constants';

export const url = 'user/subscription/bacon';

/**
 * Defaults for the bacon subscription store.
 * @typedef {Object} BaconSubscriptionDefaults
 * @property {boolean} fetching - Indicates if an API interaction is in progress.
 * @property {boolean} fetched - Indicates if at least one successful API call has been made.
 * @property {Object} error - Contains any API error response.
 * @property {string} accountCode - Recurly account code, links sho user w/ Recurly.
 * @property {string} productCode - Current plan's Recurly product code.
 * @property {string} paymentMethod - Recurly payment method - either 'Card' or 'PayPal'
 * @property {string} cardType - If paying with a card, the card type (Visa, Amex, etc.)
 * @property {string} lastFourDigits - If paying with card, the card's last 4 digits
 * @property {string} year - If paying with a card, the expiration year.
 * @property {string} month - If paying with a card, the expiration month.
 * @property {string} subscriptionPlanSummary - A description of the user's current plan.
 * @property {boolean} autoRenew - Indicates if a user's subscription auto-renews.
 * @property {boolean} hasPurchased - Indicates if a user has made payments for their plan (not just trial usage.)
 */

/**
 * Returns an immutable set of default data to be used in state.
 * @returns {BaconSubscriptionDefaults} - Default state data.
 */
export const getDefaults = () => ({
  fetching: false,
  fetched: false,
  error: null,
  accountCode: null,
  productCode: null,
  paymentMethod: null,
  cardType: null,
  lastFourDigits: null,
  year: null,
  month: null,
  subscriptionPlanSummary: null,
  autoRenew: null,
  hasPurchased: null,
});

export const state = getDefaults();

export const getters = {
  hasValidSavedPayment (state) {
    return Object.values(RECURLY_PAYMENT_METHODS).indexOf(state.paymentMethod) > -1;
  },
  paymentMethodDisplay (state) {
    if (state.paymentMethod === RECURLY_PAYMENT_METHODS.card) {
      return `${state.cardType.toUpperCase()} X-${state.lastFourDigits} EXP ${state.month}/${state.year}`;
    }

    return state.paymentMethod || 'None';
  },
};

export const mutations = {
  setFetching (state, fetching) {
    state.error = null;
    state.fetching = fetching;
  },
  setFetched (state) {
    state.fetched = true;
    state.fetching = false;
  },
  setResponse (state, response) {
    // Reset state to defaults and then set to API response
    Object.assign(state, getDefaults(), response);
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Gets Bacon subscription details.
   * @param {Object} context - Vuex context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   */
  async getSubscriptionDetails ({ commit, state }, options = {}) {
    // Make an API call if user has requested fresh data,
    // or if data has never been fetched, and a fetch is not in progress
    if (!state.fetching && (options.cacheBust || !state.fetched)) {
      try {
        commit('setFetching', true);
        const response = await api.get(url);
        commit('setResponse', response);
      } catch (error) {
        commit('setError', error);
      }
      commit('setFetched', true);
    }
  },
  /**
   * Attempts a Recurly purchase.
   * @param {Object} context - Vuex context.
   * @param {Object} payload - Purchase data.
   */
  async purchase ({ commit, dispatch }, payload) {
    if (payload.password) {
      await dispatch('user/confirmPassword', payload.password, { root: true });
    }
    // The bacon subscription purchase success response is a user object, not a bacon subscription object,
    // so bacon subscription details need to be manually requested, as well as user subscription details
    // to pull data that is also needed for the purchase success UI.
    // This should be addressed at the API or at least the gibson level to cut down on plan/sub API redundancy.
    const response = await api.post(url, payload);
    commit('user/setUser', response, { root: true });
    
    // Refresh bacon subscription
    await dispatch('getSubscriptionDetails', { cacheBust: true });
    // Refresh user subscription
    await dispatch('userSubscription/getSubscriptionDetails', { cacheBust: true }, { root: true });

    return response;
  },
  /**
   * Resets state to defaults.
   * @param {Object} context - Vuex context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
