import ImpactMixin, { CONFIG as IMPACT_CONFIG } from '@components/affiliates/ImpactMixin.js';

export default {
  methods: {
    /**
     * Determines CID to send to Paywall.
     * * Should check for 'offer', 's_cid', 'i_cid', and 'o_cid' query parameters, in that order.
     *   - These can be ignored if an 'aff_src' (affiliate source) param is present.
     *   - Eventually all offers should use the 'offer' param - s_cid, i_cid and o_cid are legacy support
     * * If no URL campaign id, and not in a registration flow, and affiliate cookie present, generate affiliate cid.
     * * If a test bucket query param is present, 'b', always send that as the cid.
     * @returns {string}
     */
    getCid () {
      let cid;
      const offerCid = this.$route.query.offer;
      const sCid = this.$route.query.s_cid;
      const iCid = this.$route.query.i_cid;
      const oCid = this.$route.query.o_cid;
      const testBucket = this.$route.query.b;
      const impactData = ImpactMixin.methods.getImpactData();

      // Affiliate links will have tracking CIDs attached that can be ignored by the paywall
      if (!this.$route.query[IMPACT_CONFIG.params.affiliateSource]) {
        cid = testBucket || offerCid || sCid || iCid || oCid;
      }

      // If no campaign found in URL, and no reg flow being hit, pass affiliate IDs to paywall as 'cid' for affiliate offers
      if (!this.flowId && !cid && impactData) {
        cid = `aff-${impactData.affiliateSource}-${impactData.affiliateId}`;
      }

      return cid;
    },
  },
};
