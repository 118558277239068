import { STORAGE_KEYS } from '@utils/constants.js';
import ls from '@utils/local-storage.js';

// Store the current route in local storage so the user is
// automatically redirected once they complete registration
export default function storeRoute (route) {
  const { name, params, query, hash } = route;
  // local storage expiration is measured in days
  // expire the stored route after 1 hour
  const storageTime = 1 / 24;
  ls.setItem(STORAGE_KEYS.intendedRoute, { name, params, query, hash }, storageTime);
} 
