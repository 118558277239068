import api from '@api/api.js';

export const actions = {
  /**
   * Fetches partner authentication url and metadata.
   * @param {Object} context - Vuex module context
   * @param {string} partnerId - Auth partner id
   * @returns {Promise}
   */
  getPartnerAuthenticationUrl (context, partnerId) {
    return api.get(`partner/authentication-url/${partnerId}`);
  },
  /**
   * Attempts to authorize a user by linking a partner subscription.
   * @param {Object} context - Vuex module context
   * @param {Object} payload
   * @param {string} payload.partnerId - Auth partner id
   * @param {string} payload.code - Authorization code 
   */
  linkPartnerAccount (context, { partnerId, code }) {
    return api.post(`user/subscription/${partnerId}`, { code });
  },
};

export default {
  namespaced: true,
  actions,
};
