import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('movies');

const URL = 'titles/collections';

export const defaults = {
  fetching: false,
  fetched: false,
  collectionMetadata: {},
  page: 0,
  pageCount: 0,
  promotions: null,
  resultCount: 0,
  titles: [],
  error: null,
};

const state = { ...defaults }; 

const actions = {
  /**
   * Fetches a page of movie titles and category promotions.
   * Automatically fetches the next page of a given 'collectionId'
   * @param {Object} context - Vuex module context 
   * @param {number} collectionId - Movie category collection id
   */
  async getMovies ({ commit, state }, collectionId) {
    // Check if a new category is being requested
    const currentId = state.collectionMetadata && state.collectionMetadata.id;
    const isNewCategory = currentId !== collectionId;
    const newPage = isNewCategory ? 1 : state.page + 1;

    // Skip fetch if no more pages are available and not a new category
    if (!isNewCategory && newPage > state.pageCount) {
      return;
    }

    commit('setFetching');
    try {
      const response = await api.get(`${URL}/${collectionId}/page/${newPage}`);
      // if this is the first response, reset titles, set promotions and category meta
      if (newPage === 1) {
        commit('resetTitles');
        commit('setPromotions', response.promotions || null);
        commit('setMetadata', response.collectionMetadata || {});
      }
      commit('setTitles', response);
    } catch (error) {
      logger.error(error);
      commit('setError', error);
    }
  },
  /**
   * Resets state
   * @param {Object} context - Vuex module context 
   */
  reset ({ commit }) {
    commit('resetState');
  },
};

export const mutations = {
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setTitles (state, response) {
    state.page = response.page;
    state.pageCount = response.pageCount;
    state.resultCount = response.resultCount;
    state.titles = state.titles.concat(response.titles);
    state.fetching = false;
    state.fetched = true;
  },
  setPromotions (state, promotions) {
    state.promotions = promotions;
  },
  setMetadata (state, collectionMetadata) {
    state.collectionMetadata = collectionMetadata;
  },
  resetTitles (state) {
    state.titles = [];
  },
  resetState (state) {
    Object.assign(state, { ...defaults });
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
