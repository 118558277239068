<template>
  <div class="my-list">
    <MyListItems
      :fetching="fetching"
      :fetched="fetched"
      :items="items"
      @delete-click="onDeleteClick"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MyListItems from '@components/my-list/MyListItems.vue';

/**
 * Vuex wrapper for My List UI components.
 */
export default {
  name: 'MyList',
  components: {
    MyListItems,
  },
  computed: {
    ...mapState('myList', ['items', 'fetching', 'fetched']),
  },
  created () {
    this.$store.dispatch('myList/getMyList');
  },
  methods: {
    onDeleteClick (item) {
      this.$store.dispatch('myList/removeItem', item);
    },
  },
};
</script>
