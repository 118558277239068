/**
 * @param  {Array} queryStringItems - array of items preset ('x=y') or empty array
 * @param  {} queryObject - object to be parameterized, does not handle nested
 * @returns {String} query string
 */
export function queryString (queryStringItems, queryObject) {
  // array is required as an array
  if (!Array.isArray(queryStringItems)) {
    return '';
  }

  if (!queryObject) {
    return '';
  }

  for (const [key, value] of Object.entries(queryObject)) {
    queryStringItems.push(`${key}=${encodeURIComponent(value)}`);
  }
  return queryStringItems.join('&');
}
