import api from '@api/api.js';
import { ACCOUNT_TYPES } from '@utils/constants.js';

export const pcUrl = 'user/pc';

export const getDefaults = () => ({
  movie: null,
  tv: null,
  defaultMovie: null,
  defaultTv: null,
  movieDisplay: null,
  tvDisplay: null,
  defaultMovieDisplay: null,
  defaultTvDisplay: null,
  tveUserId: null,
  subs: null,
  created: null,
  error: null,
  fetching: false,
  fetched: false,
});

const state = getDefaults();

export const actions = {
  async getViewerRestrictions ({ commit }, accountType = ACCOUNT_TYPES.primary) {
    commit('setFetching', true);
    try {
      const response = await api.get(`${pcUrl}${ accountType === ACCOUNT_TYPES.primary ? '/all' : ''}`);
      commit('setViewerRestrictions', response);
    } catch (error) {
      commit('setError', error);
    }
    commit('setFetching', false);
    commit('setFetched', true);
  },

  async updateViewerRestrictions ({ commit, state }, vrUpdate) {
    const payload = { ...state };
    if (vrUpdate.subTveUserId) {
      const subIndex = payload.subs.findIndex((sub) => sub.tveUserId === vrUpdate.subTveUserId);
      payload.subs[subIndex][vrUpdate.field] = vrUpdate.value;
    } else {
      payload[vrUpdate.field] = vrUpdate.value;
    }
    commit('setFetching', true);
    try {
      const response = await api.put(pcUrl, payload);
      commit('setViewerRestrictions', response);
    } catch (error) {
      commit('setError', error);
    }
    commit('setFetching', false);
  },

  // Action used during registration to accept multiple viewerRestrictions at once.
  async setInitialViewerRestrictions ({ commit }, vrUpdate) {
    commit('setFetching', true);
    try {
      const response = await api.put(pcUrl, vrUpdate);
      commit('setViewerRestrictions', response);
    } catch (error) {
      commit('setError', error);
    }
    commit('setFetching', false);
  },

  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  setViewerRestrictions (state, viewerRestrictions) {
    Object.assign(state, viewerRestrictions);
  },
  setFetching (state, fetching) {
    state.fetching = fetching;
  },
  setFetched (state, fetched) {
    state.fetched = fetched;
  },
  setError (state, error) {
    state.error = error;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
