import api from '@api/api.js';
import Logger from '@utils/logger.js';

import { LINEAR_OFFSETS } from '@utils/player-constants.js';

const logger = new Logger('schedule');
const schedule = 'schedule';

// pointer updating timer for live title switches
let timer;

export const defaults = {
  titles: [],
  lastFetchedPage: null,
  pointerEast: null,
  pointerWest: null,
  error: false,
  fetching: true,
};

const state = { ...defaults };

/* east getters */
const titlesEast = (state) => ({
  titles: state.titles.slice(state.pointerEast, state.titles.length),
  region: 'east',
});

const onNowEast = (state) => state.titles[state.pointerEast];

const onNextEast = (state) => {
  if (state.titles.length > state.pointerEast + 1) {
    return state.titles[state.pointerEast + 1];
  } else {
    return state.titles[state.titles.length - 1];
  }
};

/* west getters */
const titlesWest = (state) => ({
  titles: state.titles.slice(state.pointerWest, state.titles.length),
  region: 'west',
});

const onNowWest = (state) => state.titles[state.pointerWest];

const onNextWest = (state) => {
  if (state.titles.length > state.pointerWest + 1) {
    return state.titles[state.pointerWest + 1];
  } else {
    return state.titles[state.titles.length - 1];
  }
};

const preview = (state) => ({
  shoeast: {
    next: onNextEast(state),
    now: onNowEast(state),
  },
  showest: {
    next: onNextWest(state),
    now: onNowWest(state),
  },
});

export const getters = {
  titlesEast,
  onNowEast,
  onNextEast,
  titlesWest,
  onNowWest,
  onNextWest,
  preview,
};

export const actions = {
  /* get current page, before + after of schedule to start */
  getScheduleData ({ commit, dispatch }, page) {
    /* check if state exists before doing this fetch, because 3 different places will use this */
    if (!state.titles || !state.titles.length) {
      const previousPageFetch = api.get(`${schedule}/${page - 1}`);
      const currentPageFetch = api.get(`${schedule}/${page}`);
      const nextPageFetch = api.get(`${schedule}/${page + 1}`);
      return Promise.all([
        previousPageFetch.catch((error) => {
          logger.error(error);
          return { error };
        }),
        currentPageFetch.catch((error) => {
          logger.error(error);
          return { error };
        }),
        nextPageFetch.catch((error) => {
          logger.error(error);
          return { error };
        }),
      ]).then((responses) => {
        if (responses[1].error || !responses[1].channels.length || !responses[1].channels[0].programs.length) {
          commit('setError');
        } else {
          const titlesArray = [];
          responses.forEach((response) => {
            if (isValidScheduleResponse(response)) {
              titlesArray.push(...response.channels[0].programs);
            }
          });
          const updatedPage = page + 1;
          commit('setSchedule', { titlesArray, updatedPage });
          commit('setFetching', false);
          dispatch('updatePointers');
        }
      });
    } else {
      dispatch('updatePointers');
    }
  },
  /* set countdown until next title starts, when pointers
    will update (next title's start time - current time) */
  setTimer ({ dispatch, state }) {
    clearTimeout(timer);
    /* check if you need more titles */
    if (state.pointerEast > state.titles.length - 8) {
      dispatch('getMoreTitles');
    }
    const currentEasternTime = getTime().nowEast;
    const currentWesternTime = getTime().nowWest;

    /* calculate which 'on next' title is starting first, set timer to countdown to that */
    const eastStartDelta = (state.titles.find((title) => title.start > currentEasternTime).start) - currentEasternTime;
    const westStartDelta = (state.titles.find((title) => title.start > currentWesternTime).start) - currentWesternTime;
    const duration = eastStartDelta < westStartDelta ? eastStartDelta : westStartDelta;

    timer = setTimeout(() => {
      dispatch('updatePointers');
    }, duration);
  },

  updatePointers ({ commit, state, dispatch }) {
    /* recalculate pointers, pass them to mutation */
    const currentEasternTime = getTime().nowEast;
    const currentWesternTime = getTime().nowWest;

    const updatedEastPointer = state.titles.findIndex((channel) => channel.start > currentEasternTime) - 1;
    const updatedWestPointer = state.titles.findIndex((channel) => channel.start > currentWesternTime) - 1;

    dispatch('setTimer');
    commit('resetPointers', { updatedEastPointer, updatedWestPointer });
  },

  getMoreTitles ({ commit, state }) {
    /* grab more titles - will only get explicitly called from setTimer if titles returned in getter are running low */
    const page = state.lastFetchedPage + 1;
    if (!state.fetching) {
      commit('setFetching');
      return api.get(`${schedule}/${page}`).then((response) => {
        if (isValidScheduleResponse(response)) {
          const nextTitles = response.channels[0].programs;
          commit('addTitles', { nextTitles, page });
        } else {
          logger.error('there was an error fetching schedule titles');
        }
      })
        .catch((error) => {
          logger.error(error);
          commit('setFetching', false);
        });
    }
  },

  debugNextStartTime ({ commit, dispatch }, { channel, newStartTimeInMillis }) {
    if (__BUILDENV__ !== 'prod' && __BUILDENV__ !== 'production') {
      commit('setNextStartTime', { channel, newStartTimeInMillis });
      dispatch('setTimer');
    }
  },
};

export const mutations = {
  setSchedule (state, response) {
    /* assign schedule data to state, hold on to last */
    state.titles = response.titlesArray;
    state.lastFetchedPage = response.updatedPage;
  },

  resetPointers (state, pointers) {
    state.pointerEast = pointers.updatedEastPointer;
    state.pointerWest = pointers.updatedWestPointer;
  },

  addTitles (state, titlesInfo) {
    /* add titles to state, increment the last fetched page */
    state.titles.push(...titlesInfo.nextTitles);
    state.lastFetchedPage = titlesInfo.page;
    state.fetching = false;
  },

  setError (state) {
    state.error = true;
  },

  setFetching (state, val = true) {
    state.fetching = val;
  },

  setNextStartTime (state, { channel, newStartTimeInMillis }) {
    if (channel === 'shoeast') {
      state.titles[state.pointerEast + 1].start = newStartTimeInMillis;
    }
    if (channel === 'showest') {
      state.titles[state.pointerWest + 1].start = newStartTimeInMillis - (LINEAR_OFFSETS.showest);
    }
  },
};

export const getTime = () => {
  const d = new Date();
  const east = d.getTime();
  const west = d.setHours(d.getHours() - 3);
  return { nowEast: east, nowWest: west };
};

export const isValidScheduleResponse = (response) => {
  if (response.channels && Array.isArray(response.channels) && response.channels[0].programs && response.channels[0].programs.length > 0) {
    return true;
  }

  return false;
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getTime,
};
