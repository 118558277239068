const getDefaults = () => ({
  page: null,
  data: {},
});

export const state = getDefaults();

export const mutations = {
  setPage (state, biMetadata) {
    state.page = biMetadata;
  },
  clearPage (state) {
    state.page = null;
  },
  setData (state, { name, data }) {
    state.data = {
      ...state.data,
      [name]: data,
    };
  },
  clearData (state, key) {
    const updated = { ...state.data };
    delete updated[key];
    state.data = updated;
  },
};

export const actions = {
  /**
   * Sets page-specific bi metadata
   * @param {Object} context - Vuex module context.
   * @param {Object} biMetadata - Page metadata
   * @param {string} biMetadata.siteSection
   * @param {string[]} biMetadata.subSections
   */
  setPage ({ commit }, biMetadata) {
    commit('setPage', biMetadata);
  },
  clearPage ({ commit }) {
    commit('clearPage');
  },
  /**
   * Sets miscellany bi data to the global data layer.
   * @param {Object} context - Vuex module context.
   * @param {Object} data - BI Data to set
   * @param {string} data.name - Data key
   * @param {Object} data.data - Data value
   */
  set ({ commit }, { name, data }) {
    commit('setData', { name, data });
  },
  /**
   * Clears a single data entry
   * @param {Object} context - Vuex module context.
   * @param {string} key - Key to clear
   */
  clear ({ commit }, key) {
    commit('clearData', key);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
