import api from '@api/api.js';

const URLS = {
  purchase: 'user/ppv/bacon/purchase',
  savedPayment: 'user/ppv/bacon/payment',
  validateCoupon: 'ppv/coupon',
};

export const actions = {
  /**
   * Checks for a user's saved PPV payment method.
   * @async
   * @returns {Object|null}
   */
  async getSavedPayment () {
    const response = await api.get(URLS.savedPayment);
    return response.paymentMethod ? response : null;
  },
  /**
   * Validates a PPV coupon code.
   * @param {Object} context - Vuex moduel context
   * @param {string} code - Coupon code to validate
   * @returns {Promise}
   */
  validateCoupon (context, code) {
    return api.get(`${URLS.validateCoupon}/${code}`);
  },
  async purchase ({ dispatch }, payload) {
    if (payload.password) {
      await dispatch('user/confirmPassword', payload.password, { root: true });
    }

    return await api.post(URLS.purchase, payload);
  },
};

export default {
  namespaced: true,
  actions,
};
