// Class to manage fairplay encryption
import api from '@api/api.js';

import mux from 'mux-embed';
import { muxData } from '@player/config/mux.js';
import Logger from '@utils/logger.js';

const logger = new Logger('Fairplay');

export default class Fairplay {
  /**
   * Constructor for Fairplay
   * @param {object} video - video dom element for page
   *
   **/
  constructor (videoEl) {
    // sets up all internal variables at this time.

    // key system being used
    this.__keySystem = null;

    // cert retrieved at path below
    this.__certificate = null;
    this.__serverCertificatePath = '/assets/common/fairplay/showtime.der';

    // auth token if returned (currently unused)
    // TODO address when authToken is added to fairplay response
    this.__authToken = null;

    // license url path
    this.__licenseUrl = null;

    // video element
    this.__video = videoEl;

    // set up Mux
    mux.monitor(
      this.__video,
      {
        debug: muxData.debug,
        errorTranslator: muxData.errorTranslator,
        data: muxData.data,
      }
    );
  }

  /**
   * get uriRequestParam - #required Returns string for uri param on startplay request
   *
   * @return {String}  fairplay descriptor
   */
  static get uriRequestParam () {
    return 'FAIRPLAY_URI';
  }

  /**
   * Async DRM support check. Returns a boolean.
   * @returns {Boolean} true/false browser supports Fairplay
   */
  static async testSupport (video, mediaTypeConfig) {
    try {
      // cover for old mac os versions
      if (typeof WebKitMediaKeys !== 'undefined' && WebKitMediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')) {
        return true;
      } else if (window.navigator.requestMediaKeySystemAccess && typeof window.navigator.requestMediaKeySystemAccess === 'function') {
        await window.navigator.requestMediaKeySystemAccess('com.apple.fps', mediaTypeConfig);
        return true;
      } else if (window.MSMediaKeys) {
        return !!window.MSMediaKeys.isTypeSupported('com.apple.fps');
      } else if ('WebKitMediaKeys' in window || 'MediaKeys' in window) {
        // test whether browser can (probably) or possibly (maybe) play an hls file
        const canplay = video.canPlayType
          && typeof video.canPlayType === 'function'
          && (video.canPlayType('application/vnd.apple.mpegURL') === 'maybe'
            || video.canPlayType('application/vnd.apple.mpegURL') === 'probably');
        // test whether browser is capable of using Fairplay
        if (canplay) {
          logger.log('Fairplay is supported');
          return true;
        } else {
          logger.log('Fairply is NOT supported');
          return false;
        }
      } else {
        logger.log('No WebkitMediaKeys / MediaKeys Support');
        return false;
      }
    } catch (err) {
      logger.log('Not supported:', err);
      return false;
    }
  }

  /**
   * set authToken - setter for authtoken
   *
   * @param  {String} token auth token
   */
  set authToken (token) {
    // TODO implement fully once backend begins to send authToken
    this.__authToken = token;
  }

  requestPlayback (startPlay) {
    // attempt to get license from startplay, if it doesn't exist
    // default it to static api path
    // TODO file a ticket for live/ppv api update to include license server
    if (typeof startPlay.licenseUrl !== 'undefined') {
      try {
        const url = new URL(startPlay.licenseUrl);
        this.__licenseUrl = url.pathname;
      } catch (err) {
        logger.log(err);
        this.__licenseUrl = startPlay.licenseUrl;
      }
    } else {
      this.__licenseUrl = '/api/drm/fairplay/ckc';
    }
    this.__video.setAttribute('src', startPlay.uri);

    // Send event to mux with new title data
    mux.emit('videochange', muxData.data.video);
  }

  /**
   * init - #required initter for all drm classes
   *
   * @param  {Object} startplay response
   */
  async init () {
    // test if WebKit prefix is needed
    /* global WebKitMediaKeys */
    /* global MediaKeys */
    if (WebKitMediaKeys) {
      this.__video.addEventListener('webkitneedkey', this.onNeedKey.bind(this), false);
    } else if (MediaKeys) {
      this.__video.addEventListener('needkey', this.onNeedKey.bind(this)), false;
    }
    this.__video.addEventListener('error', this.onError.bind(this), false);

    try {
      // request certificate
      const response = await api.get(this.__serverCertificatePath, { responseType: 'arraybuffer' });
      this.__certificate = new Uint8Array(response);
    } catch (error) {
      logger.log('Certificate Error');
      logger.error(error);
    }
  }

  /**
   * setLanguage - Set the audio track to the given language.
   * @param language - string that dictates the audio channel we should use
   * (PPV ONLY)
   */
  setLanguage (language) {
    if (this.__video && this.__video.audioTracks.length) {
      for (const i in this.__video.audioTracks) {
        if (this.__video.audioTracks[i].language === language) {
          this.__video.audioTracks[i].enabled = true;
        } else {
          this.__video.audioTracks[i].enabled = false;
        }
      }
    }
  }

  /**
   * checkLanguage - Returns true if there are two or more languages available,
   * false otherwise.
   * (PPV ONLY)
   */
  checkLanguage () {
    return this.__video && this.__video.audioTracks.length > 1;
  }

  /**
   * getAudioTracks - Return list of audio tracks
   * @returns {AudioTrackList}
   */
  getAudioTracks () {
    return this.__video.audioTracks;
  }

  /**
   * setAudioTrack - Set the audio track to the given language.
   * @param {String} track - desired track
   */
  setAudioTrack (selectedTrack) {
    const tracks = this.getAudioTracks();
    for (let i = 0; i < tracks.length; i++) {
      tracks[i].enabled = false;
      if (tracks[i].language === selectedTrack.language && tracks[i].kind === selectedTrack.role) {
        tracks[i].enabled = true;
      }
    }
  }

  /**
   * onError - description
   *
   * @param  {Event} e event from request
   */
  onError (e) {
    logger.error('A video playback error occurred', e);
    const error =  e.currentTarget.error;
    logger.log(error);
  }


  /**
   * arrayToString - helper function to convert an array to a string
   *
   * @param  {Array} array
   * @return {String}
   */
  arrayToString (array) {
    const uint16array = new Uint16Array(array.buffer);
    return String.fromCharCode.apply(null, uint16array);
  }

  /**
   * stringToArray - turns string into Uint16Array
   *
   * @param  {String} string
   * @return {Array}
   */
  stringToArray (string) {
    const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
    const array = new Uint16Array(buffer);
    for (let i = 0, strLen = string.length; i < strLen; i++) {
      array[i] = string.charCodeAt(i);
    }
    return array;
  }


  /**
   * base64EncodeUint8Array - base64 encode Array
   *
   * @param  {Array} input
   * @return {String}
   */
  base64EncodeUint8Array (input) {
    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';
    let chr1;
    let chr2;
    let chr3;
    let enc1;
    let enc2;
    let enc3;
    let enc4;
    let i = 0;

    while (i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
      chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
        keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
  }

  /**
   * base64DecodeUint8Array - decodes previously encoded base64 string
   *
   * @param  {String} input
   * @return {Array}
   */
  base64DecodeUint8Array (input) {
    const raw = window.atob(input);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

  /**
   * extractContentId - returns content id in manifest blob
   *
   * @param  {Array} initData
   * @return {String}
   */
  extractContentId (initData) {
    let contentId = this.arrayToString(initData);
    const skd = contentId.indexOf('skd');
    if (skd !== 0) {
      contentId = contentId.slice(skd, contentId.length);
    }
    const link = document.createElement('a');

    link.href = contentId;

    return link.hostname;
  }

  /**
   * concatInitDataIdAndCertificate - data to be sent to server for license
   *
   * @param  {String} initData
   * @param  {String} dataId
   * @return {Array}
   */
  concatInitDataIdAndCertificate (initData, dataId) {
    const id = typeof dataId === 'string' ? this.stringToArray(dataId) : dataId;

    // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
    let offset = 0;
    const buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + this.__certificate.byteLength);
    const dataView = new DataView(buffer);

    const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
    initDataArray.set(initData);
    offset += initData.byteLength;

    dataView.setUint32(offset, id.byteLength, true);
    offset += 4;

    const idArray = new Uint16Array(buffer, offset, id.length);
    idArray.set(id);
    offset += idArray.byteLength;

    dataView.setUint32(offset, this.__certificate.byteLength, true);
    offset += 4;

    const certArray = new Uint8Array(buffer, offset, this.__certificate.byteLength);
    certArray.set(this.__certificate);

    return new Uint8Array(buffer, 0, buffer.byteLength);
  }

  /**
   * onNeedKey - Event handler when Key is requested. internal function to browser
   *
   * @param  {Event} event
   */
  onNeedKey (event) {
    let initData = event.initData;
    const contentId = this.extractContentId(initData);
    initData = this.concatInitDataIdAndCertificate(initData, contentId);

    if (!this.__video.webkitKeys && !this.__video.mediaKeys) {
      this.selectKeySystem();
      if (WebKitMediaKeys) {
        this.__video.webkitSetMediaKeys(new WebKitMediaKeys(this.__keySystem));
      } else if (MediaKeys) {
        this.__video.setMediaKeys(new MediaKeys(this.__keySystem));
      }
    }

    if (!this.__video.webkitKeys && !this.__video.mediaKeys) {
      throw 'Could not create MediaKeys';
    }

    const keySession = this.__video.webkitKeys.createSession('video/mp4', initData);
    if (!keySession) {
      throw 'Could not create key session';
    }

    keySession.contentId = contentId;

    // TODO validate these for whether we need both.
    // TODO Look into retries if initial license request fails.
    // For Safari 10+
    keySession.addEventListener('keymessage', this.licenseRequestReady.bind(this));
    keySession.addEventListener('keyadded', this.onKeyAdded.bind(this));
    keySession.addEventListener('keyerror', this.onKeyError.bind(this));
    // For Safari 9
    keySession.addEventListener('webkitkeymessage', this.licenseRequestReady.bind(this));
    keySession.addEventListener('webkitkeyadded', this.onKeyAdded.bind(this));
    keySession.addEventListener('webkitkeyerror', this.onKeyError.bind(this));
  }

  /**
   * licenseRequestReady - Event to get licnense, triggered by src being set
   *
   * @param  {Event} event
   */
  licenseRequestReady (event) {
    /**
     * Welcome to us using an old school XMLHttpRequest
     * You may be thinking to yourself:
     * "Self? Why would Matt use this here? He's using fancy Axios everywhere else"
     * fine and valid question.
     * Axios does not support x-www-form-urlencoded out of the box. You have to
     * do some major backflips to make them work.
     * It also sends a CORS error if the body is formatted in any other way. Which
     * seems very wrong, since CORS wouldn't be the issue there. ¯\_(ツ)_/¯ who knows.
     */
    const request = new XMLHttpRequest();
    request.open('POST', this.__licenseUrl, true);
    request.session = event.target;
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    request.addEventListener('load', this.licenseRequestLoaded.bind(this));
    request.addEventListener('error', this.licenseRequestFailed.bind(this));

    // TODO add authToken once the backend implements
    const params = `spc=${encodeURIComponent(this.base64EncodeUint8Array(event.message))}&assetId=${event.target.contentId}`;
    request.send(params);
  }

  /**
   * licenseRequestLoaded - Event manager once license has been returned
   *
   * @param  {Event} event - HttpRequest event
   */
  licenseRequestLoaded (event) {
    const session = event.target;

    // see if server sent back an api error
    try {
      const data = session.responseText;

      if (data.error) {
        const error = data.error.exception || data.error.code || null;
        logger.log(error);
        return;
      }
    } catch (e) {
      logger.error(e);
    }

    // response can be of the form: '\n<ckc>base64encoded</ckc>\n'
    // so trim the excess:
    let keyText = session.responseText.trim();
    if (keyText.substr(0, 5) === '<ckc>' && keyText.substr(-6) === '</ckc>') {
      keyText = keyText.slice(5, -6);
    }

    try {
      const key = this.base64DecodeUint8Array(keyText);
      session.session.update(key);
    } catch (e) {
      const error = e.message || e.code || null;
      logger.log(error);
    }
  }

  /**
   * licenseRequestFailed - license request failure handler
   *
   * @param  {Event} ev
   */
  licenseRequestFailed (ev) {
    logger.error('license request failed', ev);
  }

  /**
   * onKeyError - Key retrieval error handler
   *
   * @param  {Event} ev
   */
  onKeyError (ev) {
    logger.error('key error', ev);
  }

  /**
   * onKeyAdded - key added event handler
   *
   */
  onKeyAdded () {
    // NO-OP
  }

  /**
   * selectKeySystem - Selects available key system in Safari
   * TODO add fps.2_0
   *
   */
  selectKeySystem () {
    if (WebKitMediaKeys && WebKitMediaKeys.isTypeSupported('com.apple.fps', 'video/mp4')) {
      this.__keySystem = 'com.apple.fps';
    } else if (MediaKeys && MediaKeys.isTypeSupported('com.apple.fps', 'video/mp4')) {
      this.__keySystem = 'com.apple.fps';
    } else if (WebKitMediaKeys && WebKitMediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')) {
      this.__keySystem = 'com.apple.fps.1_0';
    } else if (MediaKeys && MediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')) {
      this.__keySystem = 'com.apple.fps.1_0';
    } else {
      throw 'Key System not supported';
    }
  }

  /**
   * destroy - Requested by local web player when teardown begins.
   */
  destroy () {
    // NO-OP
  }
}
