import api from '@api/api.js';
import Logger from '@utils/logger.js';

const logger = new Logger('series-seasons');
const seriesEpisodesUrl = 'titles/series';

export const defaults = {
  seasons: [],
  fetching: false,
  fetched: false,
  error: null,
};

const state = { ...defaults };

export const actions = {
  /**
   * Fetches a series' season and episode data.
   * @param {Object} context 
   * @param {number} seriesId 
   */
  async getSeriesSeasons ({ commit, rootState }, seriesId) {
    const drm = rootState?.appPlayer?.drm?.uriRequestParam;
    const query = drm ? `${seriesEpisodesUrl}/${seriesId}/format/${drm}` : `${seriesEpisodesUrl}/${seriesId}`;

    try {
      commit('setFetching');
      const response = await api.get(query);
      commit('setSeriesEpisodes', response);
    } catch (error) {
      // Does not set page level error for pre-premiere content
      if (error.code !== 'error.notavailable.series') {
        commit('setError', error);
        logger.error(error);
      }
    } finally {
      commit('setFetching', false);
      commit('setFetched');
    }
  },
  /**
   * Resets state
   * @param {Object} context 
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  /**
   * Parses an episode list into seasons and updates state
   * @param {Object} state 
   * @param {Object} response - API response
   */
  setSeriesEpisodes (state, response) {
    const seasons = [];
    const episodes = response.episodesForSeries || [];
    episodes.forEach((episode) => {
      const epSeason = seasons.find((season) => season.seasonNum === episode.series.seasonNum);
      if (epSeason) {
        epSeason.episodes.push(episode);
      } else {
        seasons.push({ seasonNum: episode.series.seasonNum, episodes: [episode] });
      }
    });
    state.seasons = seasons;
    state.fetching = false;
    state.fetched = true;
  },
  resetState (state) {
    state.seasons = [];
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
    state.fetched = false;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setFetched (state, fetched = true) {
    state.fetched = fetched;
  },
  reset (state) {
    Object.assign(state, { ...defaults });
  },
};

export default {
  namespaced: true, 
  state,
  actions,
  mutations,
};

