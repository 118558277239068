import { mapState } from 'vuex';
import { getDeviceType } from '@utils/mobile-detect.js';

const DEFAULT_CAMPAIGNS = {
  amazon: 'amazon-appstore',
  android: 'google-playstore',
  ios: 'ios-itunes',
};

const URL_SUFFIX = 'DownloadUrl';

/**
 * Mixin for generating Kochava app store URLs.
 */
export default {
  computed: {
    ...mapState('dictionary', ['kochava']),
    /**
     * Returns the app store download link for supported mobile devices.
     * @returns {(string|null)}
     */
    appDownloadUrl () {
      const deviceType = getDeviceType();

      if (!deviceType || deviceType === 'unsupported' || !this.kochava) {
        return null;
      }

      let url = this.kochava[`${deviceType}${URL_SUFFIX}`];
      if (!url) {
        return null;
      }

      const defaultCid = DEFAULT_CAMPAIGNS[deviceType];

      // Replace placeholder campaign IDs in the dictionary URL with actual query params

      ['i_cid', 's_cid', 'o_cid'].forEach((param) => {
        const newVal = this.$route.query[param] || defaultCid;
        url = this.updateParam(url, param, newVal);
      });

      return url;
    },
  },
  methods: {
    /**
     * Updates a query parameter in a URL string.
     * @param {string} url 
     * @param {string} key 
     * @param {string} value 
     * @returns {string}
     */
    updateParam (url, key, value) {
      const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
      const separator = url.indexOf('?') !== -1 ? '&' : '?';
      if (url.match(re)) {
        return url.replace(re, `$1${key}=${value}$2`);
      } else {
        return `${url}${separator}${key}=${value}`;
      }
    },
  },
};
