/**
 * PPV state store module. 
 */

import api from '@api/api.js';
import ls from '@utils/local-storage.js';
import { STORAGE_KEYS } from '@utils/constants.js';
import Logger from '@utils/logger.js';
const logger = new Logger('ppv-state');


export const url = 'ppv/state';

const MAX_EXPIRES_IN = 3600000;

const ERROR_MAX_RETRIES = 3;
const ERROR_RETRY_INTERVAL = 5000;

let fetchAttempts = 0;
let fetchTimer;

/**
 * Defaults for the PPV state store.
 * @typedef {Object} PPVStateDefaults
 * @property {boolean} fetching - Indicates if an API interaction is in progress.
 * @property {boolean} fetched - Indicates if at least one successful API call has been made.
 * @property {Object} error - Contains any API error response.
 * @property {Number} expiresIn - Number of ms before next fetch
 * @property {String} state - Current PPV state
 * @property {Object} pages - Data required for building the UI of PPV components
 * @property {String} productId - Current PPV product ID
 * @property {Number} priceInCents - Price to purchase the PPV
 */

/**
 * Returns an immutable set of default data to be used in state.
 * @returns {PPVStateDefaults} - Default state data.
 */
export const getDefaults = () => {
  const pageDefaults = {
    dateLine: '',
    isLive: false,
    headline: '',
    headlineSegments: [],
    purchaseLine: '',
    hasPurchased: false,
    imageUrl: {
      mobile: '',
      desktop: '',
    },
    buttons: [],
    description: {
      desktop: '',
      mobile: '',
    },
  };

  return {
    fetching: false,
    fetched: false,
    error: null,
    state: null,
    pages: {
      general: {
        ...pageDefaults,
        dateSegments: null,
        // All other pages use an obect, this one uses a single string
        imageUrl: '',
      },
      banner: {
        ...pageDefaults,
        // All other pages use {desktop: '', mobile: ''}, this one uses {short: '', long: ''}
        description: {
          short: '',
          long: '',
        },
      },
      event: {
        ...pageDefaults,
        countdownTimestamp: 0,
        index: 0,
        category: '',
        playbackTile: null,
      },
      modal: {
        ...pageDefaults,
        visible: false,
      },
      home: {
        ...pageDefaults,
        visible: false,
        countdownTimestamp: 0,
        index: 0,
        type: 'PPV',
      },
      promotion: {
        ...pageDefaults,
        visible: false,
        countdownTimestamp: 0,
        index: 0,
        type: 'PPV',
        category: '',
      },
    },
    productId: '',
    priceInCents: 0,
  };
};

export const state = getDefaults();

export const mutations = {
  setFetching (state, fetching) {
    state.error = null;
    state.fetching = fetching;
  },
  setResponse (state, response) {
    logger.log(`[PPV State] state fetched successfully: ${response.state}`);
    // Reset state to defaults and then set to API response
    // Object.assign only works for top level, so this merges page defaults
    Object.assign(
      state,
      getDefaults(),
      {
        ...response,
        pages: {
          ...getDefaults().pages,
          general: {
            ...getDefaults().pages.general,
            ...response.pages ? response.pages.general : {},
          },
          banner: {
            ...getDefaults().pages.banner,
            ...response.pages ? response.pages.banner : {},
          },
          event: {
            ...getDefaults().pages.event,
            ...response.pages ? response.pages.event : {},
          },
          modal: {
            ...getDefaults().pages.modal,
            ...response.pages ? response.pages.modal : {},
          },
          home: {
            ...getDefaults().pages.home,
            ...response.pages ? response.pages.home : {},
          },
          promotion: {
            ...getDefaults().pages.promotion,
            ...response.pages ? response.pages.promotion : {},
          },
        },
      }
    );
    state.fetching = false;
    state.fetched = true;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Gets PPV state.
   * @param {Object} context - Vuex context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   * @param {boolean} [options.isRefetch] - Indicates an automatic refetch - does not trigger 'fetching'
   */
  async getState ({ commit, state, dispatch }, options = {}) {
    // Skip PPV State fetching if disabled via automation flag
    const automationConfig = ls.getItem(STORAGE_KEYS.automation);
    if (automationConfig?.suppressPpv) {
      logger.log('State fetch skipped from automation config.');
      return;
    }

    // Make an API call if user has requested fresh data,
    // or if data has never been fetched, and a fetch is not in progress
    if ((!state.fetching && !state.fetched) || options.cacheBust) {
      try {
        // clear any scheduled refetches
        if (fetchTimer) {
          clearTimeout(fetchTimer);
        }

        if (!options.isRefetch) {
          commit('setFetching', true);
        }
        
        const response = await api.get(url);

        // reset error retry counter
        fetchAttempts = 0;

        // Schedule next refetch
        if (response.expiresIn) {
          const nextFetchTime = Math.min(response.expiresIn, MAX_EXPIRES_IN);
          fetchTimer = setTimeout(() => dispatch('getState', { cacheBust: true, isRefetch: true }), nextFetchTime);
          logger.log(`[PPV State] new fetch in ${Math.ceil(nextFetchTime / 1000)}s`);
        }

        // State changing is causing watchers to execute
        delete response.created; 
        delete response.expiresIn; 
        commit('setResponse', response);
      } catch (error) {
        commit('setError', error);

        // increment error retry counter
        fetchAttempts++;
        if (fetchAttempts < ERROR_MAX_RETRIES) {
          logger.error(`[PPV State] error fetching state, retying in ${Math.ceil(ERROR_RETRY_INTERVAL / 1000)}s`);
          fetchTimer = setTimeout(() => dispatch('getState', { cacheBust: true }), ERROR_RETRY_INTERVAL);
        } else {
          logger.error('[PPV State] max error retries exceeded');
        }
      }
    }
  },
  /**
   * Resets state to defaults.
   * @param {Object} context - Vuex context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
