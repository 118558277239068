import api from '@api/api.js';
const URL = 'now';

export const getDefaults = () => ({
  now: null,
  schedule: null,
  tveDeviceId: null,
  fetched: false,
  fetching: false,
  error: null,
});

const state = getDefaults();

// TODO: Test

/**
 * Generates a 0-99 value based on the supplied id string.
 * @param {number} id 
 * @returns {number}
 */
export const generateDeviceIdBucketValue = (id) => {
  // eslint-disable-next-line prefer-template
  const idString = id + '';
  let total = idString.charCodeAt(0);
  for (let i = 1; i < idString.length; i++) {
    total += idString.charCodeAt(i);
  }
  return total % 100;
};

export const getters = {
  /**
   * Generates a test bucket value from 0-99 based on device id.
   * @param {Object} - Module state
   * @returns {(number|null)}
   */
  tveDeviceBucket (state) {
    return state.tveDeviceId ? generateDeviceIdBucketValue(state.tveDeviceId) : null;
  },
};

export const actions = {
  async getNow ({ commit, state }) {
    // Skip fetch if fetching or fetched
    if (state.fetching || state.fetched) {
      return;
    }

    commit('setError', null);
    commit('setFetching');

    try {
      const response = await api.get(URL);
      commit('setNow', response);
    } catch (error) {
      commit('setError', error);
    }
  },
};

export const mutations = {
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setNow (state, response) {
    Object.assign(state, {
      ...response,
    });
    state.fetching = false;
    state.fetched = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
