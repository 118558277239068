import api from '@api/api.js';

const URLS = {
  validate: 'coupon',
  redeem: 'coupon/redeem',
};

export const actions = {
  /**
   * Validates a coupon code.
   * @async
   * @param {Object} context - Store context.
   * @param {Object} payload
   * @param {string} payload.code - Coupon code to validate.
   * @param {string} payload.productCode - Optional product to validate coupon against.
   * @returns {Object} - Validated coupon metadata.
   * @throws {Object} - Throws on invalid coupon or API error.
   */
  async validate (context, { code, productCode }) {
    const url = productCode ? `${URLS.validate}/${code}/${productCode}` : `${URLS.validate}/${code}`;
    try {
      const response = await api.get(url);
      return response;
    } catch ({ body }) {
      // TODO: STAT-37277 Unify store error handling
      throw body;
    }
  },
  /**
   * Redeems a coupon code.
   * @param {Object} context - Store context.
   * @param {String} coupon - Coupon code to redeem.
   * @returns {Promise}
   */
  redeem (context, coupon) {
    // TODO: STAT-37277 Unify store error handling
    return api.post(URLS.redeem, { coupon });
  },
};

export default {
  namespaced: true,
  actions,
};
