/**
 * Routes for triggering purchase attribution. Simple redirect with a ?attr= query param caught by AppWrapper.
 */
export default [
  {
    path: '/hilton',
    redirect: {
      name: 'frontDoor',
      query: {
        attr: 'hotel-hilton',
      },
    },
  },
  {
    path: '/marriott',
    redirect: {
      name: 'frontDoor',
      query: {
        attr: 'hotel-marriott',
      },
    },
  },
  {
    path: '/sheraton',
    redirect: {
      name: 'frontDoor',
      query: {
        attr: 'hotel-sheraton',
      },
    },
  },
  {
    path: '/hotel',
    redirect: {
      name: 'frontDoor',
      query: {
        attr: 'hotel-generic',
      },
    },
  },
  {
    path: '/hotels',
    redirect: {
      name: 'frontDoor',
      query: {
        attr: 'hotel-generic',
      },
    },
  },
  {
    path: '/tivo',
    redirect: {
      name: 'frontDoor',
      query: {
        attr: 'tivo',
      },
    },
  },
  {
    path: '/portal',
    redirect: {
      name: 'frontDoor',
      query: {
        attr: 'portal',
      },
    },
  },
];
