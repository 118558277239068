/**
 * Button data structure.
 * @typedef {Object} ModalButton
 * @property {string} label - Button label.
 * @property {Object} [route] - Optional route for button action.
 */

/**
 * Modal state defaults.
 * @typedef {Object} ModalDefaults
 * @property {string} [title] - Modal title.
 * @property {string} body - Modal body.
 * @property {ModalButton[]} - Modal buttons.
 */

const getDefaults = () => ({
  title: '',
  body: '',
  buttons: [],
});

/**
 * Parses buttons from API error responses ['array', 'of', 'strings']
 * into a properly formatted { label, callback } array.
 * @param {string[]|Object[]} buttons - Buttons array.
 */
export const parseButtons = (buttons) => buttons.map((button) => (typeof button === 'string' ? { label: button } : button));

export const state = getDefaults();

export const getters = {
  /**
   * Inidicates if a modal is visible or not.
   * @param {Object} state - Module state
   * @returns {boolean}
   */
  visible (state) {
    return !!state.body;
  },
};

export const mutations = {
  setModal (state, { body, title, buttons = [] }) {
    state.body = body;
    state.title = title || '';
    state.buttons = buttons.length ? parseButtons(buttons) : [{ label: 'OK' }];
  },

  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Show modal action
   * @param {Object} context - Vuex module context.
   * @param {Object} modal - Modal content payload.
   * @param {string} body - Modal body.
   * @param {string} [title] - Modal title.
   * @param {string[]|Object[]} [buttons] - Modal buttons.
   * @param {string[]|Object[]} [call] - Modal buttons from an API error response.
   */
  show ({ commit }, { body, title, buttons, call }) {
    commit('setModal', { body, title, buttons: buttons || call });
  },

  /**
   * Close modal action
   * @param {Object} context - Vuex module context.
   */
  close ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
