<template>
  <!-- NOTE: check if <transition> still works if the parent owns the v-if, and potentially remove the loading prop -->
  <transition name="loader-transition">
    <div v-if="loading" class="container sho-loader" :class="componentClass" role="alert" aria-label="Loading">
      <div class="loading-indicator" aria-hidden="true" />
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'ShoLoader',

  props: {
    /**
     * Indicates if loading is active.
     */
    loading: {
      type: Boolean,
      required: true,
    },
    /**
     * Applies a higher z-index, blocking out all app use, and locks page scrolling.
     */
    blockUi: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Determines fixed, absolute, or inline position.
     * @values fixed, absolute, inline
     */
    position: {
      type: String,
      required: false,
      default: 'fixed',
      validator (value) {
        return ['fixed', 'absolute', 'inline', 'relative'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    componentClass () {
      const classes = [`pos-${this.position}`];
      if (this.blockUi) {
        classes.push('block-ui');
      }
      return classes;
    },
  },
};
</script>

<style scoped>
  .container {
    backdrop-filter: blur(1px);
    background: var(--black_25);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--z-index-page-overlay);
  }

  .container.pos-absolute {
    position: absolute;
  }

  .container.pos-relative {
    position: relative;
  }

  .container.pos-inline {
    background: none;
    bottom: auto;
    height: 75px;
    left: auto;
    padding: 20px 0;
    position: relative;
    right: auto;
    top: auto;
  }

  .container.block-ui {
    z-index: var(--z-index-app-loader);
  }

  .loading-indicator {
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .loading-indicator::after {
    animation: spin 0.9s linear 0s infinite;
    background: url("~@assets/img/spinner/spinner_new_grey.png") no-repeat;
    content: "";
    display: block;
    height: 64px;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
    position: fixed;
    top: 50%;
    width: 64px;
  }

  .container.pos-relative .loading-indicator {
    position: absolute;
  }

  .container.pos-relative .loading-indicator::after {
    position: absolute;
  }

  .loader-transition-enter-active,
  .loader-transition-leave-active {
    opacity: 1;
    transition: opacity 300ms, backdrop-filter 300ms;
  }

  .loader-transition-enter-from {
    opacity: 0;
  }

  .loader-transition-leave-to {
    opacity: 0;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
</style>
