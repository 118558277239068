import AppMain from '@src/AppMain.vue';
import { createApp } from 'vue';
import store from '@store/store.js';
import Logger from '@utils/logger.js';
import { router } from '@src/router.js';
import config from '@configs/__BUILDENV__.json';
import api from '@api/api.js';
import { APP_TYPES } from '@utils/constants.js';
import Optimizely from '@optimizely/optimizely-sdk';

export default {
  /**
   * Displays a generic error message on any pre-fetch error.
   */
  showAppError () {
    const container = document.getElementById('website-2000');
    const helpLink = (__APP_TYPE__ === APP_TYPES.ott) ? 'https://help.showtime.com/hc/en-us' : 'https://help.showtimeanytime.com/hc/en-us';
    const errorTemplate = `
      <div style="background: black; color: white; text-align: center; font-family: Arial, Helvetica, sans-serif; padding: 20px; box-sizing: border-box; width: 90%; max-width: 600px; border: 1px solid white; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
        <h1>SERVER ERROR</h1>
        <p>Looks like something went wrong. Please try again.</p>
        <p>If the problem persists please contact our <a href="${helpLink}">help center</a>.</p>
      </div>
    `;

    if (container) {
      container.innerHTML = errorTemplate;
    } else {
      this.logger.error('Container div not found.');
    }
  },

  /**
   * App data pre-fetch. Always load now, user, and dictionary before App start.
   * 'now' must be called first to establish a proper server session.
   * @returns {Promise} Promise object represents successful pre-fetch data responses.
   */
  async getPrefetchData () {
    return new Promise((resolve, reject) => {
      api.get('now')
        .then((nowData) => {
          const userFetch = api.get('user');
          const dictionaryFetch = api.get('dictionary');

          Promise.allSettled([userFetch, dictionaryFetch])
            .then((responses) => {
              const errorResponse = responses.find((response) => response.status === 'rejected' && response.reason.code !== 'error.session.loggedOut');
              if (errorResponse) {
                reject(errorResponse.reason);
                return;
              }

              const [userResponse, dictionaryResponse] = responses;

              resolve({
                nowData,
                dictionaryData: dictionaryResponse.value,
                userData: userResponse.status === 'fulfilled' ? userResponse.value : null,
              });
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Sets vuex state and instantiates Vue instance.
   * @param {Object} preFetchData
   */
  instantiateVue ({ nowData, userData, dictionaryData }) {
    store.commit('now/setNow', nowData);
    store.commit('dictionary/setDictionary', dictionaryData);
    if (userData) {
      store.commit('user/setUser', userData);
    }

    const app = createApp(AppMain);

    // Set static App config
    app.config.globalProperties.$config = config;

    // Optimizely set up
    const optimizelyClient = Optimizely.createInstance({ sdkKey: config?.optimizelySDKKey });
    optimizelyClient.onReady().then(() => {
      app.config.globalProperties.$optimizelyUser = optimizelyClient.createUserContext(nowData.tveDeviceId);
    }).catch((err) => {
      this.logger.error('Error creating Optimizely user context:', err);
    }).finally(() => {
      app.use(router);
      app.use(store);
      app.mount('#website-2000');
    });
  },

  /**
   * Performs data pre-fetch, commits responses to vuex, and instantiates Vue instance with static configs.
   * Any startup error displays generic error messaging to the user.
   */
  async start () {
    this.logger = new Logger('AppLauncher');

    try {
      const preFetchData = await this.getPrefetchData();
      this.instantiateVue(preFetchData);
    } catch (error) {
      this.logger.error('App start error', error);
      this.showAppError();
    }
  },
};
