<template>
  <div
    :class="{
      'flip-button': flipButton,
      'sp-hidden': isUIHidden
    }"
    class="minimize-sender-player-button"
    @click="$emit('minimize-sender-click')"
  >
    <ShoIcon
      class="icon minimize-sender"
      type="chevron-up"
    />
  </div>
</template>

<script>
import ShoIcon from '@components/ui/ShoIcon.vue';

export default {
  name: 'MinimizeSenderButton',

  components: {
    ShoIcon,
  },

  emits: ['minimize-sender-click'],

  props: {
    flipButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    isUIHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      showText: false,
    };
  },
};
</script>

<style scoped>
  .minimize-sender-player-button {
    cursor: pointer;
  }

  .flip-button.minimize-sender-player-button {
    height: 40px;
    opacity: 0.8;
    position: absolute;
    right: 40px;
    text-align: right;
    top: 40px;
    transition: opacity 300ms;
    width: 400px;
    z-index: 100;
  }

  .minimize-sender-player-button:hover,
  .minimize-sender-player-button:focus {
    color: white;
    opacity: 1;
  }

  span {
    display: inline-block;
    font-family: var(--font-family-medium);
    font-size: 1.25rem;
    position: relative;
    text-transform: uppercase;
    vertical-align: top;
  }

  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .icon.minimize-sender {
    height: 28px;
  }

  .flip-button .minimize-sender {
    transform: rotate(180deg);
  }

  .minimize-sender {
    height: 20px;
    margin-left: 10px;
    opacity: 0.8;
    width: 20px;
  }

  .minimize-sender:hover,
  .minimize-sender:focus {
    opacity: 1;
  }

  .minimize-sender-position {
    opacity: 0.7;
    position: absolute;
    right: 12px;
    top: 12px;
  }

  .minimize-sender-player-button.sp-hidden {
    opacity: 0;
    transition: opacity 1.5s ease-out;
  }

  .ppv-close-btn.minimize-sender-player-button {
    top: 70px;
  }
</style>
