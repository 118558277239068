import api from '@api/api.js';

const url = 'user/resetpassword';

export const actions = {
  /**
     * Verifies an account exists for the given email, emails a reset link on success.
     * @param {Object} context - Vuex module context
     * @param {string} email - Email address to check
     * @returns {Promise}
     */
  initiateReset (context, email) {
    return api.post(url, { email });
  },
  /**
     * Attempt to update a user's password
     * @param {Object} context - Vuex module context
     * @param {Object} payload
     * @param {string} payload.newPassword - User's new password
     * @param {string} payload.authToken - User's auth token
     * @returns {Promise}
     */
  updatePassword (context, { newPassword, authToken }) {
    return api.put(url, { newPassword, authToken });
  },
};

export default {
  namespaced: true,
  actions,
};
