import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('title-detail');

const url = 'titles';

export const defaultValues = {
  advisories: [],
  bi: { siteSection: '', subSections: [] },
  countdownMillis: 0,
  created: new Date('1/1/19970'),
  creditMarkerMillis: 0,
  credits: [],
  description: { short: '' },
  downloadSupported: false,
  duration: 0,
  expirationDate: new Date('1/1/19970'),
  flags: [],
  id: 0,
  images: [],
  links: [],
  name: '',
  releaseYear: 19970,
  social: { displayLike: false, displayFollow: false, facebook: '', instagram: '' },
  sortName: '',
  titleRating: '',
  titleRatingDisplayName: '',
  series: {},
  type: '',
  nextTitleId: 0,
  previousTitleId: 0,
  fetched: false,
  fetching: false,
};

const state = {
  title: { ...defaultValues },
  error: null,
};

export const getters = {
  /**
   * Returns formattedurl for sharing title on twitter
   * @param {Object} state -store state
   * @returns {string}
   */
  twitterIntentUrl: (state) => {
    const title = state.title.name;
    const social = state.title.social;
    let twitterIntentUrl = 'https://twitter.com/intent/tweet';
    let text = 'I am watching ';

    if (title && social) {
      if (state.type === 'series') {
        text += `${state.title.series.seriesTitle} S${state.title.series.seasonNum} Ep${state.title.series.episodeNum}`;
      } else {
        text += `${title}`;
      }
      twitterIntentUrl += `?text=${encodeURIComponent(text)}`;
      if (state.title.social.twitterHash) {
        twitterIntentUrl += `&hashtags=${state.title.social.twitterHash.replace('#', '')}`;
      }
      if (state.title.social.twitterUser) {
        twitterIntentUrl += `&via=${state.title.social.twitterUser.replace('@', '')}`;
      }
      twitterIntentUrl += `&url=${encodeURIComponent(window.location.href)}`;
      return twitterIntentUrl;
    } else {
      return '';
    }
  },
  /**
   * Returns object containing social media sharing urls
   * @returns {object}
   */
  socialURLS: (state, getters) => {
    const urls = {};
    urls.facebook = state.title.social.facebook;
    urls.instagram = state.title.social.instagram;
    urls.twitter = getters.twitterIntentUrl;
    return urls;
  },
};

const actions = {
  /**
   * Gets title detail data based on titleId
   * @param {number} titleId - titleId
   */
  async getTitleDetail ({ commit, rootState }, titleId) {
    const drm = rootState?.appPlayer?.drm?.uriRequestParam;
    const query = drm ? `${url}/${titleId}/format/${drm}` : `${url}/${titleId}`;

    try {
      commit('setFetching');
      const response = await api.get(query);
      commit('setTitleDetail', response);
    } catch (error) {
      logger.log(error);
      commit('setError', error);
    }
  },
  /**
   * Commits resetState mutation
   */
  resetState ({ commit }) {
    commit('resetState');
  },
};

export const mutations = {
  /**
   * Sets fetched title detail data in state
   */
  setTitleDetail (state, title) {
    state.title = { ...state, ...title }; // TODO: This shouldn't persist state during a title switch
    state.title.loaded = true;
    state.title.fetching = false;
  },
  /**
   * Sets fetching property in state
   */
  setFetching (state, fetching = true) {
    state.title.fetching = fetching;
  },
  /**
   * Sets error, fetched, and fetching properties in state
   */
  setError (state, error) {
    state.error = error;
    state.title.fetched = null;
    state.title.fetching = false;
  },
  /**
   * Resets state to defaults
   */
  resetState (state) {
    Object.assign(state.title, { ...defaultValues });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
