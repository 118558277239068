import api from '@api/api.js';
import Logger from '@utils/logger.js';

const logger = new Logger('free-full-episodes');

const URL = 'titles/collections/free/page/1';

export const defaults = {
  freeFullEpisodes: null,
  collectionMetaData: null,
  fetched: false,
  fetching: false,
};

const state = { ...defaults };

const actions = {
  /**
   * FFE API call wrapper
   * @returns {Promise}
   */
  async getTitles () {
    const response = await api.get(URL);
    return response.titles;
  },
  async getFreeFullEpisodes ({ commit }) {
    commit('setFetching', true);
    try {
      const response = await api.get(URL);
      commit('setFreeFullEpisodes', response.titles);
      commit('setCollectionMetaData', response.collectionMetadata);
    } catch (error) {
      logger.error(error);
    }
    commit('setFetching', false);
    commit('setFetched', true);
  },
};

const mutations = {
  setFreeFullEpisodes (state, response) {
    state.freeFullEpisodes = response;
  },
  setCollectionMetaData (state, response) {
    state.collectionMetaData = response;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setFetched (state, fetched = true) {
    state.fetched = fetched;
    if (fetched) {
      state.fetching = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
