import store from '@store/store.js';
import config from '@configs/__BUILDENV__.json';

const CancelAccount = () => import('@pages/ott/CancelAccountPage.vue');
const Settings = () => import('@pages/ott/SettingsPage.vue');
const ComplimentaryAccessPage = () => import('@pages/ComplimentaryAccessPage.vue');
const GiftCardPage = () => import('@pages/GiftCardPage.vue');
const OpenPaywallRoadblock = () => import('@pages/paywall/OpenPaywallRoadblock.vue');
const BundleInfoPage = () => import('@pages/paramount-bundle/BundleInfoPage.vue');
const ManageBundlePage = () => import('@pages/ManageBundlePage.vue');
const ManageBillingIntervalPage = () => import('@pages/ManageBillingIntervalPage.vue');
const GiftRedemptionPage = () => import('@pages/gift-redemption/GiftRedemptionPage.vue');

// Home wrapper contains both auth and mobile device logic
const HomeWrapper = () => import('@pages/HomeWrapper.vue');

import baseRoutes from '@routes/shared/base-routes.js';
import contentRoutes from '@routes/shared/content-routes.js';
import legalRoutes from '@routes/shared/legal-routes.js';
import paywallRoutes from '@routes/ott/paywall-routes.js';
import passwordRoutes from '@routes/ott/password-routes.js';
import ppvRoutes from '@routes/ott/ppv-routes.js';
import enhancedPurchaseRoutes from '@routes/ott/enhanced-purchase-routes.js';
import vanityRoutes from '@routes/ott/vanity-routes.js';
import attributionRoutes from '@routes/ott/attribution-routes.js';
import legacyRoutes from '@routes/shared/legacy-routes.js';

import { queryString } from '@utils/query-string-builder';
import { PURCHASE_TYPES } from '@utils/constants.js';

export default [
  ...baseRoutes,
  ...legacyRoutes,
  ...contentRoutes,
  ...legalRoutes,
  ...paywallRoutes,
  ...passwordRoutes,
  ...ppvRoutes,
  ...enhancedPurchaseRoutes,
  ...vanityRoutes,
  ...attributionRoutes,
  {
    path: '/home',
    name: 'home',
    component: HomeWrapper,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      access: {
        ott: {
          authorized: true,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },

  {
    path: '/settings/cancel',
    name: 'cancelAccount',
    component: CancelAccount,
    meta: {
      access: {
        ott: {
          authorized: true,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },
  {
    path: '/change-plan',
    name: 'changeInterval',
    component: ManageBillingIntervalPage,
    meta: {
      access: {
        ott: {
          authorized: true,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },
  {
    path: '/manage-paramountplus',
    alias: '/add-paramountplus',
    name: 'manageBundle',
    component: ManageBundlePage,
    beforeEnter (to, from, next) {
      if (!config.bundleEnabled) {
        next({ name: 'settings' });
      } else {
        next();
      }
    },
    /* @type {ShoRouteMeta} */
    meta: {
      access: {
        ott: {
          authorized: true,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
      authnFailureRoute: {
        name: 'purchaseLogin',
        params: { 
          newPurchaseType: PURCHASE_TYPES.bundle,
        },
      },
    },
  },
  {
    path: '/cancel-paramountplus',
    name: 'cancelBundle',
    component: ManageBundlePage,
    props: { forceCancel: true },
    /* @type {ShoRouteMeta} */
    meta: {
      access: {
        ott: {
          authorized: true,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
      authnFailureRoute: {
        name: 'purchaseLogin',
        params: { 
          newPurchaseType: PURCHASE_TYPES.bundle,
        },
      },
    },
  },
  /**
   * Paramount Bundle marketing page.
   */
  {
    path: '/paramountplusbundle',
    name: 'bundleInfo',
    component: BundleInfoPage,
    beforeEnter () {
      if (!config.bundleEnabled) {
        window.location.replace('https://www.sho.com/paramount-plus-bundle');
        return false;
      } else {
        return true;
      }
    },
  },

  /**
   * Complimentary Access Page
   */
  {
    path: '/complimentary-access',
    name: 'complimentaryAccess',
    component: ComplimentaryAccessPage,
  },

  /**
   * Gift Card Page
   */
  {
    path: '/gift',
    name: 'giftCard',
    component: GiftCardPage,
  },

  /**
   * Open paywall roadblock 
   */
  {
    path: '/getShowtime',
    name: 'getShowtime',
    component: OpenPaywallRoadblock,
    meta: {
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },

  /**
   * Redirects to external Showtime login page.
  */
  {
    path: '/connect/login',
    redirect: (to) => {
      window.location.href = `/scripts/connect/index.html?${queryString([], to.query)}`;
    },
  },

  /**
   * Redirects to loginresult script used by full screen partner authentication (Spotify, P+, etc.)
  */
  {
    path: '/loginresult/:id',
    redirect: (to) => {
      const queryStringItems = [`provider=${to.params.id}`];
      window.location.href = ` /scripts/partner/loginresult/index.html?${queryString(queryStringItems, to.query)}`;
    },
  },

  /**
   * Help Center redirect
   */
  {
    path: '/help',
    alias: '/help/ppv',
    beforeEnter: () => {
      const dictionary = store.state?.dictionary;
      const helpCenterUrl = dictionary?.customerService?.helpCenter || 'https://help.showtime.com/';
      window.location.replace(helpCenterUrl);
    },
  },

  /**
   * Gift redemption flow start.
   */
  {
    path: '/redeem/:code?',
    name: 'giftRedemption',
    component: GiftRedemptionPage,
    props: true,
    meta: {
      section: 'giftRedemption',
    },
  },

  /**
   * Partner Offer flow start.
   */
  {
    path: '/monster/:code?',
    name: 'monster',
    component: GiftRedemptionPage,
    props: (route) => ({
      offer: 'monster',
      code: route.params.code,
    }),
  },
];
