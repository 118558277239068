export function flatten (array, isDeep) {
  return array.reduce(
    (acc, cur) => {
      if (isDeep && Array.isArray(cur)) {
        return acc.concat(flatten(cur));
      } else {
        return acc.concat(cur);
      }
    },
    []
  );
}

export function isEmpty (obj) {
  for (const x in obj) {
    return false;
  }
  return true;
}

export function findWhere (array, criteria) {
  return array.find((item) => Object.keys(criteria).every((key) => item[key] === criteria[key]));
}

/**
* Convert text to RGBA values for CSS
* @param hexVal hexadecimal code, with lost 2 values indicating opacity
* @returns {*}
*/
export function hexToRgba (hexVal) {
  let hex = hexVal;
  let alpha = hex.slice(-2);
  const hex2Alpha = {
    'FF': 1,
    'F2': '.95',
    'E6': '.90',
    'D9': '.85',
    'CC': '.80',
    'BF': '.75',
    'B3': '.70',
    'A6': '.65',
    '99': '.60',
    '8C': '.55',
    '80': '.50',
    '73': '.45',
    '66': '.40',
    '59': '.35',
    '4D': '.30',
    '40': '.25',
    '33': '.20',
    '26': '.15',
    '1A': '.10',
    '0D': '.5 ',
    '00': '.0 ',
  };
  hex = hex.substr(0,hex.length - 2);
  alpha = (hex2Alpha[alpha]) ? hex2Alpha[alpha] : 1;
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    alpha,
    string: `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${alpha})`,
  } : null;
}

export function formatSecondsToMinutes (timeInSeconds) {
  let hours = Math.floor(timeInSeconds / (60 * 60)) % 24 || '';
  let minutes = (Math.floor(timeInSeconds / 60) % 60) || '0';
  let seconds = Math.floor(timeInSeconds % 60);

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  hours ? hours = `${hours}:` : '';

  return `${hours + minutes}:${seconds}`;
}

export function isLinearChannel (id) {
  return typeof id === 'string' && id.match(/(shoeast|showest|ppv)/);
}
