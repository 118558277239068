/**
 * STAT Registration state defaults.
 * @typedef {Object} STATRegistrationDefaults
 * @property {boolean} [isVisible] - STAT registration form modal visibility.
 */
const getDefaults = () => ({
  isVisible: false,
});

export const state = getDefaults();

export const mutations = {
  showRegistrationModal (state) {
    state.isVisible = true;
  },

  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Show STAT registration action
   * @param {Object} context - Vuex module context.
   */
  show ({ commit }) {
    commit('showRegistrationModal');
  },

  /**
   * Close STAT registration action
   * @param {Object} context - Vuex module context.
   */
  close ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
