/* global shaka */
import api from '@api/api.js';
import { muxData } from '@player/config/mux.js';
import Logger from '@utils/logger.js';

const logger = new Logger('Base Shaka');

export default class BaseShaka {
  /**
   * constructor - Given a video element, instantiates a new shaka player and
   *   adds event listeners for both the player and video.
   */
  constructor (videoEl) {
    /* eslint-disable-next-line */
    /* global shaka */
    shaka.polyfill.installAll();

    this.__video = videoEl;
    this.__player = new shaka.Player(this.__video);
    this.__certificate = null;
    this.__definition = 'hd';
    this.__startPlay = null;

    // Override attributes
    this.__keySystem = null;
    this.__serverCertificatePath = null;

    // Context bindings
    this.onLoadedData = this.onLoadedData.bind(this);
    this.onCueChange = this.onCueChange.bind(this);

    // Map of local error code handlers
    this.errorCodeHandlers = {
      3016: this.onHDCPError.bind(this),
      4012: this.onHDCPError.bind(this),
      // 6008: this.onLicenseResponseRejected, TODO: create handler for this
    };

    // Default Event Listeners
    this.__video.addEventListener('loadeddata', this.onLoadedData.bind(this));
    this.__player.addEventListener('error', this.onError.bind(this));
    this.__player.addEventListener('streaming', this.onStreaming.bind(this));
    this.__player.addEventListener('buffering', this.onBuffering.bind(this));

    // add headers to requests
    this.__player.getNetworkingEngine().registerRequestFilter((type, req) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        req.headers['X-STAT-contentVersion'] = `${__APP_TYPE__}`.toUpperCase();
        req.headers['X-STAT-appVersion'] = __VERSION__;
      }
    });

    // Container for Shaka.js parsed text cues
    this.__video.shakaCues = [];

    // Add Mux script
    const muxScript = document.createElement('script');
    const self = this;
    muxScript.onload = function () {
      self.__mux = window.initShakaPlayerMux(
        self.__player,
        {
          debug: muxData.debug,
          errorTranslator: muxData.errorTranslator,
          data: muxData.data,
        }
      );
    };
    muxScript.src = 'https://src.litix.io/shakaplayer/5/shakaplayer-mux.js';

    document.getElementsByTagName('head')[0].appendChild(muxScript);
  }

  /* ===================================
   OVERRIDE METHODS
   ==================================== */

  // Not sure if we need to keep these around.

  static get uriRequestParam () {
    throw new Error('Override this method.');
  }

  static get keySystem () {
    throw new Error('Override this method.');
  }

  static testSupport () {
    throw new Error('Override this method.');
  }

  get licenseServerUrl () {
    throw new Error('Override this method.');
  }

  /* ===================================
   GETTERS
   ==================================== */

  /**
   * manifestUrl - Given startplay data, returns either an HD or SD URI.
   */
  get manifestUrl () {
    if (!this.__startPlay) {
      return '';
    }

    if (this.__definition === 'hd') {
      return this.__startPlay.uri;
    } else {
      // Fall back to uri if sdUri is not provided
      return this.__startPlay.sdUri ? this.__startPlay.sdUri : this.__startPlay.uri;
    }
  }

  /* ===================================
   EVENT HANDLERS
   ==================================== */

  /**
   * onBuffering - Console logs out on buffering event.
   */
  onBuffering (buffering) {
    logger.log('buffering:', buffering);
  }

  /**
   * onCueChange - Handles the video element's text track cue change events.
   */
  onCueChange () {
    let textCues = [];
    const activeCues = this.__video.textTracks[0].activeCues;
    if (activeCues && activeCues.length > 0 && activeCues[0].text) {
      textCues = Object.values(activeCues).map(({ text }) => text);
    }

    // Set video element's shaka cue container to parsed text cues and trigger shaka cue change event
    this.__video.shakaCues = textCues;
    this.__video.dispatchEvent(new Event('shakaCuechange'));
  }

  /**
   * onError - Logs errors and determines if a specific error handling function needs to be executed.
   * @param error - error object with a detail property object with a code
   */
  onError (error) {
    logger.error('error:', error);
    this.__mux.loadErrorHandler(error);
    if (error.detail && error.detail.code && Object.prototype.hasOwnProperty.call(this.errorCodeHandlers, error.detail.code)) {
      const errorCodeHandlerFunction = this.errorCodeHandlers[error.detail.code];
      if (typeof errorCodeHandlerFunction === 'function') {
        errorCodeHandlerFunction.call(this, error);
      }
    }
  }

  /**
   * onHDCPError - Handles HDCP errors by downresing from HD to SD.
   * TODO: This works in Chrome but should be tested in other browsers
   */
  onHDCPError () {
    if (this.__definition === 'hd') {
      logger.log('Switching to SD');
      this.__definition = 'sd';
      this.setupLicensedPlayback();
    } else {
      if (this.__player.getAssetUri() === this.manifestUrl) {
        logger.error(`HDCP error on SD manifest: ${this.manifestUrl}`);
      }
    }
  }

  /**
   * onLoadedData - Triggered on video element's 'loadeddata' event.
   * Enables Dash.js captioning by default; enabling and disabling is handled externally.
   */
  onLoadedData () {
    if (this.__video.textTracks && this.__video.textTracks.length > 0) {
      this.__video.textTracks[0].addEventListener('cuechange', this.onCueChange);
    }
  }

  /**
   * onStreaming - Does nothing atm.
   */
  onStreaming () {
    // NO-OP
  }

  /* ===================================
   CLASS METHODS
   ==================================== */

  /**
   * initializePlayer - Initializes the Shaka.js player
   */
  initializePlayer () {
    window.player = this.__player;
  }

  /**
   * setupLicensedPlayback - Sets up Shaka player with license information and attaches manifest.
   */
  async setupLicensedPlayback (type) {
    // don't store previous buffer if in live or ppv
    // our segments are in 3s max amounts, setting behind to 6
    // allows for 2 segments in case of mismatch on restart time when
    // restarting live
    const bufferBehind = type === 'vod' ? 30 : 5;
    this.__player.configure({
      drm: {
        servers: { 'com.widevine.alpha': this.licenseServerUrl },
        advanced: {
          'com.widevine.alpha': {
            serverCertificate: this.__certificate,
          },
        },
      },
      manifest: {
        dash: {
          ignoreMinBufferTime: true,
        },
        retryParameters: {
          timeout: 180000,       // timeout in ms, after which we abort
          stallTimeout: 600000,  // stall timeout in ms, after which we abort
          connectionTimeout: 60000, // connection timeout in ms, after which we abort
          maxAttempts: 30,   // the maximum number of requests before we fail
          baseDelay: 1000,  // the base delay in ms between retries
          backoffFactor: 2, // the multiplicative backoff factor between retries
          fuzzFactor: 0.5,  // the fuzz factor to apply to each retry delay
        },
      },
      // set up streaming defaults
      streaming: {
        // allow for captions then control internally
        alwaysStreamText: true,
        bufferBehind,
        // how much buffer before start (4 segments)
        bufferingGoal: 20,
        // how much of a look ahead we want when starting after buffer
        rebufferingGoal: 5,
        ignoreTextStreamFailures: false,
        inaccurateManifestTolerance: 30,
        retryParameters: {
          timeout: 180000,       // timeout in ms, after which we abort
          stallTimeout: 600000,  // stall timeout in ms, after which we abort
          connectionTimeout: 60000, // connection timeout in ms, after which we abort
          maxAttempts: 30,   // the maximum number of requests before we fail
          baseDelay: 1000,  // the base delay in ms between retries
          backoffFactor: 2, // the multiplicative backoff factor between retries
          fuzzFactor: 0.5,  // the fuzz factor to apply to each retry delay
        },
      },
    });

    // Try to load a manifest.
    // This is an asynchronous process.
    try {
      await this.__player.load(this.manifestUrl);
      // This runs if the asynchronous load is successful.
      // Set up captions track
      const tracks = this.__player.getTextTracks();
      if (tracks && tracks.length) {
        this.__player.selectTextTrack(tracks[0]);
        this.__player.setTextTrackVisibility(true);
        this.__video.textTracks[0].mode = 'hidden';
      }
    } catch (error) {
      this.onError(error);
    }
  }

  /* ===================================
   "PUBLIC" METHODS
   ==================================== */

  /**
   * requestPlayback - Attempt licensed playback using the startPlay response.
   * @param startPlay - response object from the startplay api request
   */
  requestPlayback (startPlay, type) {
    // TODO: determine if we can use the startplay entitlement instead
    // if (process.env.NODE_ENV === 'development') {
    //   startPlay.entitlement = 'bitemyshinymetaldrm';
    // }
    startPlay.entitlement = 'bite my shiny metal drm';
    this.__startPlay = startPlay;
    this.setupLicensedPlayback(type);

    // Send event to mux with new title data
    try {
      this.__player.mux.emit('videochange', muxData.data.video);
    } catch (err) {
      logger.error('Mux not installed', err);
    }
  }

  /**
   * destroy - Requested by local web player when teardown begins.
   */
  destroy () {
    this.__video.removeEventListener('loadeddata', this.onLoadedData);
    this.__player.removeEventListener('error', this.onError);
    this.__player.removeEventListener('streaming', this.onStreaming);
    this.__player.removeEventListener('buffering', this.onBuffering);
    this.__player.unload();
    this.__player.destroy();
    try {
      this.__player.mux.destroy();
    } catch (err) {
      logger.error('mux not installed', err);
    }
  }

  /**
   * init - Request certificate if necessary and initialize the Shaka.js player.
   * @returns {Promise<any>}
   */
  async init () {
    if (this.__serverCertificatePath) {
      try {
        const response = await api.get(this.__serverCertificatePath, { responseType: 'arraybuffer' });
        this.__certificate = new Uint8Array(response);
        this.initializePlayer();
      } catch (error) {
        logger.error('Certificate Error', error);
        throw new Error('Certificate failure');
      }
    } else {
      this.initializePlayer();
    }
  }

  /**
   * setLanguage - Set the audio track to the given language.
   * @param language - string that dictates the audio channel we should use
   * (PPV ONLY)
   */
  setLanguage (language) {
    this.__player.selectAudioLanguage(language);
  }

  /**
   * checkLanguage - Returns true if there are two or more languages available,
   *   false otherwise.
   * (PPV ONLY)
   */
  checkLanguage () {
    return this.__player.getAudioLanguages() && this.__player.getAudioLanguages().length > 1;
  }

  /**
   * getAudioTracks - Return list of audio tracks
   * @returns {Array}
   */
  getAudioTracks () {
    return this.__player.getAudioLanguagesAndRoles();
  }

  /**
   * setAudioTrack - Set the audio track to the given language and role.
   * @param {Object} track - selected audio track
   */
  setAudioTrack (track) {
    this.__player.selectAudioLanguage(track.language, track.role);
  }
}
