/**
 * String manipulation helper methods.
 */

/**
 * Creates a URL friendly slug from a source string
 * Taken from: https://gist.github.com/codeguy/6684588
 * @param {string} src - Original string
 * @param {string} [separator=-] - Whitespace replacement
 * @returns {string} 
 */
export function getSlug (src, separator = '-') {
  let str = src.trim();
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  return str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '') // trim - from end of text
    .replace(/-/g, separator);
}

/**
 * Converts a camelCase string to kebab-case.
 * Taken from: https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707
 * @param {string} string 
 * @returns {string}
 */
export function camelToKebab (string) {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}
