<template>
  <div 
    class="bundle-success-content"
    data-name="bundle-purchase-success"
    :data-product-code="productCode"
    :data-addon-id="addOnId"
  >
    <button v-if="!suppressClose" class="close" aria-label="Close" @click="onConfirmClick"><CloseIcon /></button>
    <div class="sho-welcome">
      <picture>
        <source 
          :srcset="welcomeImageDesktop"
          media="(min-width: 768px)"
        >
        <img 
          :src="welcomeImageMobile"
          :srcset="welcomeImageMobile"
          class="hero"
        >
      </picture>
      
      <div v-if="isMobileDevice" class="content mobile">
        <div class="copy-container">
          <div class="copy">
            <h1 class="headline">Welcome to SHOWTIME!</h1>
            <p>You're all set! Start watching on the SHOWTIME app on any supported device.</p>
          </div>
          <div v-if="isSupportedMobileDevice" class="cta">
            <h2>Get the SHOWTIME App</h2>
            <MobileAppButton class="primary-cta" data-name="mobile-cta" />
          </div>
        </div>
      </div>

      <div v-else class="content desktop">
        <ShowtimeLogo class="showtime-logo" />
        <div class="copy-container">
          <div class="copy">
            <h1 class="headline">Welcome to SHOWTIME!</h1>
            <p>You're all set to start watching.</p>
          </div>
          <div class="cta">
            <ShoButton class="primary primary-cta" data-name="watch-cta" @click="onConfirmClick">Watch Showtime Now</ShoButton>
          </div>
        </div>
      </div>
    </div>
    <div class="paramount-activation">
      <div class="copy">
        <ParamountLogo class="paramount-logo" alt="Paramount+ Logo" />
        <p>Before you can start watching Paramount+ you'll need to activate your Paramount+ account.</p>
      </div>
      <div class="cta">
        <ShoButton
          class="white border-black"
          href="https://www.paramountplus.com/showtime"
          target="_blank"
          rel="noopener noreferrer"
          data-name="activate"
        >Activate Account</ShoButton>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobileDevice, isSupportedMobileDevice } from '@utils/mobile-detect.js';
import { setScrollLock } from '@utils/layout.js';

import CloseIcon from '@assets/img/icons/svg/close-button.svg';
import MobileAppButton from '@components/ui/MobileAppButton.vue';
import ParamountLogo from '@assets/img/ott/icons/svg/paramount_plus_logo_black.svg';
import ShoButton from '@components/ui/ShoButton.vue';
import ShowtimeLogo from '@assets/img/__APP_TYPE__/icons/svg/showtime-logo.svg';

export default {
  name: 'BundleSuccessContent',
  components: {
    CloseIcon,
    MobileAppButton,
    ParamountLogo,
    ShoButton,
    ShowtimeLogo,
  },
  emits: ['confirm'],
  props: {
    /**
     * Indicates if the 'close' button should be suppressed.
     */
    suppressClose: {
      type: Boolean,
      default: false,
    },
    /**
     * Purchase payload including product, addons, and coupon.
     */
    purchasePayload: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      welcomeImageDesktop: `${__BASE_IMAGE_URL__}/frontdoor/bundle_welcome_screen_desktop.jpg`,
      welcomeImageMobile: `${__BASE_IMAGE_URL__}/frontdoor/bundle_welcome_screen_mobile.jpg`,
    };
  },
  computed: {
    isMobileDevice,
    isSupportedMobileDevice,
    /**
     * Product code from purchase payload for tracking.
     */
    productCode () {
      return this.purchasePayload?.productCode || null;
    },
    /**
     * AddOn ID from purchase payload for tracking.
     */
    addOnId () {
      return this.purchasePayload?.addOns ? this.purchasePayload.addOns.join(',') : null;
    },
  },
  created () {
    setScrollLock(true);
  },
  beforeUnmount () {
    setScrollLock(false);
  },
  methods: {
    onConfirmClick () {
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped>
  .bundle-success-content {
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .close {
    appearance: none;
    background: none;
    border: none;
    border-radius: 0;
    color: white;
    height: 24px;
    padding: 0;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    z-index: 50;
  }

  .close svg {
    fill: white;
    height: 24px;
    width: 24px;
  }

  .hero {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .sho-welcome {
    flex: 1;
    min-height: 400px;
    position: relative;
  }

  .sho-welcome .content {
    bottom: 0;
    box-sizing: border-box;
    font-size: 14px;
    padding: 16px 32px;
    position: absolute;
    width: 100%;
  }

  .sho-welcome h1,
  .sho-welcome h2 {
    font-size: 24px;
    text-transform: uppercase;
  }

  .sho-welcome h2 {
    font-size: 18px;
  }

  .sho-welcome h1,
  .sho-welcome h2,
  .sho-welcome p {
    margin: 12px 0 0;
  }

  .primary-cta {
    margin-top: 16px;
  }

  .paramount-activation {
    background: white;
    color: black;
    flex: 0;
    padding: 16px 16px 36px;
    position: relative;
  }

  .paramount-logo {
    display: inline-block;
    height: auto;
    width: 147px;
  }

  .paramount-activation .copy {
    padding: 0 30px;
  }

  .paramount-activation .sho-button {
    display: block;
    width: auto;
  }

  .paramount-activation .copy,
  .paramount-activation .cta {
    margin: 0 auto;
    max-width: 360px;
  }

  /** Modal layout switch */
  @media (min-width: 768px) {
    .bundle-success-content {
      border-radius: 5px;
      display: block;
      margin: 0 auto;
      max-width: 680px;
      min-height: 0;
    }

    .sho-welcome {
      height: 440px;
      min-height: 0;
      text-align: left;
    }

    .sho-welcome,
    .hero {
      border-radius: 5px 5px 0 0;
    }

    .sho-welcome .copy-container,
    .paramount-activation {
      align-items: center;
      border-radius: 0 0 5px 5px;
      display: flex;
      text-align: left;
    }

    .sho-welcome .content,
    .paramount-activation {
      padding: 24px;
    }

    .sho-welcome .copy,
    .paramount-activation .copy {
      flex: 1;
      max-width: 100%;
      padding: 0 32px 0 0;
    }

    .paramount-activation .copy p {
      margin-bottom: 0;
    }

    .sho-welcome .cta,
    .paramount-activation .cta {
      flex: 0 0 196px;
    }

    .sho-welcome .copy-container {
      align-items: flex-start;
      padding: 0;
    }

    .sho-welcome .sho-button {
      margin: 0;
      width: 100%;
    }

    .showtime-logo {
      width: 120px;
    }

    .sho-welcome h1 {
      font-family: var(--font-family-medium);
      font-size: 30px;
      line-height: 1;
      margin: 0 0 8px;
    }

    .sho-welcome p {
      font-size: 16px;
      margin: 0;
    }

    .content.mobile .cta {
      text-align: center;
    }

    .content.mobile .cta h2 {
      font-size: 16px;
      margin: 0 0 8px;
    }

    .content.mobile .primary-cta {
      margin-top: 0;
    }
  }
</style>
