<template>
  <div class="mobile-app-button">
    <a :href="appDownloadUrl" class="btn-mobile" :class="cssClass">{{ buttonText }}</a>
  </div>
</template>

<script>
import { getDeviceType } from '@utils/mobile-detect.js';
import KochavaMixin from '@components/kochava/KochavaMixin.js';

/**
 * @displayName Mobile App Button
 */
export default {
  name: 'MobileAppButton',
  mixins: [KochavaMixin],
  data () {
    return {
      deviceConfigs: {
        ios: {
          cssClass: 'app-store',
          buttonText: 'Download on the App Store',
        },
        amazon: {
          cssClass: 'amazon-store',
          buttonText: 'Download on Amazon',
        },
        android: {
          cssClass: 'android-store',
          buttonText: 'Download on Google Play',
        },
      },
    };
  },
  computed: {
    /**
     * Returns device type
     * @returns {string}
     */
    deviceType () {
      return getDeviceType();
    },
    /**
     * Returns button text specific to device.
     * @returns {string}
     */
    buttonText () {
      return this.deviceConfigs[this.deviceType]?.buttonText;
    },
    /**
     * Returns css class specific to device. This class determines which button is displayed.
     * @returns {string}
     */
    cssClass () {
      return this.deviceConfigs[this.deviceType]?.cssClass;
    },
  },
};
</script>
<style scoped>
  .mobile-app-button {
    display: inline-block;
  }

  .btn-mobile {
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 40px;
    margin-right: 7px;
    margin-top: 5px;
    overflow: hidden;
    text-indent: -1000px;
    width: 137px;
  }

  .app-store {
    background-image: url("~@assets/img/badges/app-store-tablet-retina.png");
  }

  .amazon-store {
    background-image: url("~@assets/img/badges/amazon-badge-desktop.png");
  }

  .android-store {
    background-image: url("~@assets/img/badges/android-store-tablet-retina.png");
  }
</style>
