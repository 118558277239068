import api from '@api/api.js';
import { STORAGE_KEYS, RECAPTCHA_ACTIONS } from '@utils/constants';

const URLS = {
  validate: 'giftcard',
  redeem: 'giftcard/redeem',
};

export const getDefaults = () => ({
  giftCard: null,
});

export const state = getDefaults();

export const mutations = {
  initializeState (state) {
    const giftCard = localStorage.getItem(STORAGE_KEYS.giftcard);
    if (giftCard) {
      state.giftCard = JSON.parse(giftCard);
    }
  },
  saveCard (state, giftCard) {
    state.giftCard = giftCard;
    localStorage.setItem(STORAGE_KEYS.giftcard, JSON.stringify(giftCard));
  },
  reset (state) {
    Object.assign(state, getDefaults());
    localStorage.removeItem(STORAGE_KEYS.giftcard);
  },
};

export const actions = {
  /**
   * Validates a gift card code.
   * @async
   * @param {Object} context - vuex module context
   * @param {Object} payload
   * @param {string} payload.code - Gift card code
   * @param {string} payload.captchaToken - Captcha token
   */
  async validateCode (context, { code, captchaToken }) {
    const response = await api.get(`${URLS.validate}/${code}/captcha/${captchaToken}/action/${RECAPTCHA_ACTIONS.giftcardValidation}`);
    // This is a lookup API - a redeemed card is a valid API response
    /**
       * NOTE: the lookup API checking for 'redemptionDate' is how unity works, but I'm 
       * currently getting error.giftcard.redeemed for redeemed cards - this can possibly be removed
       */
    if (response.redemptionDate) {
      throw {
        body: 'This gift card has already been redeemed.',
        title: 'Error Finding Gift Card',
      };
    } else {
      return response;
    }
  },
  /**
   * Authorized user gift redemption.
   * @param {Object} context - vuex module context
   * @param {string} payload - Gift card code and recaptcha info
   */
  async redeem (context, { code, recaptcha }) {
    return api.post(URLS.redeem, { giftCardCode: code, recaptcha });
  },
  /**
   * Saves gift card to session.
   * @param {Object} context - vuex module context 
   * @param {Object} giftCard - Gift card metadata
   */
  async saveCard ({ commit }, giftCard) {
    commit('saveCard', giftCard);
  },
  /**
   * Resets state.
   * @param {Object} context - vuex module context 
   */
  async reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
