import api from '@api/api.js';
import Logger from '@utils/logger.js';

const logger = new Logger('paywall');

const BASE_URL = 'paywall';
const DEFAULT_PAGE = 'general';

const PAGE_NOT_FOUND_ERROR = {
  code: 'error.paywall.notfound',
  title: 'An Error Occurred',
  body: 'It looks like we’re having some trouble contacting our server, please try again later.',
  exceptionType: 'Paywall Error',
  exception: 'Page Not Found',
  call: ['OK'],
};

/**
 * Determines if data should be refreshed from the API based on current state and request options.
 * @param {Object} state - Current store state.
 * @param {Object} options - Options passed to the 'get data' action.
 * @param {boolean} [options.cacheBust] - Forces a re-fetch
 * @param {string} [options.flowId] - Requested reg flow
 * @param {string} [options.partnerId] - Requested reg flow partner
 * @returns {boolean}
 */
export const shouldRefreshData = function (state, options = {}) {
  // Re-fetch if state has never been fetched or 'cacheBust' requested
  if (!state.pages.length || options.cacheBust) {
    return true;
  }

  // Re-fetch if 'next' step has been requested
  if (options.page && options.page === 'next') {
    return true;
  }

  // Re-fetch if requested flow does not match the previous fetch
  if ((typeof options.flowId !== 'undefined' && options.flowId !== state.flowId) 
      || (typeof options.flowId !== 'undefined' && options.partnerId !== state.partnerId)) {
    return true;
  }

  if ((state.flowId && !options.flowId) || (state.partnerId && !options.partnerId)) {
    return true;
  }

  return false;
};

/**
 * Builds the Paywall API url.
 * @param {string} BASE_URL - Base API url.
 * @param {Object} state - Current state object.
 * @param {Object} options - Options passed to the 'get data' action.
 * @param {string} [options.cid] - Requested campaign ID 
 * @param {string} [options.flowId] - Requested reg flow
 * @param {string} [options.partnerId] - Requested reg flow partner
 * @returns {string}
 */
export const getUrl = function (BASE_URL, options) {
  const params = {};
  if (options.cid) {
    params.cid = options.cid;
  }

  if (options.flowId) {
    params.flow = options.flowId;
  }

  if (options.partnerId) {
    params.partner = options.partnerId;
  }

  const entries = Object.entries(params);
  if (entries.length) {
    const paramString = new URLSearchParams(entries).toString();
    return `${BASE_URL}?${paramString}`;
  }

  return BASE_URL;
};

/**
 * Parses bad paywall data into a more digestible format. Can be removed when paywall data addressed.
 * @param {number|string[]|Object} steps - Paywall steps - current (bad) data is an )
 */
export const parseSteps = function (steps) {
  if (typeof steps === 'number') {
    return steps;
  }

  if (Array.isArray(steps)) {
    return steps.map((step) => (typeof step === 'object' ? step.title : step));
  }

  if (typeof steps === 'object') {
    const stepsArray = Object.values(steps);
    return stepsArray.map((step) => (typeof step === 'object' ? step.title : step));
  }
};

/**
 * Flattens paywall page contents (to eliminate this.content.content.steps, etc.) and parses steps.
 * @param {Object[]} pages 
 * @returns 
 */
export const parsePages = function (pages) {
  const parsed = [];

  for (const [key, page] of Object.entries(pages)) {
    let parsedPage = { ...page };

    // Flatten content.content
    if (parsedPage.content) {
      parsedPage = { ...parsedPage, ...parsedPage.content };
      delete parsedPage.content;
    }

    // Parse bad step format
    if (parsedPage.steps) {
      parsedPage.steps = parseSteps(parsedPage.steps);
    }

    if (key === 'nextStep') {
      parsedPage.nextStep = true;
    }

    parsed.push(parsedPage);
  }

  return parsed;
};

/**
 * @typedef {Object} PaywallDefaults
 * @property {string} name - Paywall name, used for tracking purposes
 * @property {Object[]} pages - Paywall pages
 * @property {?string} flowId - Registration flow id for this paywall fetch state
 * @property {?string} partner - Registration flow partner id for this paywall fetch state
 * @property {string} activePageId - Used to return the active page getter
 */

/**
 * Creates a new defaults object for setting state.
 * @returns {PaywallDefaults}
 */
export const getDefaults = () => ({
  name: null,
  pages: [],
  flowId: null,
  partnerId: null,
  activePageId: null,
});

export const state = getDefaults();

export const mutations = {
  setPaywall (state, response) {
    state.name = response.name;
    state.pages = response.pages || [];
    state.flowId = response.flowId;
    state.partnerId = response.partnerId;
  },
  setActivePageId (state, pageId) {
    state.activePageId = pageId;
  },
  resetActivePage (state) {
    state.activePageId = null;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Handles a paywall data request action. Fetches data if necessary and sets the active page.
   * Data fetching is based on 'shouldRefreshData' response, active page defaults to 'general'.
   * After requesting a Paywall page, the page can be accessed via the 'activePage' getter below.
   * 
   * @param {Object} context - Store context 
   * @param {Object} options - Request options
   * @param {string} options.page - Requested page
   * @param {string} [options.cid] - Campaign ID to send to paywall
   * @param {string} [options.flowId] - Flow ID to send to paywall
   * @param {string} [options.partnerId] - Partner ID to send to paywall
   * @param {boolean} [options.cacheBust] - Force an API hit even when state has been fetched
   */
  async getPaywallPage ({ dispatch, commit, state }, options = {}) {
    logger.log('Paywall page requested', options);
    
    if (options.page && options.page !== state.activePageId) {
      commit('resetActivePage');
    }

    if (shouldRefreshData(state, options)) {
      logger.log('Fetching paywall data');
      
      const apiUrl = getUrl(BASE_URL, options);
      // Try/Catch handled via component
      const response = await api.get(apiUrl);
      if (response.pages) {
        response.pages = parsePages(response.pages);
      }
      commit('setPaywall', {
        ...response,
        flowId: options.flowId || null,
        partnerId: options.partnerId || null,
      });
    } else {
      logger.log('Serving cached paywall data');
    }

    // If flow 'start' was requested, check for a 'firstStep' paywall page, otherwise use default
    if (options.page === 'start') {
      const firstStep = state.pages.find((page) => page.firstStep === true) || {};
      const firstPageId = firstStep.pageName || DEFAULT_PAGE;
      dispatch('setActivePageId', firstPageId);
      return;
    } 

    // If flow 'next' was requested, check for a 'nextStep' paywall page, otherwise use default
    if (options.page === 'next') {
      const nextStep = state.pages.find((page) => page.nextStep === true) || {};
      const nextPageId = nextStep.pageName || DEFAULT_PAGE;
      dispatch('setActivePageId', nextPageId);
      return;
    }

    // If a specific page was requested, set that as active
    dispatch('setActivePageId', options.page || DEFAULT_PAGE);
  },
  /**
   * Checks if the requested active page exists and sets the id to be used by the active page getter.
   * @param {Object} context - Vuex context 
   * @param {string} requestedPageId - Page name of active paywall page requested.
   */
  setActivePageId ({ state, commit }, requestedPageId) {
    // If page exists, go there, else set an error
    commit('setActivePageId', requestedPageId);
    const pageFound = state.pages.find((page) => page.pageName === requestedPageId);
    if (!pageFound) {
      throw PAGE_NOT_FOUND_ERROR;
    }
  },
  /**
   * Resets the active paywall page getter.
   * @param {Object} context - Vuex context 
   */
  resetActivePage ({ commit }) {
    commit('resetActivePage');
  },
  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const getters = {
  /**
   * Returns page data for requested 'pageName'.
   */
  getPage: (state) => (pageName) => state.pages.find((page) => page.pageName === pageName) || {},
  /**
   * Returns page data for a page based on activePageId in state.
   * @param {Object} state - Current store module state.
   * @returns {Object|null} - Active page, if found
   */
  activePage (state) {
    if (!state.activePageId) {
      return null;
    }

    return state.pages.find((page) => page.pageName === state.activePageId) || null;
  },
  /**
   * Finds the first button in the first promo slide on the general page. 
   * This is the 'primary signup' call to action for the user's current state.
   * @param {Object} state - Current store module state.
   * @returns {Object|null}
   */
  primarySignupCTA (state) {
    const general = state.pages?.find((page) => page.pageName === 'general');
    return general?.buttons?.buyButton;
  },
  /**
   * Finds active Paywall Page's hero image (page background) URL, if the image exists
   * @returns {string|null}
   */
  paywallBackgroundImage (state) {   
    const page = state.pages.find((page) => page.pageName === state.activePageId);
    if (page && page.images) {
      const image = page.images.find((image) => image.type ===  'PAYWALL_HERO'); 
      if (image && image.url) {
        return image.url;
      }
    }
    return null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
