import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('curated-list-detail');

const curatedUrl = 'mixed/collections';

export const defaults = {
  categoryMetadata: {},
  items: [],
  page: null,
  pageCount: null,
  resultCount: null,
  created: null,
  fetching: false,
  error: null,
};

const state = { ...defaults };

export const getters = {
  /** 
   * Returns parsed titles for curated list
   * @returns {array}
   */
  curatedListItems: (state) => (state.items ? parseItems(state.items) : null),
  /**
   * Returns data for hero 
   * @returns {object}
   */
  heroItem: (state) => (state.categoryMetadata ? state : null),
};

const actions = {
  /**
   * Gets titles in curated list based on curated list id
   * @param {number} curatedListId - curated list id
   * @returns {Promise} - Promise resolving with curated list items
   */
  getCuratedList ({ commit }, curatedListId) {
    commit('setFetching');
    return api.get(`${curatedUrl}/${curatedListId}`).then((response) => {
      commit('setCuratedListItems', response);
    })
      .catch((error) => {
        logger.error(error); 
        commit('setError', error);  
      });
  },
};

export const mutations = {
  setCuratedListItems (state, response) {
    Object.assign(state, response);
    state.fetching = false;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
};

const parseItems = (items) => items.map((item) => {
  const data = item;
  return {
    ...data,
    description: data.description.short,
  };
});


export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
