/**
 * Default metadata for setting up Mux analytics
 */

/* eslint-disable camelcase */
class MuxData {
  constructor () {
    this.debug = false;

    this.data = {
      env_key: null,
      player_name: 'ShowtimePlayer',
      player_version: 'ws2k',
      page_type: 'watchpage',
      player_init_time: Date.now(),
    };
  }

  /**
   * Function to process errors before they are emitted to Mux
   * @param Object error 
   * @returns {Boolean|Object}
   */
  errorTranslator (error) {
    // 3016 - HDCP error
    // 4012 - HDCP error
    const errorsToIgnore = [3016, 4012];
    if (errorsToIgnore.includes(error.player_error_code)) {
      return false;
    }
    return {
      player_error_code: error.player_error_code,
      player_error_message: error.player_error_message,
    };
  }

  setDebug (val) {
    this.debug = val;
  }
  
  setData (newData) {
    this.data = { ...this.data, ...newData };
  }
}
/* eslint-enable camelcase */

export const muxData = new MuxData();
