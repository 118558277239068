export default {
  data () {
    return {
      focusableEls: null,
      focusedElBeforeOpen: null,
      firstFocusableEl: null,
      lastFocusableEl: null,
      modalContainerEl: null,
    };
  },
  methods: {
    /**
     * Set up focusable elements for accessibility
     * @param {HTMLElement} modalContainerEl
     */
    initAccessibility (modalContainerEl) {
      this.modalContainerEl = modalContainerEl;
      modalContainerEl.addEventListener('keydown', this.handleTabKey);
      this.focusedElBeforeOpen = document.activeElement;
      const focusableEls = modalContainerEl.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
      );
      this.focusableEls = [...focusableEls];
      this.firstFocusableEl = focusableEls[0];
      this.lastFocusableEl = this.focusableEls[this.focusableEls.length - 1];
      this.firstFocusableEl.focus();
    },
    /**
     * Handle Tab Key Press
     */
    handleTabKey (e) {
      if (e.which === 9) {
        if (this.focusableEls.length === 1) {
          return;
        }

        if (e.shiftKey) {
          this.handleBackwardTab(e);
        } else {
          this.handleForwardTab(e);
        }
      }
    },
    handleForwardTab (e) {
      if (document.activeElement === this.lastFocusableEl) {
        e.preventDefault();
        this.firstFocusableEl.focus();
      }
    },
    handleBackwardTab (e) {
      if (document.activeElement === this.firstFocusableEl) {
        e.preventDefault();
        this.lastFocusableEl.focus();
      }
    },
    beforeUnmount () {
      this.modalContainerEl.removeEventListener('keydown', this.handleTabKey);
      if (this.focusedElBeforeOpen) {
        this.focusedElBeforeOpen.focus();
      }
    },
  },
};
