const StartFlowPage = () => import('@pages/paywall/StartFlowPage.vue');

export default [
  /**
   * Generic Rendezvous Start route
   */
  {
    path: '/rendezvous/:deviceId',
    name: 'rendezvousStart',
    component: StartFlowPage,
    props: (route) => ({
      flowId: 'rendezvous',
      partnerId: route.params.deviceId,
      page: 'start',
    }),
    meta: {
      section: 'paywall',
    },
  },

  /**
   * Samsung Rendezvous Registration Flow
   */
  {
    path: '/samsung',
    name: 'rendezvousSamsung',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'samsung' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * Xbox Rendezvous Registration Flow
   */
  {
    path: '/xbox',
    name: 'rendezvousXbox',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'xbox' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * LG Rendezvous Registration Flow
   */
  {
    path: '/lg',
    alias: '/lgtv',
    name: 'rendezvousLG',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'lg' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * Oculus Rendezvous Registration Flow
   */
  {
    path: '/oculus',
    name: 'rendezvousOculus',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'oculus' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * PS4 Rendezvous Registration Flow
   */
  {
    path: '/ps4',
    alias: '/playstation4',
    name: 'rendezvousPS4',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'ps4' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * PS5 Rendezvous Registration Flow
   */
  {
    path: '/ps5',
    alias: '/playstation5',
    name: 'rendezvousPS5',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'ps5' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * Xfinity Rendezvous Registration Flow
   */
  {
    path: '/xfinity',
    alias: '/comcast',
    name: 'rendezvousXfinity',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'xfinity' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * XClass Rendezvous Registration Flow
   */
  {
    path: '/xclass',
    name: 'rendezvousXclass',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'xclass' },
    meta: {
      section: 'paywall',
    },
  },

  /**
   * COX Rendezvous Registration Flow
   */
  {
    path: '/cox',
    name: 'rendezvousCox',
    component: StartFlowPage,
    props: { flowId: 'rendezvous', partnerId: 'cox' },
    meta: {
      section: 'paywall',
    },
  },
];
