<template>
  <div id="app" class="body">
    <AppWrapper
      v-if="routerReady"
      v-show="appReady"
      :class="bodyClass"
      @ready="onWrapperReady"
    >
      <template v-if="appReady">
        <div class="layout-default">
          <router-view />
          <AppFooter v-if="!footerHidden" />
        </div>
        <AppDrawer />
        <ShoLoader :loading="loading" block-ui />
        <MiniSender
          v-if="senderMinimized"
          @maximize-sender="onMaximizeSender"
        />
        <AppTOSNotification />
        <ShoDataLayer />
      </template>
    </AppWrapper>
    <AppModal />
    <AppPreLoader v-if="!appReady" />
  </div>
</template>

<script>
import AppDrawer from '@components/app/AppDrawer.vue';
import AppFooter from '@components/layout/footer/AppFooter.vue';
import AppModal from '@components/app/AppModal.vue';
import AppPreLoader from '@components/app/AppPreLoader.vue';
import AppTOSNotification from '@components/app/AppTOSNotification.vue';
import AppWrapper from '@components/app/__APP_TYPE__/AppWrapper.vue';
import MiniSender from '@components/mini-sender/MiniSender.vue';
import ShoLoader from '@components/ui/ShoLoader.vue';
import ShoDataLayer from '@components/analytics/ShoDataLayer.vue';

import { mapActions, mapState, mapGetters } from 'vuex';

import { APP_TYPES } from '@utils/constants.js';
import { setScrollLock } from '@utils/layout.js';
import loadScript from '@utils/load-external-script.js';
import { isMobileDevice } from '@utils/mobile-detect.js';

import Logger from '@utils/logger.js';
const logger = new Logger('Main');

import EventBus from '@player/utils/event-bus.js';

export default {
  name: 'AppMain',
  components: {
    AppDrawer,
    AppFooter,
    AppModal,
    AppPreLoader,
    AppTOSNotification,
    AppWrapper,
    MiniSender,
    ShoLoader,
    ShoDataLayer,
  },
  data () {
    return {
      routerReady: false, // tracks that the router is ready before displaying wrapper
      wrapperReady: false, // tracks that stat/ott startup logic has completed before showing router-view
    };
  },
  computed: {
    ...mapState('dictionary', { cafId : 'caf.id' }),
    ...mapState(['drawerOpen', 'footerHidden']),
    ...mapGetters(['loading']),
    ...mapState('user', { tveUserId: 'tveUserId', isAuthorized: 'isAuthorized' }),
    ...mapState('appPlayer', ['senderMinimized', 'titleId', 'drmChecked']),

    /**
     * Indicates the app is ready for use.
     * User and dictionary fetched, and OTT/STAT startup logic has completed.
     * @returns {boolean}
     */
    appReady () {
      return this.wrapperReady && this.drmChecked;
    },

    /**
     * Computed classes for the <body> element.
     * @returns {string[]}
     */
    bodyClass () {
      const bodyClass = [];

      // Apply My List open styles
      if (this.drawerOpen) {
        bodyClass.push('drawer-open');
      }

      return bodyClass;
    },
  },
  watch: {
    /**
     * Watches user authentication state, updates $optimizelyUser 'tveUserId' attribute, and performs redirect if necessary.
     * @param {string} newValue - Current tveUserId value
     * @param {string} oldValue - Previous tveUserId value
     */
    tveUserId: {
      immediate: true,
      handler (newValue, oldValue) {
        if (oldValue && !newValue) {
          this.$store.dispatch('setDrawerOpen', false);
          if (__APP_TYPE__ === APP_TYPES.ott && this.$route.name !== 'frontDoor') {
            this.$router.push({ name: 'frontDoor' });
          }
          if (__APP_TYPE__ === APP_TYPES.stat && this.$route.name !== 'home') {
            this.$router.push({ name: 'home' });
          }
        }

        if (this.$optimizelyUser) {
          this.$optimizelyUser.setAttribute('isAuthenticated', !!newValue);
        }
      },
    },

    /**
     * Watches user authorization state and updates $optimizelyUser 'isAuthorized' attribute.
     * @param {boolean} newValue - Current isAuthorized value
     */
    isAuthorized: {
      immediate: true,
      handler (newValue) {
        if (this.$optimizelyUser) {
          this.$optimizelyUser.setAttribute('isAuthorized', newValue);
        }
      },
    },

    /**
     * Locks window scrolling during app-level loading.
     * @param {boolean} loading - Indicates if app-level loading
     */
    loading (loading) {
      if (loading) {
        setScrollLock();
      } else {
        setScrollLock(false);
      }
    },
  },

  /**
   * Created lifecycle hook
   */
  async created () {
    await this.$router.isReady();
    this.routerReady = true;

    // Set base Optimizely attributes
    if (this.$optimizelyUser) {
      this.$optimizelyUser.setAttribute('displayType', 'WEB');
      this.$optimizelyUser.setAttribute('contentType', __APP_TYPE__.toUpperCase());
      this.$optimizelyUser.setAttribute('appVersion', __VERSION__);
      this.$optimizelyUser.setAttribute('isMobileDevice', isMobileDevice());
    }

    // Set the appropriate drm on app start
    this.$store.dispatch('appPlayer/checkDRM');

    // If we detect that the app is running in a Chrome browser, try to setup for Chromecast
    window.__onGCastApiAvailable = function (isAvailable) {
      this.checkAndInitializeChromecast(isAvailable);
    }.bind(this);

    // instantiate global event bus
    window.eventBus = new EventBus();

    // Load the Chromecast sender framework script
    if (Object.prototype.hasOwnProperty.call(window, 'chrome')) {
      try {
        logger.log('loading chromecast sender script');
        await loadScript('https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1');
      } catch (error) {
        logger.error('error while loading chromecast sender script:', error);
      }
    }

    // Close player on browser back button
    // play routes are never handled by vue router, so native JS is used here
    window.addEventListener(
      'popstate',
      () => this.$store.dispatch('appPlayer/newTitleId', null)
    );
  },

  methods: {
    ...mapActions('appPlayer', ['initializeChromecast']),

    /**
     * Continously attempts to initialize Chromecast app if it is detected to be
     *  available and that we have a receiver to connect to
     */
    checkAndInitializeChromecast (isAvailable = true) {
      if (isAvailable && this.cafId) {
        this.chromecastInitializeFailures = 0;
        this.initializeChromecast(this.cafId);
      } else if (this.chromecastInitializeFailures < 15) {
        this.chromecastInitializeFailures++;
        setTimeout(this.checkAndInitializeChromecast, 2000);
      }
    },

    /**
     * When requested from the minimized cast player sender, dispatches event to
     *  maximize Chromecast sender view to full screen
     */
    onMaximizeSender () {
      this.$store.dispatch('appPlayer/storeOrigin', this.$route);
      this.$store.dispatch('appPlayer/maximizeSender');
      this.$router.push({ name: 'play', params: { titleId: this.titleId } });
    },

    /**
     * Handles 'ready' event emitted after STAT/OTT specific startup logic has completed.
     */
    onWrapperReady () {
      this.wrapperReady = true;
    },
  },
};
</script>

<style scoped>
  .layout-default {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  @media (min-width: 768px) {
    /* My List Open Effect */
    .body {
      transition: margin 400ms ease-in-out;
      transition-delay: 75ms;
    }

    .body.drawer-open {
      margin: 0 var(--open-drawer-nudge) 0 calc(-1 * var(--open-drawer-nudge));
    }
  }
</style>
