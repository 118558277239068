import api from '@api/api.js';
import { BILLING_TYPES } from '@utils/constants.js';

const url = 'dictionary';

export const defaults = {
  fetched: false,
  accessibility: '',
  customerService: {
    emailUs: '',
    helpCenter: '',
    liveChat: '',
  },
  disclaimer: '',
  legal: {
    footer: '',
    meta: {
      disableAdTracking: {},
      gift: {},
      privacy: {},
      tou: {},
    },
    links: {
      disableAdTracking: '',
      gift: '',
      privacy: '',
      tou: '',
    },
    text: {},
  },
  social: {
    facebook: '',
    instagram: '',
    twitter: '',
  },
  newsletterSignup: {
    pubid: 0,
    url: '',
  },
  msos: [],

  // Additional Client-Side Dictionary Entries

  /**
   * Maps rendezvous partner IDs to device labels used in user-facing messaging.
   */
  rendezvousDevices: {
    cox: 'Wireless 4K Contour box',
    samsung: 'Samsung TV',
    xbox: 'Xbox',
    lg: 'LG TV',
    oculus: 'VR Headset',
    ps4: 'PS4',
    ps5: 'PS5',
    xfinity: 'Xfinity device',
    xclass: 'XClass TV',
  },

  /**
   * Maps billing types to labels used in user-facing messaging.
   */
  billingTypes: {
    [BILLING_TYPES.bacon]: 'Showtime.com',
    [BILLING_TYPES.porku]: 'Roku',
    [BILLING_TYPES.scrapple]: 'Apple',
    [BILLING_TYPES.spamazon]: 'Amazon',
    [BILLING_TYPES.hamdroid]: 'Google',
    [BILLING_TYPES.cablevision]: 'Optimum',
    [BILLING_TYPES.snoutify]: 'Spotify',
    [BILLING_TYPES.corndog]: 'Paramount+',
    [BILLING_TYPES.salamisclub]: "Sam's Club",
  },

  /**
   * Billing types associated with paywall registration flows.
   * Used for Dual Billing Error messaging.
   */
  flowBillingTypes: {
    default: BILLING_TYPES.bacon,
    paramountplus: BILLING_TYPES.corndog,
    spotify: BILLING_TYPES.snoutify,
    optimum: BILLING_TYPES.cablevision,
    samsclub: BILLING_TYPES.salamisclub,
  },

  /**
   * Help Center URL for PPV supported devices.
   */
  ppvHelpCenterUrl: 'https://help.showtime.com/hc/en-us/articles/360052727934-What-devices-can-I-use-to-watch-the-PPV-event-',

  /**
   * Help Center URL for Showtime supported devices.
   */
  supportedDevicesUrl: 'https://showtime.zendesk.com/hc/en-us/articles/206396958-What-devices-can-I-use-to-watch-',

  /**
   * Impact affiliate id
   */
  impactId: 19233,
  impactIdPPV: 22486,

  /**
   * SHO.com Order Now page
   */
  statOrderUrl: 'https://www.sho.com/order',

  error: null,

  googlePolicyLinks: {
    terms: 'https://policies.google.com/terms',
    privacy: 'https://policies.google.com/privacy',
  },
};

const state = {
  ...defaults,
};

export const getters = {};

const actions = {
  getDictionary ({ commit, state }) {
    // Never re-fetch dictionary
    if (state.fetched) {
      return;
    }

    return api.get(`${url}`).then((response) => {
      commit('setDictionary', { ...defaults, ...response });
    })
      .catch((error) => commit('setError', error));
  },
};

export const mutations = {
  setDictionary (state, dictionary) {
    Object.assign(state, dictionary);
    state.fetched = true;
  },
  setError (state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
