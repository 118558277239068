<template>
  <component
    :is="componentType"
    v-bind="hrefAttr"
    class="sho-button"
    :class="buttonClass"
    data-name="sho-button"
    :data-route="to ? to.name : null"
    :rel="linkRelationship"
    :target="target"
    :to="to"
    :type="buttonType"
    :disabled="disabled || null"
    :icon="icon"
    @click="onClick($event)"
  >
    <ShoIcon v-if="icon" :type="icon" /><slot />
  </component>
</template>

<script>
import ShoIcon from '@components/ui/ShoIcon.vue';

// Available style variations
export const VARIATIONS = ['', 'black', 'dark', 'link', 'primary', 'ppv', 'white'];

export default {
  name: 'ShoButton',
  components: { ShoIcon },
  emits: ['click'],
  props: {
    href: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
    to: {
      type: [String, Object],
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Applies 'display: block' to the button
     */
    block: {
      type: Boolean,
      default: false,
    },
    /**
     * ['black', 'dark', 'link', 'primary', 'ppv', 'white',]
     */
    variation: {
      type: String,
      required: false,
      default: null,
      validator (value) {
        return VARIATIONS.includes(value);
      },
    },
  },
  computed: {
    componentType () {
      if (this.href) {
        return 'a';
      }

      if (this.to) {
        return 'router-link';
      }

      return 'button';
    },
    buttonType () {
      if (this.href || this.to) {
        return null;
      }

      return this.type || 'button';
    },
    buttonClass () {
      const classList = {
        block: this.block,
      };

      if (this.variation) {
        classList[this.variation] = true;
      }
      
      return classList;
    },
    linkRelationship () {
      return this.href && this.target === '_blank' ? 'noopener noreferrer' : null;
    },
    // This is a workaround so that empty href's don't end up on router-link
    hrefAttr () {
      if (this.href) {
        return { href: this.href };
      }
      return {};
    },
  },
  methods: {
    onClick (e) {
      // NOTE: @click will still need to be used for now on parent components using <ShoButton>:
      // https://github.com/vuejs/vue/issues/10939 
      // Even with a dymamic directive, the '.native' modifier can't be used here to bubble events
      // for <button> or <a> tags, but it's necessary for <router-link> tags:
      // https://github.com/vuejs/vue/issues/10939#issuecomment-626677017
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped>
/* TODO: STAT-36149 Swap dark and default styles */
.sho-button {
  appearance: none;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0%);
  border-radius: 2px;
  box-sizing: border-box;
  color: var(--xlight_grey);
  display: inline-block;
  font-family: var(--font-family-medium);
  font-size: var(--font-size-caption);
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
}

.sho-button:hover {
  color: white;
}

.sho-button:focus-visible,
.sho-button:active {
  color: white;
}

.sho-button:focus-visible {
  outline: 1px solid white;
}

.sho-button:active {
  outline: none;
}

.sho-button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;

  /* automatically kills subclass :hover states, but disallows cursor styling */
  pointer-events: none;
}

.sho-button.block {
  display: block;
  width: 100%;
}

.sho-button.with-animated-input {
  height: 50px;
  line-height: 50px;
}

/* TODO: Add 'with icon' styles */

.sho-button.primary {
  background-color: var(--sho_primary);
  border-color: var(--sho_primary);
  color: white;
}

.sho-button.primary:hover,
.sho-button.primary:focus-visible {
  background-color: var(--sho_secondary);
  border-color: var(--sho_secondary);
}

.sho-button.primary:focus-visible {
  outline-offset: -1px;
}

.sho-button.ppv {
  background-color: var(--ppv_primary);
  border-color: var(--ppv_primary);
  color: white;
}

.sho-button.ppv:hover {
  background-color: var(--ppv_secondary);
  border-color: var(--ppv_secondary);
}

.sho-button.dark {
  background-color: var(--black_50);
  border-color: var(--white_40);
  color: white;
}

.sho-button.black {
  background-color: black;
  border-color: black;
  color: white;
}

.sho-button.dark:hover,
.sho-button.dark:focus-visible {
  border-color: white;
  outline: none;
}

.sho-button.white {
  background-color: white;
  border-color: white;
  color: var(--black_90);
}

.sho-button.white:hover {
  background-color: var(--white_90);
  border-color: var(--white_90);
}

.sho-button.link {
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  height: auto;
  line-height: inherit;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
}

.sho-button.link:focus {
  border: 1px solid white;
  border-radius: 3px;
}

.sho-icon {
  margin-right: 0.5em;
}

.sho-button.border-black {
  border-color: black;
}

.sho-button.border-black:hover {
  border-color: var(--dark_grey);
}
</style>
