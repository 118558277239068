/**
 * STAT Activation state defaults.
 * @typedef {Object} STATActivationDefaults
 * @property {boolean} [isVisible] - STAT activation visibility.
 * @property {boolean} [withRegistration] - True if registration flow happened in the middle of activation
 */
const getDefaults = () => ({
  isVisible: false,
  withRegistration: false,
});

export const state = getDefaults();

export const mutations = {
  setVisibility (state, isVisible = true) {
    state.isVisible = isVisible;
  },

  setRegistration (state) {
    state.withRegistration = true;
  },

  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Show STAT activation action
   * @param {Object} context - Vuex module context.
   */
  show ({ commit }) {
    commit('setVisibility', true);
  },

  /**
   * Close STAT activation action
   * @param {Object} context - Vuex module context.
   */
  close ({ commit }) {
    commit('setVisibility', false);
  },
  
  /**
   * Reset store state
   * @param {Object} context - Vuex module context
   */
  reset ({ commit }) {
    commit('reset');
  },

  /**
   * Tracks that user registration occured during device activation.
   * @param {Object} context - Vuex module context.
   */
  setRegistration ({ commit }) {
    commit('setRegistration');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
