import api from '@api/api.js';

const passwordUrl = 'cbs/okta/password';
const sessionUrl = 'cbs/okta/user/profile';

export const actions = {
  /**
   * Validates OKTA session
   */
  checkSession () {
    return api.get(sessionUrl);
  },
  /**
   * Submits password form
   * @param {Object} context - vuex module context
   * @param {Object} payload - payload containing user's password, confirmed password, and boolean if terms were checked.
   */
  updatePassword (context, payload) {
    return api.post(passwordUrl, payload);
  },
};

export default {
  namespaced: true,
  actions,
};
