/**
 * Returns a set of default route access configs.
 * @returns {ShoRouteAccessConfig}
 */
export const getDefaultAccess = (env) => (env === 'ott' ? {
  authorized: true,
  authenticated: true,
  unauthenticated: true,
  mobile: false,
} : {
  authenticated: true,
  unauthenticated: true,
  mobile: false,
});
