const ls = window.localStorage;

export default {
  /**
   * Sets an item to localStorage with an optional TTL.
   * @param {string} key - Storage key
   * @param {*} value - Storage item value
   * @param {number} expires - Time to expry in days
   */
  setItem (key, value, expires = null) {
    const now = new Date();
    const days = expires ? expires * 1000 * 60 * 60 * 24 : null;
    const item = {
      value,
      expires: expires ? now.getTime() + days : null,
    };
    ls.setItem(key, JSON.stringify(item));
  },

  /**
   * Gets an item from local storage if it has not expired.
   * @param {string} key - Storage item key
   * @returns {*}
   */
  getItem (key) {
    const itemJSON = ls.getItem(key);
    if (!itemJSON) {
      return null;
    }

    const item = JSON.parse(itemJSON);
    const now = new Date();
    if (item.expires && now.getTime() > item.expires) {
      ls.removeItem(key);
      return null;
    }

    return item.value;
  },

  /**
   * localStorage removeItem wrapper
   * @param {string} key 
   */
  removeItem (key) {
    ls.removeItem(key);
  },

  /**
   * localStorage clear wrapper
   */
  clear () {
    ls.clear();
  },
};
