export const contactFormURL = '/api/help-center/contact';

export const actions = {
  submit (module, data) {
    const payload = urlEncodedPayload(data);
    return fetch(contactFormURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: payload,
    });
  },
};

/**
 * encodes the payload for the POST.
 * @returns {string} 
 * @param {object} - payload
 */
const urlEncodedPayload = function (payload) {
  return Object.keys(payload).map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(payload[k])}`)
    .join('&');
};

export default {
  namespaced: true,
  actions,
};
