import api from '@api/api.js';
import bundlePayload from '@pages/enhanced-purchase/bundle-payload.json';
import config from '@configs/__BUILDENV__.json';

import Logger from '@utils/logger.js';
const logger = new Logger('available-plan-intervals');

const URLS = {
  available: 'user/availableplans/duration',
  update: 'user/plan',
};

const GENERIC_ERROR = {
  title: 'Error',
  body: 'Something went wrong. Please try again.',
  call: ['OK'],
};

const getDefaults = () => ({
  newPlan: null,
  currentPlan: null,
  fetching: false,
  fetched: false,
  error: null,
});

export const state = getDefaults();

export const mutations = {
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setFetched (state, value) {
    state.fetched = value;
  },
  setResponse (state, response) {
    state.newPlan = response.plans && response.plans.length && response.plans[0] || null;
    state.currentPlan = response.currentPlan || {};
    state.fetching = false;
    state.fetched = true;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  clearError (state) {
    state.error = null;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  async getAvailableIntervals ({ commit, state }) {
    if (state.fetching) {
      return;
    }
    
    try {
      commit('setFetching');
      // todo: WEB-4 move non-reactive config options to a static file
      const payload = config.LTOCouponEnabled ? bundlePayload : { plans: [] };
      const response = await api.post(URLS.available, payload);
      // If more than 1 plan returned, log and throw
      if (response.plans && response.plans.length > 1) {
        logger.error('Multiple plans returned from interval change check', response);
        throw GENERIC_ERROR;
      }

      commit('setResponse', response);
    } catch (error) {
      commit('setError', error);
      commit('setFetching', false);
      commit('setFetched', true);
    }
  },
  updateInterval (state, payload) {
    return api.put(URLS.update, payload);
  },
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
