/* jshint esversion:6 */
/**
 * Utility constants for avoiding direct string comparisons with common API values.
 */

/**
 * Content Type, Live vs PPV vs VOD
 * @const
 * @type {Object}
 * @property {string} live - Live
 * @property {string} ppv - PPV
 * @property {string} vod - Video On Demand
 * @default
 */
export const CONTENT_TYPE = {
  live: 'LIVE',
  ppv: 'PPV',
  vod: 'VOD',
};

/**
 * Playlist Types for VOD titles
 * @const
 * @type {Object}
 * @property {string} preRoll - Pre_Roll
 * @property {stirng} main - Main
 * @default
 */
export const PLAYLIST_TYPE = {
  preRoll: 'PRE_ROLL',
  main: 'MAIN',
};

/**
 * Playback Statuses
 * @const
 * @type {Object}
 * @property {string} playing - Playing
 * @property {stirng} pause - Paused
 * @property {stirng} paused - Paused
 * @property {stirng} buffering - Buffering
 * @property {stirng} waiting - Waiting
 * @property {stirng} seeking - Seeking
 * @property {stirng} idle - Idle
 * @property {string} ended - Ended
 * @default
 */
export const STATUSES = {
  playing: 'PLAYING',
  pause: 'PAUSED',
  paused: 'PAUSED',
  buffering: 'BUFFERING',
  waiting: 'WAITING',
  seeking: 'SEEKING',
  idle: 'IDLE',
  ended: 'ENDED',
};

/**
 * Title Type for current content
 * @const
 * @type {Object}
 * @property {string} episode - Episode
 * @property {string} movie - Movie
 * @default
 */
export const TITLE_TYPE = {
  episode: 'Episode',
  movie: 'Movie',
};

/**
 * Time in seconds before actual end of video
 * when it should be considered fully watched
 * @const
 * @type {Number}
 */
export const END_OF_CONTENT_BUFFER = 60 * 3;

/**
 * Time in seconds before actual end of pre-roll
 * when it should be considered fully watched
 * @const
 * @type {Number}
 */
export const END_OF_AD_BUFFER = 1;

/**
 * Time in seconds to go forward / back for ff and rewind
 * @const
 * @type {Number}
 */
export const TIME_SKIP_INTERVAL = 10;

/**
 * Default settings for autoplay prompt
 * @const
 * @type {Object}
 * @property {Number} playcount - # of autoplayed items this playback session
 * @property {Number} countdown - How long to display the autoplay prompt before defaulting
 * @property {Bool} isVisible - Is the autoplay prompt visible
 * @property {Bool} userDismissed - Did the user dismiss the autoplay prompt
 * @property {Bool} playNewVODTitleFromBeginning - Restart playback on autoplay, or honor bookmarks
 * @default
 */
export const AUTOPLAY_DEFAULTS = {
  playCount: 0,
  countdown: 10,
  isVisible: false,
  userDismissed: false,
  playNewVODTitleFromBeginning: false,
};

export const CLOSED_CAPTIONING_DEFAULTS = {
  foregroundColor: '#FFFFFF',
  foregroundOpacity: 'FF',
  backgroundColor: '#000000',
  backgroundOpacity: 'FF',
  windowColor: '#000000',
  windowOpacity: 'FF',
  fontFamily: 'DEFAULT',
  edgeType: 'NONE',
  fontScale: '1',
};

export const CAST_FONTS = {
  DEFAULT: 'SANS_SERIF',
  CURSIVE: '.Savoye LET CC.',
  CASUAL: 'Avenir Next',
  SMALL_CAPITALS : 'Copperplate',
  SANS_SERIF: 'Helvetica',
  SERIF: 'Iowan Old Style',
  MONOSPACED_SERIF: 'Courier',
};

export const WEB_FONTS = {
  DEFAULT: 'Sans-serif',
  CURSIVE: 'Coronet_LT_Std, "Brush Script MT", cursive',
  CASUAL: 'bitstream_vera_serifroman, "Comic Sans", "Avenir Next"',
  SMALL_CAPITALS: 'berlinsmallcapsregular, Copperplate',
  SANS_SERIF: 'Helvetica, Arial, sans-serif',
  SERIF: '"Times New Roman", "Iowan Old Style", serif',
  MONOSPACED_SERIF: 'monospacemedium, Courier, monospace',
};

/**
 * Time in milliseconds offsets from east time
 * @const
 * @type {Object}
 * @property {Number} shoeast - shoeast offset in milliseconds (0)
 * @property {Number} showest - showest offset in milliseconds (3 hours)
 */
export const LINEAR_OFFSETS = {
  shoeast : 0,
  showest : 10800000, // 3 hours in milliseconds
};

/**
 * Object that stores default current title data
 * @const
 * @type {Object}
 */
export const CURRENT_TITLE_DEFAULTS = {
  isLive: false,
  titleType: '',
  primaryName: '',
  secondaryName: '',
  seasonNumber: null,
  episodeNumber: null,
  description: '',
  duration: null,
  releaseYear: null,
  rating: null,
  image: null,
  playlistType: '',
  name: '',
  title: '',
  refid: '',
  titleId: '',
};

export const SKIP_TYPES = {
  intro: 'SKIP_INTRO',
  preRoll: 'SKIP_PREROLL',
  recap: 'SKIP_RECAP',
};
