<template>
  <div
    v-bind="formattedData"
    class="sho-data-element"
    data-name="digitalData"
    :data-data-element="name"
  />
</template>

<script>
import { camelToKebab } from '@utils/string-manipulation.js';

export default {
  name: 'ShoDataElement',
  props: {
    /**
     * Data Element name.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Data Element data.
     */
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * Formats the data object with kebab-case keys.
     * @returns {Object}
     */
    formattedData () {
      return Object.entries(this.data).reduce((parsed, [key, value]) => {
        parsed[`data-${camelToKebab(key)}`] = value;
        return parsed;
      }, {});
    },
  },
};
</script>

<style scoped>
  .sho-data-element {
    height: 0;
    position: absolute;
    width: 0;
    z-index: -1;
  }
</style>
