<template>
  <div class="sho-data-layer">
    <!-- User Data -->
    <ShoDataElement name="user" :data="userBIObject" />

    <!-- App Data -->
    <ShoDataElement name="app" :data="appBIObject" />

    <!-- PPV Data -->
    <ShoDataElement name="ppv" :data="{ state: ppvState, productId: ppvProductId, }" />

    <!-- Vuex-based page tracking -->
    <ShoPageDataElement v-if="biPage" :bi-metadata="biPage" class="app-level" />

    <!-- Vuex-based misc data tracking -->
    <ShoDataElement 
      v-for="[key, value,] in biDataEntries"
      :key="`bi-data-element-${key}`"
      :class="`bi-data-element-${key}`"
      :name="key"
      :data="value"
    />

    <!-- STAT-36746 Alt: Explicit User data element -->
    <!--
    <div 
      class="sho-data-element"
      data-name="digitalData"
      data-data-element="user"
      :data-tve-user-id="user.tveUserId"
      :data-tve-device-id="tveDeviceId"
      :data-trial-status="userBI.trialStatus"
      :data-subscription-status="userBI.subscriptionStatus"
      :data-original-signup-platform="userBI.originalSignupPlatform"
      :data-current-billing-platform="userBI.currentBilling"
      :data-is-authorized="user.isAuthorized"
      :data-current-product-id="user.currentProductId"
    />
    -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ShoDataElement from '@components/analytics/ShoDataElement.vue';
import ShoPageDataElement from '@components/analytics/ShoPageDataElement.vue';

/* TODO: Possibly needs a STAT/OTT split for trialStatus, isAuthorized, etc. */

/**
 * Global app analytics data layer.
 */
export default {
  name: 'ShoDataLayer',
  components: {
    ShoDataElement,
    ShoPageDataElement,
  },
  computed: {
    ...mapState(['user']),
    ...mapState('now', ['tveDeviceId']),
    ...mapState('analytics', { biPage: 'page', biData: 'data' }),
    ...mapState('ppvState', { ppvState: 'state', ppvProductId: 'productId' }),
    /**
     * Shortcut for user bi node.
     * @returns {Object}
     */
    userBI () {
      return this.user && this.user.bi || {};
    },
    /**
     * Flattened user object for data element.
     * - Nested objects just become "data-bi=[Object object]" in the DOM
     * @returns {Object}
     */
    userBIObject () {
      const userBI = this.user.bi || {};
      return {
        tveUserId: this.user.tveUserId,
        tveDeviceId: this.tveDeviceId,
        trialStatus: userBI.trialStatus,
        subscriptionStatus: userBI.subscriptionStatus,
        originalSignupPlatform: userBI.originalSignupPlatform,
        currentBillingType: userBI.currentBillingType,
        isAuthorized: this.user.isAuthorized || null,
        currentProductId: this.user.currentProductId,
        accountType: this.user.accountType,
        mso: this.user.mso,
        msoId: this.user.msoId,
        msoUserId: this.user.msoUserId,
        registered: this.getMonthAndYear(this.user.createdOn),
      };
    },
    /**
     * App BI data
     * @returns {Object}
     */
    appBIObject () {
      return {
        appType: __APP_TYPE__,
        build: 'Manual Build',
      };
    },
    /**
     * Returns an array of misc Vuex bi data.
     * @returns {Object[]}
     */
    biDataEntries () {
      return Object.entries(this.biData);
    },
  },
  methods: {
    /**
     * Given a timestamp will return a String of the month and year. Ex. '01-2022'
     */
    getMonthAndYear (timestamp) {
      const date = new Date(timestamp);

      let month = (date.getUTCMonth() + 1).toString();
      if (month.length === 1) month = `0${month}`;

      const year = date.getUTCFullYear().toString();
      return `${month}-${year}`;
    },
  },
};
</script>

<style scoped>
  .sho-data-layer,
  .digital-data {
    height: 0;
    position: absolute;
    width: 0;
    z-index: -1;
  }
</style>
