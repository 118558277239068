const Category = () => import('@pages/CategoryPage.vue');
const CuratedListDetail = () => import('@pages/CuratedListDetailPage.vue');
const CuratedLists = () => import('@pages/CuratedListsPage.vue');
const SchedulePage = () => import('@pages/SchedulePage.vue');
const Search = () => import('@pages/SearchPage.vue');
const Series = () => import('@pages/SeriesPage.vue');
const SeriesDetail = () => import('@pages/SeriesDetailPage.vue');
const Movies = () => import('@pages/MoviesPage.vue');
const TitleDetail = () => import('@pages/TitleDetailPage.vue');

import { getDefaultAccess } from '@utils/route-default-access.js'; // NOTE: This will likely be split to STAT and OTT

/**
 * Content routes
 */
export default [
  /**
   * Content routes
   */
  {
    path: '/movies/:collectionId?',
    name: 'movies',
    component: Movies,
    props (route) {
      if (route.params.collectionId) {
        // Calling Number on a 'null' collection ID returns 0
        return { collectionId: Number(route.params.collectionId) };
      }
      // TODO: Would this be better as two named routes? 'allMovies' (with no prop) and 'movies'?
      return {};
    },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
      siteSection: 'movies',
    },
  },
  {
    path: '/movie/:id',
    name: 'movieDetail',
    component: TitleDetail,
    props (route) {
      return { id: Number(route.params.id) };
    },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },

  {
    path: '/fight/:id',
    name: 'fightDetail',
    component: TitleDetail,
    props (route) {
      return { id: Number(route.params.id) };
    },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },

  {
    path: '/series/all',
    alias: '/series',
    name: 'allSeries',
    component: Series,
    props: { flag: 'allSeries' },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
      siteSection: 'series',
    },
  },
  {
    path: '/series/current',
    name: 'currentSeries',
    component: Series,
    props: { flag: 'currentSeries' },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
      siteSection: 'series',
    },
  },
  {
    path: '/series/:id/:tab?',
    name: 'seriesDetail',
    component: SeriesDetail,
    props (route) {
      return { 
        id: Number(route.params.id), 
        ...(route.query.season && { seasonParam: Number(route.query.season) }),
      };
    },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },
  {
    path: '/episode/:id',
    name: 'episodeDetail',
    component: TitleDetail,
    props (route) {
      return { id: Number(route.params.id) };
    },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },
  {
    path: '/category/:id/:categorySlug/:subCategorySlug?',
    name: 'category',
    component: Category,
    props (route) {
      return { 
        id: Number(route.params.id),
        categorySlug: route.params.categorySlug,
        subCategorySlug: route.params.subCategorySlug,
      };
    },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
      preventScroll: true,
    },
  },
  {
    path: '/collection/allcollections',
    name: 'curatedLists',
    component: CuratedLists,
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },
  {
    path: '/collection/:id',
    name: 'curatedListDetail',
    component: CuratedListDetail,
    props (route) {
      return { id: Number(route.params.id) };
    },
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },
  {
    path: '/search/:searchTerm',
    name: 'search',
    component: Search,
    props: true,
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },
  {
    path: '/schedule/:channel?',
    name: 'liveTV',
    component: SchedulePage,
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },
];
