import api from '@api/api.js';

const url = 'user/title';

export const defaultValues = {
  bookmark: {
    inMyList: false,
    longTimeRemaining: null,
    mediumTimeRemaining: null,
    percentWatched: null,
    seconds: null,
    shortTimeRemaining: null,
    status: null,
  },
  isBookmarked: false,
  isInMyList: false,
};

// TODO: Flatten this
const state = { userTitle: { ...defaultValues } };

// TODO: Handle API Errors
const actions = {
  getUserTitleInfo ({ commit }, titleId) {
    api.get(`${url}/${titleId}`).then((response) => {
      commit('setUserTitle', { ...defaultValues, ...response });
    });
  },

  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  setUserTitle (state, response) {
    state.userTitle = {
      ...defaultValues,
      ...response,
    };
  },
  reset (state) {
    Object.assign(state, { userTitle: { ...defaultValues } });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

