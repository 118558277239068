import api from '@api/api.js';
import reasons from '@store/data/cancel-reasons.json';
import feedbackQuestions from '@store/data/cancel-feedback-questions.json';
import Logger from '@utils/logger.js';
const logger = new Logger('cancel-account');

export const cancelAccountUrl = 'user/plan';
export const cancelFeedbackUrl = 'questionnaire';

export const getDefaults = () => ({
  reasons,
  selectedReasonId: null,
  expirationDate: null,
  success: false,
  error: null,
  feedbackSubmitted: false,
  feedbackQuestions,
  feedbackResponses: {},
});

export const state = getDefaults();

/**
 * Parses user feedback into the proper API payload format.
 * @param {*} selectedReason 
 * @param {*} feedbackQuestions 
 * @param {*} feedbackResponses 
 */
export const parseFeedbackResponses = function (selectedReason, feedbackQuestions, feedbackResponses) {
  const payload = {
    category: selectedReason.name,
    questions: [],
  };

  for (const [questionId, response] of Object.entries(feedbackResponses)) {
    const responseObj = {
      id: null,
      prompt: null,
      responses: [],
    };
    const question = feedbackQuestions.find((question) => question.id === questionId);
    responseObj.id = questionId;
    responseObj.prompt = question && question.prompt;
    if (Array.isArray(response)) {
      response.map((resp) => ({ reply: resp })).forEach((r) => {
        responseObj.responses.push(r);
      });
    } else {
      responseObj.responses.push({ reply: response });
    }

    payload.questions.push(responseObj);
  }

  return payload;
};

export const getters = {
  /**
   * Returns the full selected reason object based on selectedReasonId.
   * @param {Object} state 
   */
  selectedReason (state) {
    if (!state.selectedReasonId) {
      return null;
    }

    return state.reasons.find((reason) => reason.id === state.selectedReasonId) || null;
  },
  /**
   * Returns a shuffled list of reasons based on reason config.
   * @param {Object} state 
   */
  shuffledReasonList (state) {
    const toShuffle = state.reasons.filter((reason) => reason.shuffle);
    const shuffled = [];
    const fixed = state.reasons.filter((reason) => !reason.shuffle);
    while (toShuffle.length) {
      const idx = Math.floor(Math.random() * toShuffle.length);
      shuffled.push(toShuffle.splice(idx, 1)[0]);
    }
    return [...shuffled, ...fixed];
  },
  // TODO: Rename this getter to something different than the state attribute
  feedbackQuestions (state) {
    if (!state.selectedReasonId) {
      return [];
    }

    const questions = state.feedbackQuestions.find((questions) => questions.reason === state.selectedReasonId);
    const defaultQuestions = state.feedbackQuestions.find((questions) => questions.default);

    return questions ? questions.questions : defaultQuestions.questions;
  },
};

export const actions = {
  setReason ({ commit }, reasonId) {
    commit('setReason', reasonId);
  },
  async cancelAccount ({ commit, getters }) {
    if (!getters.selectedReason) {
      return;
    }

    commit('resetError');
    try {
      const response = await api.del(cancelAccountUrl, { reasonForCancelling: [getters.selectedReason.name] });
      commit('setExpirationDate', response.expirationDate);
      commit('setSuccess', true);
    } catch (error) {
      commit('setError', error);
    }
  },
  resetError ({ commit }) {
    commit('resetError');
  },
  resetState ({ commit }) {
    commit('resetState');
  },
  setFeedbackResponse ({ commit }, payload) {
    commit('setFeedbackResponse', payload);
  },
  submitFeedback ({ commit, getters, state }) {
    const payload = parseFeedbackResponses(getters.selectedReason, getters.feedbackQuestions, state.feedbackResponses);
    api.post(cancelFeedbackUrl, payload)
      .catch((error) => {
        logger.warn('Error posting feedback.', error);
      });
    // We don't care if this endpoint passes or fails, always react as if successful
    commit('setFeedbackSubmitted', true);
  },
};

export const mutations = {
  setReason (state, reasonId) {
    state.selectedReasonId = reasonId;
  },
  setExpirationDate (state, expirationDate) {
    state.expirationDate = expirationDate;
  },
  setSuccess (state, success) {
    state.success = success;
  },
  setError (state, error) {
    state.error = error;
  },
  resetError (state) {
    state.error = null;
  },
  resetState (state) {
    Object.assign(state, getDefaults());
  },
  setFeedbackResponse (state, { questionId, value }) {
    state.feedbackResponses[questionId] = value;
  },
  setFeedbackSubmitted (state, submitted) {
    state.feedbackSubmitted = submitted;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
