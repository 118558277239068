import api from '@api/api.js';
import Logger from '@utils/logger.js';
import { ASSET_TYPES } from '@utils/constants.js';

const logger = new Logger('search');

const url = 'search';

export const defaults = {
  fetching: false,
  searchInput: '', // current value of the search input box
  searchTerm: '', // search term actually sent to the API
  suggestions: {},
  searchResults: {
    page: 0,
    pageCount: 1,
    results: null,
  },
  error: null,
};

const state = { ...defaults };

export const getters = {
  suggestionList: (state) => (state.suggestions.results ? state.suggestions.results : ['']),
  resultList: (state) => (state.searchResults.results ? parseResults(state.searchResults.results) : null),
};

const actions = {
  getQuickSearch ({ commit, state }) {
    if (state.searchInput.length >= 3) {
      api.get(`${url}/quick/${state.searchInput}`).then((response) => {
        commit('setSuggestions', response);
      });
    } else {
      commit('clearSuggestions');
    }
  },
  getFullSearch ({ commit, state }) {
    const newPage = state.searchResults.page + 1;

    // make sure there are more pages
    if (newPage <= state.searchResults.pageCount) {
      if (newPage === 1) {
        commit('setFetching');
      }
      api.get(`${url}/${state.searchTerm}/page/${newPage}`).then((response) => {
        commit('setSearchResults', { response, newPage });
        commit('setSearchInput', '');
        commit('clearSuggestions');
      })
        .catch((error) => {
          logger.error(error);
          commit('setError', error);
        });
    }
  },
};

export const mutations = {
  setSuggestions (state, response) {
    state.suggestions = response;
  },
  setSearchTerm (state, term) {
    if (state.searchTerm !== term) {
      state.searchTerm = term;
      // new search term, clear any results
      state.searchResults = defaults.searchResults;
    }
  },
  setSearchInput (state, term) {
    state.searchInput = term;
  },
  clearSuggestions (state) {
    state.suggestions = defaults.suggestions;
  },
  setSearchResults (state, data) {
    state.fetching = false;
    if (data.newPage === 1) {
      state.searchResults = data.response;
    } else {
      state.searchResults = { ...data.response, results: [...state.searchResults.results, ...data.response.results] };
    }
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
};

// transform results to match expected format for grid items
const parseResults = (results) => results.map((result) => {
  const data = result.metadata;
  return {
    ...data,
    imageUrl: getImageUrl(data),
    rating: data.titleRating ? ratings[data.titleRating] : null,
    description: data.description.medium,
  };
});

const getImageUrl = function (result) {
  if (!result.images || !result.images.length) {
    return null;
  }

  let searchKey = null;
  switch (result.type) {
    case ASSET_TYPES.series:
      searchKey = 'SEARCH_SERIES_DISPLAY';
      break;
    case ASSET_TYPES.movie:
      searchKey = 'SEARCH_MOVIE_LIST_DISPLAY';
      break;
    case ASSET_TYPES.episode:
      searchKey = 'SEARCH_EPISODE_LIST_DISPLAY';
      break;
    case ASSET_TYPES.fight:
      searchKey = 'SEARCH_FIGHT_LIST_DISPLAY';
      break;
    default: {
      logger.error(`Unknown result type '${result.type}' for titleId: ${result.id}`);
      break;
    }
  }

  const img = result.images.find((img) => img.type === searchKey);

  return img ? img.url : null;
};

const ratings = {
  TV_Y: 'TV-Y',
  TV_Y7: 'TV-Y7',
  TV_G: 'TV-G',
  TV_PG: 'TV-PG',
  TV_14: 'TV-14',
  TV_MA: 'TV-MA',
  TV_NR: 'UNRESTRICTED',
  MOVIE_G: 'G',
  MOVIE_PG: 'PG',
  MOVIE_PG_13: 'PG-13',
  MOVIE_R: 'R',
  MOVIE_NC_17: 'NC-17',
  MOVIE_NR: 'UNRESTRICTED',
  TV_ALL_LEVELS: 'ALL LEVELS BLOCKED',
  MOVIE_ALL_LEVELS: 'ALL LEVELS BLOCKED',
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};


