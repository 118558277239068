import api from '@api/api.js';

const URL = 'mixed/collections';

export const getDefaults = () => ({
  categoryMetadata: null,
  items: [],
  promotions: [],
  fetching: false,
  fetched: false,
});

export const state = getDefaults();

export const mutations = {
  setCategory (state, response) {
    state.promotions = response.promotions;
    state.categoryMetadata = response.categoryMetadata;
    state.items = response.items;
    state.fetching = false;
    state.fetched = true;
  },
  setFetching (state, fetching = true) {
    state.error = null;
    state.fetching = fetching;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  async getCategory ({ commit }, collectionId) {
    commit('setFetching');
    try {
      const response = await api.get(`${URL}/${collectionId}`);
      commit('setCategory', response);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   * Resets vuex state.
   * @param {Object} context 
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

