<template>
  <Transition name="tos-anim">
    <TOSNotification
      v-if="shouldDisplayTOS"
      :body="message"
      @close="onClose"
    />
  </Transition>
</template>

<script>
import { mapState } from 'vuex';
import TOSNotification from '@components/ui/TOSNotification.vue';

/**
 * Vuex wrapper for the simple app-level TOS Notification
 */
export default {
  name: 'AppTOSNotification',
  components: {
    TOSNotification,
  },
  data () {
    return {
      hasSeen: false,
    };
  },
  computed: {
    ...mapState('user', {
      message: (state) => state.optionalAttributes && state.optionalAttributes.HTML_INFO_BOX,
    }),
    /**
     * Indicates if a TOS message should be displayed; checks for the message, and if the user has closed it.
     * @returns {boolean}
     */
    shouldDisplayTOS () {
      return !!(this.message && !this.hasSeen);
    },
  },
  methods: {
    /**
     * Handles the notification close event, hides the notification.
     */
    onClose () {
      this.hasSeen = true;
    },
  },
};
</script>

<style scoped>
  .tos-anim-enter-active,
  .tos-anim-leave-active {
    transition: opacity 300ms;
  }

  .tos-anim-enter-from,
  .tos-anim-leave-to {
    opacity: 0;
  }
</style>
