const FrontDoorWrapper = () => import('@pages/FrontDoorWrapper.vue');
const PaywallLoader = () => import('@pages/paywall/PaywallLoader.vue');

import partnerAuthRoutes from '@routes/ott/paywall/partner-auth-routes.js';
import rendezvousRoutes from '@routes/ott/paywall/rendezvous-routes.js';

/**
 * Paywall routes.
 */
export default [
  ...partnerAuthRoutes,
  ...rendezvousRoutes,

  /**
   * Front door - paywall home page.
   */
  {
    path: '/',
    name: 'frontDoor',
    component: FrontDoorWrapper,
    props: true,
    meta: {
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },

  /**
   * Front door content routes.
   */
  {
    path: '/getstarted/:section?/:subsection?',
    name: 'getstarted',
    component: FrontDoorWrapper,
    props (route) {
      return { section: route.params.section, subSection: route.params.subsection };
    },
    meta: {
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },

  // TODO: Update 'continue' to accept a flow prop
  /**
   * Triggers next step in any given purchase flow.
   */
  {
    path: '/continue-purchase',
    name: 'continuePurchase',
    component: PaywallLoader,
    props: { page: 'next' },
    meta: {
      section: 'paywall',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },

  /**
   * Requests the login page from the Paywall Loader.
   */
  {
    path: '/login/:email?',
    alias: '/signin',
    name: 'login',
    component: PaywallLoader,
    props: (route) => ({ page: 'login', emailPrepop: route.params.email }),
    meta: {
      section: 'paywall',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: false,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },

  /**
   * Requests the create account page from the Paywall Loader.
   */
  {
    path: '/signup/:email?',
    name: 'signup',
    component: PaywallLoader,
    props: (route) => ({ page: 'signup', email: route.params.email }),
    meta: {
      section: 'paywall',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: false,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },

  /**
   * Requests the payment page from the Paywall Loader.
   */
  {
    path: '/payment',
    name: 'payment',
    component: PaywallLoader,
    props: { page: 'payment' },
    meta: {
      section: 'paywall',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },
];
