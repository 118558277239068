/**
 * Vuex module for managing registration data.
 */

import { STORAGE_KEYS } from '@utils/constants.js';
import ls from '@utils/local-storage.js';

const STORAGE_EXPIRATION_DAYS = 1;

/**
 * @typedef {Object} RegistrationDefaults
 * @property {string} coupon - Coupon code to be applied at purchase
 * @property {string} flowId - Registration flow ID
 * @property {string} partnerId - Flow partner ID, if applicable
 */

/**
 * Creates a new defaults object for setting state.
 * @returns {RegistrationDefaults}
 */
const getDefaults = () => ({
  coupon: null,
  flowId: null,
  partnerId: null,
  attributionId: null,
});

export const state = getDefaults();

export const mutations = {
  initializeState (state) {
    const regSession = ls.getItem(STORAGE_KEYS.registration);
    if (regSession) {
      const { flowId, partnerId, coupon, attributionId } = regSession;
      state.coupon = coupon || null;
      state.flowId = flowId || null;
      state.partnerId = partnerId || null;
      state.attributionId = attributionId || null;
    }
  },
  setFlow (state, { flowId, partnerId }) {
    state.flowId = flowId;
    state.partnerId = partnerId;
    ls.setItem(STORAGE_KEYS.registration, state, STORAGE_EXPIRATION_DAYS);
  },
  resetFlow (state) {
    state.flowId = null;
    state.partnerId = null;
    ls.setItem(STORAGE_KEYS.registration, state, STORAGE_EXPIRATION_DAYS);
  },
  setCoupon (state, coupon) {
    state.coupon = coupon;
    ls.setItem(STORAGE_KEYS.registration, state, STORAGE_EXPIRATION_DAYS);
  },
  resetCoupon (state) {
    state.coupon = null;
    ls.setItem(STORAGE_KEYS.registration, state, STORAGE_EXPIRATION_DAYS);
  },
  setAttribution (state, attributionId) {
    state.attributionId = attributionId;
    ls.setItem(STORAGE_KEYS.registration, state, STORAGE_EXPIRATION_DAYS);
  },
  reset (state) {
    Object.assign(state, getDefaults());
    ls.removeItem(STORAGE_KEYS.registration);
  },
};

export const actions = {
  /**
   * Sets registration flow to reg session.
   * @param {Object} context - Vuex module context.
   * @param {Object} payload
   * @param {string} payload.flowId - Reg flow id
   * @param {string} [payload.partnerId] - Reg flow partner id
   */
  setFlow ({ commit }, { flowId, partnerId = null }) {
    commit('setFlow', { flowId, partnerId });
  },
  /**
   * Resets reg flow session data.
   * @param {Object} context - Vuex module context.
   */
  resetFlow ({ commit }) {
    commit('resetFlow');
  },
  /**
   * Sets a coupon code to the reg session.
   * @param {Object} context - Vuex module context
   * @param {string} coupon - Coupon code
   */
  setCoupon ({ commit }, coupon) {
    commit('setCoupon', coupon);
  },
  /**
   * Resets coupon session data.
   * @param {Object} context - Vuex module context.
   */
  resetCoupon ({ commit }) {
    commit('resetCoupon');
  },
  /**
   * Stores id for purchase attribution.
   * @param {Object} context - Vuex module context.
   * @param {string} attributionId - Code to send to purchase endpoint.
   */
  setAttribution ({ commit }, attributionId) {
    commit('setAttribution', attributionId);
  },
  /**
   * Clears registration session.
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
