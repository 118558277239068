const ForgotPasswordPage = () => import('@pages/ForgotPasswordPage.vue');
const ResetPasswordPage = () => import('@pages/ResetPasswordPage.vue');

/**
 * Forgot password routes
 */
export default [
  /**
   * Forgot password routes
   */
  {
    path: '/password',
    name: 'forgotPassword',
    component: ForgotPasswordPage,
  },
  {
    path: '/create-password',
    component: ForgotPasswordPage,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path: '/reset-password/:authToken',
    name: 'resetPassword',
    props: (route) => ({ authToken: route.params.authToken }),
    component: ResetPasswordPage,
  },
];
