/**
 * Manage Datahole API calls and meta data
 * Currently only used for ModularHomePage
 */
const URL = 'client-interaction';

import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('DataholeMixin');

export default {
  methods: {
    /**
     * Send page view event
     * @param {Object} meta
     */
    dhPageView (meta) {
      const payload = {
        action: 'page-view',
        actionType: 'default',
        meta,
      };
      this.dhMakeRequest(payload);
    },
      
    /**
     * Send other generic events
     * @param {Object} meta 
     */
    dhEvent (meta) {
      const payload = {
        action: 'click',
        actionType: meta.eventType || 'default',
        meta,
      };
      this.dhMakeRequest(payload);
    },
    
    /**
     * Send request, merge page meta into any request meta
     * @param {Object} data 
     */
    dhMakeRequest (data) {
      const payload = {
        ...data,
        eventHappenedAtMillis: Date.now(),
        meta: {
          pageName: this?.$route?.name || 'home',
          ...data.meta,
        },
      };
      try {
        logger.log('making request', payload);
        api.post(URL, payload, true);
      } catch (error) {
        logger.error(error);
      }
    },
  },
};
