import api from '@api/api.js';
import Logger from '@utils/logger.js';

const logger = new Logger('series');

const allSeriesUrl = 'series';

export const defaults = {
  series: [],
  bi: {},
  fetching: false,
  fetched: false,
  error: null,
};

const state = { ...defaults };

export const getters = {
  filteredSeriesList: (state) => (filter) => {
    const parsedSeries = parseSeries(state.series);
    if (filter !== 'allSeries') {
      return parsedSeries.filter((t) => t.flags && t.flags.indexOf('CURRENT') > -1);
    }
    return parsedSeries;
  },
};

const actions = {
  async getAllSeries ({ commit }) {
    if (!state.series.length) {
      commit('setFetching', true);
      await api.get(`${allSeriesUrl}`).then((response) => {
        commit('setSeries', response);
      })
        .catch((error) => {
          logger.error(logger);
          commit('setError', error);
        });
    }
  },
};

export const mutations = {
  setSeries (state, response) {
    state.series = response.allSeries;
    state.bi = response.bi;
    state.fetched = true;
    state.fetching = false;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
};

const parseSeries = (series) => series.map((s) => {
  const data = s;
  return {
    ...data,
    description: data.description.medium,
  };
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
