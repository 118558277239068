const NotFoundPage = () => import('@pages/NotFoundPage.vue');
const ContactForm = () => import('@pages/ContactFormPage.vue');
const ComingSoon = () => import('@pages/ComingSoonPage.vue');
const PlayerPage = () => import('@pages/PlayerPage.vue');
const FormControlsPage = () => import('@pages/FormControlsPage.vue');

import { getDefaultAccess } from '@utils/route-default-access.js';

/**
 * Route access configs.
 * @typedef {Object} ShoRouteAccessConfig
 * @property {boolean} authorized - Indicates if a route is accessible to authorized users.
 * @property {boolean} authenticated - Indicates if a route is accessible to authenticated users.
 * @property {boolean} unauthenticated - Indicates if a route is accessible to unauthenticated users.
 * @property {boolean} mobile - Indicates if a route is accessible on mobile devices.
 */

/**
 * Route access object - access configs for OTT and STAT.
 * @typedef {Object} ShoRouteAccess
 * @property {ShoRouteAccessConfig} ott - Default access object for OTT
 * @property {ShoRouteAccessConfig} stat - Default access object for STAT
 */

/**
 * Route Meta object.
 * @typedef {Object} ShoRouteMeta
 * @property {ShoRouteAccess} access - Access configs for OTT & STAT
 * @property {Object} [authnFailureRoute] - Router object for authentication-required failure redirect
 */

export default [
  {
    path: '/help-center/contact',
    name: 'contact',
    component: ContactForm,
  },
  
  {
    path: '/forms',
    name: 'formControls',
    component: FormControlsPage,
  },

  /**
   * Coming Soon page.
   */
  {
    path: '/comingsoon',
    name: 'coming_soon', // not 'camelCase' because it's from the menu API
    component: ComingSoon,
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },

  /**
   * Mobile-app friendly coming soon page with hidden UI.
   */
  {
    path: '/comingsoon/mobile',
    alias: '/page/comingsoon',
    name: 'comingSoonMobile',
    component: ComingSoon,
    props: { mobile: true },
    meta: {
      access: {
        ott: {
          ...getDefaultAccess('ott'),
          mobile: true,
        },
        stat: {
          ...getDefaultAccess('stat'),
          mobile: true,
        },
      },
    },
  },

  {
    path: '/play/:titleId',
    name: 'play',
    component: PlayerPage,
    props: (route) => ({ titleId: Number(route.params.titleId) }),
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },

  {
    path: '/play/:channel',
    name: 'playLive',
    component: PlayerPage,
    props: (route) => ({ titleId: route.params.channel }),
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },

  {
    path: '/404',
    name: 'notFound',
    component: NotFoundPage,
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'notFound' },
  },
];
