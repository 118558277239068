import api from '@api/api.js';

const url = 'user/previewplans';

/**
 * Plan metadata.
 * @typedef {Object} PlanMetaData
 * @property {string} billingInterval - Plan billing interval, "month" or "year".
 * @property {number} chargedTodayInCents - Amount a user will be charged for this plan.
 * @property {number} creditAmountApplied - Amount of new or existing credit applied towards purchase of this plan.
 * @property {number} creditAmountRemaining - Amount of account credit remaining if this plan is purchased.
 * @property {boolean} defaultPlan - Indicates this plan should be the default UI selection.
 * @property {boolean} deferredAction - Indicates if selecting this plan will immediately take effect.
 * @property {number} discountInCents
 * @property {number} intervalLength - The length of a billing cycle in number of intervalUnits.
 * @property {number} intervalUnits - Indicates length of billing cycle along with intervalLength.
 * @property {string} name - Plan display name.
 * @property {number} nextBillingDate
 * @property {number} nextBillingDateInMillis - Indicates the next date a user would be billed if selecting this plan.
 * @property {number} priceInCents - Total price for this plan.
 * @property {string} productCode - Recurly product code for this plan.
 * @property {number} trialEndDate - Trial end date, if the plan includes a trial.
 * @property {number} trialIntervalLength - Length of trial, if available, in trialIntervalUnit's.
 * @property {string} trialIntervalUnit - Indicates length of trial, if available, along with trialIntervalLength.
 */

/**
 * Defaults for the plan preview store.
 * @typedef {Object} PlanPreviewDefaults
 * @property {boolean} fetching - Indicates if an API interaction is in progress.
 * @property {boolean} fetched - Indicates if at least one successful API call has been made.
 * @property {Object} error - Contains any API error response.
 * @property {string} selectedProductCode - The product code for the user's active plan selection.
 * @property {PlanMetaData[]} plans - Contains metadata about available plans.
 * @property {number} existingAccountCreditInCents - A user's existing account credit.
 * @property {number} totalNewAccountCreditInCents - Account credit being applied in new purchase.
 * @property {boolean} trialEligible - The current user's trial eligibility.
 * @property {Object} coupon - Metadata or error response from validating a coupon code.
 * @property {Object} giftCard - Metadata or error response from validating a gift card code.
 */

/**
 * Structure for preview request plan array. 
 * @typedef {Object} PreviewRequestPlan
 * @property {string} productCode - Product code.
 * @property {string[]} [addOn] - Optional array of add-on IDs.
 * @property {string} [couponCode] - Optional product-specific coupon code.
 */

/**
 * Structure for preview request payload.
 * @typedef {Object} PreviewRequestPayload
 * @property {PreviewRequestPlan[]} plans - Array of plan metadata objects.
 * @property {string} [giftCardCode] - Optional gift card code.
 * @property {string} [couponCode] - Optional top-level coupon code (not plan-specific)
 */

/**
 * Returns an immutable set of default data to be used in state.
 * @returns {PlanPreviewDefaults} - Default state data.
 */
export const getDefaults = () => ({
  fetching: false,
  fetched: false,
  error: null,
  selectedProductCode: null,
  plans: null,
  existingAccountCreditInCents: 0,
  totalNewAccountCreditInCents: 0,
  trialEligible: false,
  coupon: null,
  giftCard: null,
});

export const state = getDefaults();

export const getters = {
  /**
   * Returns the plan meta data for the user's active selection.
   * @param {Object} state
   * @param {Object[]} state.plans - Available plans in state.
   * @param {string} state.selectedProductCode - Product code for plan selected by user.
   * @returns {(Object|null)} - Selected plan metadata, or null if not applicable.
   */
  selectedPlan (state) {
    if (!state.plans || !state.plans.length || !state.selectedProductCode) {
      return null;
    }

    return state.plans.find((plan) => plan.productCode === state.selectedProductCode);
  },
};

export const mutations = {
  setPlans (state, response) {
    state.plans = response.plans || [];
    state.existingAccountCreditInCents = response.existingAccountCreditInCents || 0;
    state.totalNewAccountCreditInCents = response.totalNewAccountCreditInCents || 0;
    state.trialEligible = !!response.trialEligible;
    state.coupon = response.coupon || null;
    state.giftCard = response.giftCard || null;
  },
  setSelectedProductCode (state, productCode) {
    state.selectedProductCode = productCode;
  },
  setFetching (state, fetching) {
    state.fetching = fetching;
    state.error = null;
  },
  setFetched (state, fetched) {
    state.fetching = false;
    state.fetched = fetched;
  },
  setError (state, error) {
    state.error = error;
  },
  removeCoupon (state) {
    state.coupon = null;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Gets a list of available plans and purchase metadata.
   * @param {Object} context - Vuex store context.
   * @param {Object} options - API options
   * @param {string} options.productCodes - Array of (paywall-suggested) product IDs.
   * @param {string} [options.couponCode] - Optional coupon code.
   * @param {string} [options.giftCardCode] - Optional gift card code.
   */
  async getPlanPreview ({ commit }, { plans, couponCode = null, giftCardCode = null, zipcode = null }) {
    if (!plans) {
      throw 'Product codes are required.';
    }

    const payload = {
      plans,
    };

    if (couponCode) {
      commit('removeCoupon');
      payload.couponCode = couponCode;
    }

    if (giftCardCode) {
      payload.giftCardCode = giftCardCode;
    }

    if (zipcode) {
      payload.postalCode = zipcode;
    }

    commit('setFetching', true);

    try {
      const response = await api.post(url, payload);
      commit('setPlans', response);
    } catch (error) {
      commit('setError', error);
    }

    commit('setFetched', true);
  },

  /**
   * Enhanced purchase flow plan preview request.
   * Payload format is updated here and will need to be used
   * for the legacy purchase flow as well, but that can't happen
   * until the P+ integration branch gets merged.
   * @param {Object} context - Vuex store context.
   * @param {PreviewRequestPayload} options - API options
   * @param {PreviewRequestPlan[]} options.plans - Array of plan metadata objects.
   * @param {string} [options.couponCode] - Optional coupon code.
   * @param {string} [options.giftCardCode] - Optional gift card code.
   * @returns {Promise}
   */
  async getEnhancedPlanPreview ({ commit }, { plans, couponCode = null, giftCardCode = null, zipcode = null }) {
    if (!plans) {
      throw 'Product codes are required.';
    }

    const payload = {
      plans,
    };

    if (couponCode) {
      commit('removeCoupon');
      payload.couponCode = couponCode;
    }

    if (giftCardCode) {
      payload.giftCardCode = giftCardCode;
    }

    if (zipcode) {
      payload.postalCode = zipcode;
    }

    return api.post(url, payload);
  },

  /**
   * Updates a user's plan selection.
   * @param {Object} context - Vuex store context.
   * @param {string} productCode - Product Code for selected plan
   */
  setSelectedProductCode ({ commit }, productCode) {
    commit('setSelectedProductCode', productCode);
  },

  /**
   * Removes coupon data from state.
   * @param {Object} context - Vuex store context.
   */
  removeCoupon ({ commit }) {
    commit('removeCoupon');
  },

  /**
   * Resets state to defaults.
   * @param {Object} context - Vuex store context.
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
