/**
 * PPV schedule store module. 
 */

import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('ppv-schedule');


export const url = 'ppv/schedule';

const MAX_EXPIRES_IN = 3600000;

const ERROR_MAX_RETRIES = 3;
const ERROR_RETRY_INTERVAL = 5000;

let fetchAttempts = 0;
let fetchTimer;

/**
 * Defaults for the PPV schedule store.
 * @typedef {Object} PPVScheduleDefaults
 * @property {string} headline - Schedule headline
 * @property {string} description - Schedule description
 * @property {Number} expiresIn - Number of ms before next fetch
 * @property {PPVScheduleEvent[]} events - Array of PPV Schedule events
 * @property {boolean} fetching - Indicates if an API interaction is in progress.
 * @property {boolean} fetched - Indicates if at least one successful API call has been made.
 * @property {Object} error - Contains any API error response.
 */

/**
 * @typedef {Object} PPVScheduleEvent
 * @property {string} headline - Event headline
 * @property {Number} start - Event start time (UTC)
 * @property {string} imageUrl - Event image URL
 * @property {boolean} live - Indicates if event is currently live
 * @property {boolean} ageGate - Indicates if event requires age gating
 * @property {boolean} collectEmail - Indicates if event requires user email
 */

/**
 * Returns an immutable set of default data to be used in state.
 * @returns {PPVScheduleDefaults} - Default schedule data.
 */
export const getDefaults = () => (
  {
    headline: '',
    description: '',
    expiresIn: 0,
    events: [],
    fetching: false,
    fetched: false,
    error: null,
  }
);

export const state = getDefaults();

export const mutations = {
  setFetching (state, fetching) {
    state.error = null;
    state.fetching = fetching;
  },
  setResponse (state, response) {
    logger.log('[PPV Schedule] schedule fetched successfully.');
    // Reset state to defaults and then set to API response
    Object.assign(state, getDefaults(), response);
    state.fetching = false;
    state.fetched = true;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Gets PPV schedule.
   * @param {Object} context - Vuex context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   */
  async getSchedule ({ commit, state, dispatch }, options = {}) {
    // Make an API call if user has requested fresh data,
    // or if data has never been fetched, and a fetch is not in progress
    if ((!state.fetching && !state.fetched) || options.cacheBust) {
      try {
        // clear any scheduled refetches
        if (fetchTimer) {
          clearTimeout(fetchTimer);
        }

        commit('setFetching', true);
        const response = await api.get(url);
        commit('setResponse', response);

        // reset error retry counter
        fetchAttempts = 0;

        // Schedule next refetch
        if (response.expiresIn) {
          const nextFetchTime = Math.min(response.expiresIn, MAX_EXPIRES_IN);
          fetchTimer = setTimeout(() => dispatch('getSchedule', { cacheBust: true }), nextFetchTime);
          logger.log(`[PPV Schedule] new fetch in ${Math.ceil(nextFetchTime / 1000)}s`);
        }
      } catch (error) {
        commit('setError', error);

        // increment error retry counter
        fetchAttempts++;
        if (fetchAttempts < ERROR_MAX_RETRIES) {
          logger.error(`[PPV Schedule] error fetching schedule, retying in ${Math.ceil(ERROR_RETRY_INTERVAL / 1000)}s`);
          fetchTimer = setTimeout(() => dispatch('getSchedule', { cacheBust: true }), ERROR_RETRY_INTERVAL);
        } else {
          logger.error('[PPV Schedule] max error retries exceeded');
        }
      }
    }
  },
  /**
   * Resets state to defaults and clear refetch timer.
   * @param {Object} context - Vuex context
   */
  reset ({ commit }) {
    clearTimeout(fetchTimer);
    commit('reset');
  },

  
};

/**
 * Finds and returns live event in state.events.
 * @returns {object} - live event
 */
export const getters = {
  liveEvent: (state) => (state.events.length ? state.events.find((event) => event.live) : null),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
