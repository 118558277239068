/**
 * Utility constants for avoiding direct string comparisons with common API values.
 */

/**
 * Recurly payment methods.
 * @const
 * @type {Object}
 * @property {string} card - Credit card payment method.
 * @property {stirng} paypal - PayPal payment method.
 * @default
 */
export const RECURLY_PAYMENT_METHODS = {
  card: 'Card',
  paypal: 'PayPal',
};

/**
 * Recurly plan billing intervals.
 * @const
 * @type {Object}
 * @property {string} year - Annual plan billing interval.
 * @property {string} month - Monthly plan billing interval.
 */
export const PLAN_BILLING_INTERVALS = {
  year: 'year',
  month: 'month',
};

/**
 * Coupon discount types.
 * @const
 * @type {Object}
 * @property {string} dollars - Price discount in dollars.
 * @property {string} percent - Price discount by percentage.
 * @property {string} trial - Free trial extension.
 */
export const COUPON_DISCOUNT_TYPES = {
  dollars: 'dollars',
  percent: 'percent',
  trial: 'free_trial',
};

/**
 * Asset types.
 * @const
 * @property {string} movie - Movies
 * @property {string} series - Series
 * @property {string} fight - Fight
 */
export const ASSET_TYPES = {
  movie: 'Movie',
  episode: 'Episode',
  series: 'SERIES',
  fight: 'Fight',
  collection: 'COLLECTION',
};

/**
 * User account trial statuses.
 * @const
 * @type {Object}
 * @property {string} eligible - Trial eligible
 * @property {string} active - Trial active
 * @property {string} expired - Trial expired
 * @property {string} none
 */
export const TRIAL_STATUSES = {
  eligible: 'ELIGIBLE',
  active: 'ACTIVE',
  expired: 'EXPIRED',
  none: 'NONE',
};

/**
 * Subscription status types
 * @const
 * @type {Object}
 * @property {string} active - Active status.
 * @property {string} inactive - Inactive status.
 * @property {string} none - No status.
 * @property {string} canceling - Canceling status.
 * @property {string} expired - Expiried status.
 * @property {string} pending - Pending status.
 */
export const SUBSCRIPTION_STATUSES = {
  active: 'Active',
  inactive: 'Inactive',
  none: 'None',
  canceling: 'Canceling',
  expired: 'Expired',
  pending: 'Pending',
};

/**
 * Add On status types
 * @const
 * @type {Object}
 * @property {string} active - Active status.
 * @property {string} inactive - Inactive status.
 * @property {string} none - No status.
 * @property {string} canceling - Canceling status.
 * @property {string} expired - Expiried status.
 * @property {string} pending - Pending status.
 */
export const ADDON_STATUSES = {
  active: 'active',
  inactive: 'inactive',
  canceling: 'canceling',
  pending: 'pending',
};

/**
 * Account types
 * @const
 * @type {Object}
 * @property {string} primary - Primary Account
 * @property {string} sub - Sub Account
 */
export const ACCOUNT_TYPES = {
  primary: 'PRIMARY',
  sub: 'SUB',
};

/**
 * MSO Types
 * @const
 * @type {Object}
 * @property {string} primarySub - account that can have subs
 */
export const MSO_TYPES = {
  primarySub: 'PRIMARY_SUB',
  primaryOnly: 'PRIMARY_ONLY',
};

/**
 * PPV States
 * @const
 * @type {Object}
 * @property {string} pre - pre
 * @property {string} dayof - dayof
 * @property {string} prepress - prepress
 * @property {string} live - live
 * @property {string} post - post
 * @property {string} postpress - postpress
 * @property {string} cooldown - cooldown
 * @property {string} sunset - sunset
 */
export const PPV_STATES = {
  pre: 'pre',
  dayof: 'dayof',
  prepress: 'prepress',
  live: 'live',
  post: 'post',
  postpress: 'postpress',
  cooldown: 'cooldown',
  sunset: 'sunset',
};

/**
 * PPV Button Actions
 * @const
 * @type {Object}
 * @property {string} eventInfo - event-info
 * @property {string} fightCard - fight-card
 * @property {string} eventPurchase - event-purchase
 * @property {string} watch - watch
 * @property {string} orderDetails - order-details
 * @property {string} waysToWatch - ways-to-watch
 */
export const PPV_BUTTON_ACTIONS = {
  eventInfo: 'event-info',
  fightCard: 'event-card',
  eventPurchase: 'event-purchase',
  watch: 'watch',
  orderDetails: 'order-details',
  waysToWatch: 'ways-to-watch',
};

/**
 * Title Image Types
 * @const
 * @type {Object}
 * @property {string} titleFeedDetailsEpisode - 'TITLE_FEED_DETAILS_EPISODE'
 * @property {string} titleFeedDetailsEpisodeSmall - 'TITLE_FEED_DETAILS_EPISODE_SMALL'
 */
export const TITLE_IMAGE_TYPES = {
  titleFeedDetailsEpisode: 'TITLE_FEED_DETAILS_EPISODE',
  titleFeedDetailsEpisodeSmall: 'TITLE_FEED_DETAILS_EPISODE_SMALL',
};

/**
 * Title Detail Image Types
 * @const
 * @type {Object}
 * @property {string} titleFeedDetailsEpisode - 'TITLE_FEED_DETAILS_EPISODE'
 * @property {string} titleFeedDetailsMovie - 'TITLE_FEED_DETAILS_MOVIE'
 * @property {string} titleFeedDetailsFight - 'TITLE_FEED_DETAILS_FIGHT'
 * @property {string} titleAutoplayDisplay - 'TITLE_AUTOPLAY_DISPLAY'
 * @property {string} titleAboutImage - 'TITLE_ABOUT_IMAGE'
 */
export const TITLE_DETAIL_IMAGE_TYPES = {
  titleFeedDetailsEpisode: 'TITLE_FEED_DETAILS_EPISODE',
  titleFeedDetailsMovie: 'TITLE_FEED_DETAILS_MOVIE',
  titleFeedDetailsMovieLandscape: 'TITLE_FEED_DETAILS_MOVIE_LANDSCAPE',
  titleFeedDetailsFight: 'TITLE_FEED_DETAILS_FIGHT',
  titleAutoplayDisplay: 'TITLE_AUTOPLAY_DISPLAY',
  titleAboutImage: 'TITLE_ABOUT_IMAGE',
};

/** PPV Fighter Image Types
 * @const
 * @type {Object}
 * @property {string} eventInfo - event-info
 * @property {string} fightCard - fight-card
 * @property {string} eventPurchase - event-purchase
 * @property {string} watch - watch
 * @property {string} orderDetails - order-details
 * @property {string} waysToWatch - ways-to-watch
 */
export const PPV_FIGHTER_IMAGE_TYPES = {
  badge: 'FIGHTER_BADGE',
  full2x3: 'FIGHTER_FULL_2x3',
  full1x1: 'FIGHTER_FULL_1x1',
};

export const PROMO_SLIDE_TYPES = {
  collection: 'COLLECTION',
  deepLink: 'DEEP_LINK',
  title: 'TITLE',
  series: 'SERIES',
  ppv: 'PPV',
};

export const PROMO_IMAGE_TYPES = {
  homeFeedDisplay: 'HOME_FEED_DISPLAY',
  promotedMovie: 'PROMOTED_MOVIE',
  promotedCollection: 'PROMOTED_COLLECTION',
  collectionFeed: 'COLLECTION_FEED_BACKGROUND',
  promotedSeries: 'PROMOTED_SERIES',
  promotedTitle: 'PROMOTED_TITLE',
};

export const MY_LIST_IMAGE_TYPES = {
  playlistListDisplay: 'PLAYLIST_LIST_DISPLAY',
  playlistSeriesListDisplay: 'PLAYLIST_SERIES_LIST_DISPLAY',
};

/**
 * Series detail season images
 * @const
 * @type {Object}
 * @property {string} hero - Season hero image
 * @property {string} logo - Season logo
 */
export const SERIES_DETAIL_IMAGE_TYPES = {
  hero: 'SERIES_FEED_SEASON_DISPLAY',
  logo: 'SEASON_LOGO',
  episode: 'TITLE_FEED_DETAILS_EPISODE_LARGE',
};

/**
 * New Series detail season images
 * @const
 * @type {Object}
 * @property {string} mobileHero - Mobile hero image from resumeWatching
 * @property {string} desktopHero - Desktop hero image from resumeWatching
 * @property {string} logo - Series logo
 * @property {string} mobileAbout - Mobile hero image from seriesDetail
 * @property {string} desktopAbout - Desktop hero image from seriesDetail
 */
export const NEW_SERIES_DETAIL_IMAGE_TYPES = {
  mobileHero: 'SERIES_HERO_MOBILE',
  desktopHero: 'SERIES_HERO_DESKTOP',
  logo: 'SERIES_LOGO_LARGE',
  mobileAbout: 'SERIES_ABOUT_IMAGE_SMALL',
  desktopAbout: 'SERIES_ABOUT_IMAGE',
};

/**
 * User billing types.
 * @const
 * @type {Object}
 * @property {string} bacon - Recurly billing
 * @property {string} porku - Roku billing
 * @property {string} scrapple - iTunes billing
 * @property {string} spamazon - Amazon billing
 * @property {string} hamdroid - Android billing
 * @property {string} snoutify - Spotify billing
 * @property {string} corndog - CBS/Paramount billing
 * @property {string} pjork - Complimentary Employee Access
 * @property {string} salamisclub - Sam's Club
 */
export const BILLING_TYPES = {
  bacon: 'BACON',
  porku: 'PORKU',
  scrapple: 'SCRAPPLE',
  spamazon: 'SPAMAZON',
  hamdroid: 'HAMDROID',
  cablevision: 'CABLEVISION',
  snoutify: 'SNOUTIFY',
  corndog: 'CORNDOG',
  pjork: 'PJORK',
  salamisclub: 'SALAMISCLUB',
  pigBrother: 'PIG_BROTHER',
};

/**
 * Title Detail Flags
 */
export const TITLE_FLAGS = {
  active: 'ACTIVE',
  current: 'CURRENT',
  free: 'FREE',
  new: 'NEW',
  featured: 'FEATURED',
  lastChance: 'LAST_CHANCE',
  HD: 'HD',
  audioDescription: 'ENGLISH_AUDIO_DESC',
};

/**
 * Local Storage Keys
 */
export const STORAGE_KEYS = {
  intendedRoute: 'sho:intendedRoute',
  impact: 'sho:aff',
  giftcard: 'sho:giftcard',
  firstTimeUser: 'sho:firstTimeUser',
  registration: 'sho:registration',
  activationCode: 'sho:activation-code',
  referrer: 'sho:referrer',
  automation: 'sho:automation',
};

/**
 * Enhanced purchase flow purchase types.
 */
export const PURCHASE_TYPES = {
  bundle: 'bundle',
  standalone: 'standalone',
};

export const PAYWALL_IMAGE_TYPES = {
  hero: 'PAYWALL_HERO',
  heroMobile: 'PAYWALL_HERO_MOBILE_WEB',
};

/**
 * App Types
 * @const
 * @type {Object}
 * @property {string} ott - app type ott
 * @property {string} stat - app type stat
 */
export const APP_TYPES = {
  ott: 'ott',
  stat: 'stat',
};

/**
 * Google recaptcha action types
 */
export const RECAPTCHA_ACTIONS = {
  purchase: 'purchase',
  ppvPurchase: 'purchase_ppv',
  giftcardValidation: 'gift_validation',
  giftcardRedemption: 'gift_redemption',
};

/**
 * Title watched status types
 * @const
 * @type {Object}
 * @property {string} watched
 * @property {string} notWatched
 * @property {string} inProgress
 */
export const TITLE_WATCHED_STATUSES = {
  watched: 'WATCHED',
  notWatched: 'NOT_WATCHED',
  inProgress: 'IN_PROGRESS',
};

/**
 * Promo module action types for clicks on image/text
 * @const
 * @type {Object}
 * @property {string} seriesDetail
 * @property {string} movieDetail
 * @property {string} episodeDetail
 * @property {string} deeplink
 */
export const PROMO_MODULE_CLICK_ACTION_TYPES = {
  play: 'PLAY',
  seriesDetail: 'SERIES_DETAIL',
  movieDetail: 'MOVIE_DETAIL',
  episodeDetail: 'EPISODE_DETAIL',
  titleDetail: 'TITLE_DETAIL',
  deeplink: 'DEEPLINK',
  myListSeries: 'MY_LIST_SERIES',
  myListTitle: 'MY_LIST_TITLE',
  myList: 'MY_LIST', // Only used in admin tool previews
};

/**
 * Home module types
 * @const
 * @type {Object}
 * @property {string} resumeWatching
 * @property {string} series
 * @property {string} movies
 * @property {string} custom
 * @property {string} branded
 * @property {string} trailer
 */
export const HOME_MODULE_TYPES = {
  resumeWatching: 'RESUME_WATCHING',
  freeFullEpisodes: 'FREE_FULL_EPISODES',
  series: 'SERIES',
  movies: 'MOVIES',
  custom: 'CUSTOM',
  branded: 'BRANDED',
  trailer: 'TRAILER',
};

/**
 * Shortform content types.
 * @const
 * @type {Object}
 * @property {string} primaryTrailer
 */
export const SHORTFORM_CONTENT_TYPES = {
  primaryTrailer: 'PRIMARY_TRAILER',
};
