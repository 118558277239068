/* eslint-disable camelcase */
export default [
  {
    path: '/samsungaccess',
    redirect: () => ({
      name: 'frontDoor',
      query: { offer: 'samsungaccess', s_cid: 'url-default-18931' },
    }),
  },
  {
    path: '/samsung-access',
    redirect: () => ({
      name: 'frontDoor',
      query: { offer: 'samsungaccess', s_cid: 'url-default-18931' },
    }),
  },
  {
    path: '/samsungyourchoice',
    redirect: () => ({
      name: 'frontDoor',
      query: { offer: 'samsungyourchoice', s_cid: 'url-default-24836' },
    }),
  },
  {
    path: '/samsungtvoffer',
    redirect: () => ({
      name: 'frontDoor',
      query: { offer: 'samsungtvoffer', s_cid: 'url-default-19593' },
    }),
  },
  {
    path: '/friends',
    redirect: () => ({
      name: 'frontDoor',
      query: { s_cid: 'url-default-12026' },
    }),
  },
  {
    path: '/try30',
    redirect: () => ({
      name: 'frontDoor',
      query: { s_cid: 'url-default-12250' },
    }),
  },
  {
    path: '/jetblue',
    redirect: () => ({
      name: 'frontDoor',
      query: { s_cid: 'url-jetblue-18019' },
    }),
  },
  {
    path: '/military',
    redirect: () => ({
      name: 'frontDoor',
      query: { s_cid: 'url-military-18109' },
    }),
  },
  {
    path: '/bestbuy',
    redirect: () => ({
      name: 'frontDoor',
      query: {
        offer: 'bestbuy2021', s_cid: 'aff-default-23517',
      },
    }),
  },
  {
    path: '/american',
    redirect: () => ({
      name: 'frontDoor',
      query: { s_cid: 'url-default-24110' },
    }),
  },
  {
    path: '/qvc',
    redirect: () => ({
      name: 'frontDoor',
      query: { offer: 'qvc' },
    }),
  },
  {
    path: '/pride',
    redirect: () => ({
      name: 'frontDoor',
      query: { offer: 'pride' },
    }),
  },
];
/* eslint-enable camelcase */
