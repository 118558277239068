/* global chrome, cast */
/* eslint no-undef: "error" */
import DRMChooser from '@player/drm/drm-chooser.js';
import PlayerModel from '@player/models/player-model.js';

import Logger from '@utils/logger.js';
const logger = new Logger('app-player');

export const getDefaults = () => ({
  castable: false,
  casting: false,
  currentTitleInfo: {
    creditMarkerSeconds: 0,
    creditMarkerMillis: 0,
    description: '',
    duration: 0,
    episodeNumber: null,
    id: null,
    image: null,
    images: [],
    isFree: false,
    isLive: false,
    name: null,
    playlistType: null,
    rating: null,
    refid: null,
    releaseYear: null,
    series: null,
    title: '',
    titleType: null,
  },
  deviceName: null,
  origin: null,
  remotePlayer: null,
  senderMinimized: false,
  titleId: null,
  drm: null,
  drmChecked: false,
});

export const state = getDefaults();

/**
 * Normalize audio tracks to the same object
 * This is necessary because different DRM classes provide tracks in different formats
 * @param {Array|AudioTrackList} tracks
 * @returns {Array}
 */
export const normalizeAudioTracks = function (tracks) {
  if (!tracks) return null;

  /**
   * Add an appropriate label for audio tracks that might not have one
   * @param {Object} track
   * @returns {String}
   */
  const normalizeLabel = (track) => {
    if (track.label) return track.label;
    let label = '';
    switch (track.language) {
      case 'en': {
        label += 'English';
        break;
      }
      case 'es': {
        label += 'Spanish';
        break;
      }
      default: {
        label += 'English';
        break;
      }
    }

    switch (track.role || track.kind || track.roles?.[0]) {
      case 'alternate':
      case 'description': {
        label += ' - Audio Description';
        break;
      }
    }
    return label;
  };

  const normalizedTracks = [];

  // HLS / Fairplay return an Array-like object, so we can't .forEach here
  for (let i = 0; i < tracks.length; i++) {
    const normalizedTrack = {
      label: normalizeLabel(tracks[i]),
      language: tracks[i].language,
      role: tracks[i].role || tracks[i].kind || tracks[i].roles?.[0],
    };
    normalizedTracks.push(normalizedTrack);
  }

  return normalizedTracks;
};

export const actions = {
  /**
   * Determine which drm to use and store to state
   * @param {Object} context - Vuex context
   */
  async checkDRM ({ commit }) {
    try {
      const drm = await DRMChooser.getSupportedDRM();
      commit('setDRM', drm);
    } catch (err) {
      logger.error('Unable to get the supported DRM');
    } finally {
      commit('setDRMChecked', true);
    }
  },

  /**
   * When we detect that the user's browser is castable, set castable to true
   * @param {Object} context - Vuex context
   */
  browserCastable ({ commit }) {
    commit('setCastable', true);
  },

  /**
   * Flip casting flag to indicate the end of user's Chromecast session
   * @param {Object} context - Vuex context
   */
  endCasting ({ commit, dispatch }) {
    commit('setCasting', false);
    dispatch('maximizeSender');
  },

  /**
   * Initialize Chromecast listeners to handle when a Chromecast session begins
   * @param {Object} context - Vuex context
   * @param {String} id - Chromecast application id
   */
  async initializeChromecast ({ commit, dispatch }, id) {
    const options = {};

    // todo: this should come from api/dictionary
    // options.receiverApplicationId = '1D5C1F82';
    // options.receiverApplicationId = '2B919E70'; // qa1
    options.receiverApplicationId = id; // DEV

    // Auto join policy can be one of the following three:
    // ORIGIN_SCOPED - Auto connect from same appId and page origin
    // TAB_AND_ORIGIN_SCOPED - Auto connect from same appId, page origin, and tab
    // PAGE_SCOPED - No auto connect
    // catch chrome not being available
    try {
      options.autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
      cast.framework.CastContext.getInstance().setOptions(options);

      const remotePlayer = new cast.framework.RemotePlayer();
      const remotePlayerController = new cast.framework.RemotePlayerController(remotePlayer);

      remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        () => dispatch('chromecastConnectionChanged', remotePlayer)
      );
      dispatch('browserCastable');
      commit('setRemotePlayer', remotePlayer);
    } catch (err) {
      logger.log('Chrome not available, likely in a different browser');
    }
  },

  /**
   * Handles Chromecast connection changes -- if connected, check if we should
   *   fetch & set current metadata and display mini-sender and set casting flag to
   *   true, otherwise set casting flag to false
   * @param {Object} context - Vuex context
   * @param {Object} remotePlayer - Chromecast remotePlayer
   */
  async chromecastConnectionChanged ({ commit, dispatch }, remotePlayer) {
    if (cast && cast.framework && remotePlayer.isConnected) {
      const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
      const activeMedia = castSession.getMediaSession();

      // if the chromecast receiver is already actively playing a title and we're not on a
      //   play route, then we want to display the mini-sender with all the relevant information
      if (activeMedia && activeMedia.media && window.location.href.indexOf('/play/') === -1) {
        const model = new PlayerModel();
        await model.fetchAndSetNewTitle(activeMedia.media.contentId);
        await dispatch('newTitleId', activeMedia.media.contentId);
        dispatch('minimizeSender');
      }

      commit('setDeviceName', castSession.getCastDevice().friendlyName);
      dispatch('startCasting');
      return;
    }

    dispatch('endCasting');
  },

  /**
   * Flip sender minimized flag to indicate the that the sender is maximised
   * @param {Object} context - Vuex context
   */
  maximizeSender ({ commit }) {
    commit('setSenderMinimized', false);
  },

  /**
   * Flip sender minimized flag to indicate the that the sender is minimized
   * @param {Object} context - Vuex context
   */
  minimizeSender ({ commit }) {
    commit('setSenderMinimized', true);
  },

  /**
   * Handle new Title ID for ShoPlayer of content that we are beginning playback
   *   for
   * @param {Object} context - Vuex context
   * @param {String} titleId - New Title ID for playback content in ShoPlayer
   */
  newTitleId ({ commit }, titleId) {
    commit('setTitleId', titleId);
  },

  /**
   * Store path for where playback was initiated
   * @param {Object} context - Vuex context
   * @param {Object} from - Route object from where playback was initiated
   */
  storeOrigin ({ commit }, from) {
    commit('setOrigin', from);
  },

  /**
   * Flip casting flag to indicate the start of user's Chromecast session
   * @param {Object} context - Vuex context
   */
  startCasting ({ commit }) {
    commit('setCasting', true);
  },

  /**
   * Handle an update request to the current title object (name, series, title type, etc)
   * @param {Object} context - Vuex context
   * @param {Object} currentTitleUpdate - Object with new current title data
   */
  updateCurrentTitleInfo ({ commit }, currentTitleUpdate) {
    // Reset currentTitleInfo before incase title is switching between Series, Movies, Live, etc.
    commit('setCurrentTitleInfo', getDefaults().currentTitleInfo);
    commit('setCurrentTitleInfo', currentTitleUpdate);
  },

  /**
   * Reset current title object to defaults
   * @param {Object} context - Vuex context
   */
  resetCurrentTitleInfo ({ commit }) {
    commit('setCurrentTitleInfo', getDefaults().currentTitleInfo);
  },
};

export const mutations = {
  /**
   * Set drm for client
   * @param {Object} state - Vuex state
   * @param {Object} drm - DRM object
   */
  setDRM (state, drm) {
    state.drm = drm;
  },

  /**
   * Tracks whether the initial DRM check has been complete
   * @param {Object} state - Vuex state
   * @param {Boolean} checked - True/False whether or not the drm has been checked
   */
  setDRMChecked (state, checked) {
    state.drmChecked = checked;
  },

  /**
   * Set Device Name for user's Chromecast device for current session
   * @param {Object} state - Vuex state
   * @param {String} deviceName - String representing Device Name
   */
  setDeviceName (state, deviceName) {
    state.deviceName = deviceName;
  },

  /**
   * Set Castable to given value
   * @param {Object} state - Vuex state
   * @param {Boolean} castable - True/False whether or not browser is able to use chromecast
   */
  setCastable (state, castable) {
    state.castable = castable;
  },

  /**
   * Set Casting State that reflects whether or not we have an active
   *   Chromecast session
   * @param {Object} state - Vuex state
   * @param {Boolean} castingState - True/False reflecting whether or not we
   *   we have an active Chromecast session
   */
  setCasting (state, castingState) {
    state.casting = castingState;
  },

  /**
   * Set Origin path from where playback was initiated
   * @param {Object} state - Vuex state
   * @param {String} origin - String full path of where playback was initiated
   */
  setOrigin (state, origin) {
    state.origin = origin;
  },

  /**
   * Set Remote Player object that we obtain from Chromecast framework API
   *   when a Chromecast session is initiated
   * @param {Object} state - Vuex state
   * @param {Object} remotePlayer - The remote player we obtain from Chromecast
   *   framework API when a Chromecast session is initiated
   */
  setRemotePlayer (state, remotePlayer) {
    state.remotePlayer = remotePlayer;
  },

  /**
   * Set Sender Minimized state that reflects whether or not the sender is minimized
   * @param {Object} state - Vuex state
   * @param {Boolean} senderMinimized - True/False reflecting whether or not
   *   sender is minimized
   */
  setSenderMinimized (state, senderMinimized) {
    state.senderMinimized = senderMinimized;
  },

  /**
   * Set Title ID that reflects the content we are initiating playback for in
   *   ShoPlayer
   * @param {Object} state - Vuex state
   * @param {String} titleId - String Title ID of new video content
   */
  setTitleId (state, titleId) {
    state.titleId = titleId;
  },

  /**
   * Set current title state to new title object
   * @param {Object} state - Vuex state
   * @param {Object} currentTitleUpdate - Object with updated current title information
   */
  setCurrentTitleInfo (state, currentTitleUpdate) {
    Object.entries(currentTitleUpdate).forEach(([key, value]) => {
      state.currentTitleInfo[key] = value;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
