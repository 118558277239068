import api from '@api/api.js';

const URL = 'comingsoon';

export const actions = {
  async getComingSoon () {
    const response = await api.get(URL);
    if (!response.contents) {
      throw new Error('Contents not found.');
    }

    return response.contents;
  },
};

export default {
  namespaced: true,
  actions,
};
