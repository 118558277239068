<template>
  <ul class="social-icons" data-name="social-icons">
    <li v-if="urls.twitter" :class="align">
      <a :href="urls.twitter" target="_blank" rel="noopener noreferrer" data-app="twitter">
        <span class="social-icon twitter" /> <span v-if="showLabel" class="social-label">Twitter</span>
      </a>
    </li>
    <li v-if="urls.facebook" :class="align">
      <a :href="urls.facebook" target="_blank" rel="noopener noreferrer" data-app="facebook">
        <span class="social-icon facebook" /> <span v-if="showLabel" class="social-label">Facebook</span>
      </a>
    </li>
    <li v-if="urls.instagram" :class="align">
      <a :href="urls.instagram" target="_blank" rel="noopener noreferrer" data-app="instagram">
        <span class="social-icon instagram" /> <span v-if="showLabel" class="social-label">Instagram</span>
      </a>
    </li>
    <li v-if="urls.sho" :class="align">
      <a :href="urls.sho" target="_blank" rel="noopener noreferrer" data-app="sho-com">
        <span class="social-icon sho" /> <span v-if="showLabel" class="social-label">Visit Sho.com</span>
      </a>
    </li>
    <li v-if="urls.store" :class="align">
      <a :href="urls.store" target="_blank" rel="noopener noreferrer" data-app="sho-store">
        <span class="social-icon sho-store" /> <span v-if="showLabel" class="social-label">SHO Store</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    urls : {
      type: Object,
      default  () {
        return {};
      },
      required: true,
    },
    align: {
      type: String,
      default () {
        return 'horizontal';
      },
      required: false,
    },
    showLabel: {
      type: Boolean,
      default () {
        return false;
      },
      required: true,
    },
  },

};
</script>
<style scoped>
  a {
    color: white;
    opacity: 0.7;
    text-decoration: none;
  }

  .horizontal {
    display: inline-block;
  }

  .social-icon {
    background: url("~@assets/img/icons/social-icons-lg.png") no-repeat;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin-right: 7px;
    opacity: 0.7;
    vertical-align: bottom;
    width: 20px;
  }

  .social-icon.twitter {
    background-position: -34px -20px;
  }

  .social-icon.facebook {
    background-position: 0 -20px;
  }

  .social-icon.instagram {
    background-position: -68px -20px;
  }

  .social-icon.sho {
    background-position: -100px -20px;
  }

  .social-icon.sho-store {
    background: url("~@assets/img/icons/footer-icon-store-20px.png") no-repeat;
  }
</style>
