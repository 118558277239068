import { APP_TYPES } from '@utils/constants.js';

/**
 * These routes are maintained to handle older deep links coming from
 * other platforms. /#/ is already remoed by the main router.js file
 */
const routes = [
  {
    path: '/series/allseries',
    redirect: () => ({
      name: 'allSeries',
    }),
  },
  {
    path: '/movies/allmovies',
    redirect: () => ({
      name: 'movies',
    }),
  },
  {
    path: '/sports/allsports',
    redirect: () => ({
      name: 'category',
      params: { id: 407, categorySlug: 'sports' },
    }),
  },
  {
    path: '/comedy/allcomedy',
    redirect: () => ({
      name: 'category',
      params: { id: 743, categorySlug: 'comedy' },
    }),
  },
  {
    path: '/documentaries/alldocumentaries',
    redirect: () => ({
      name: 'category',
      params: { id: 103, categorySlug: 'documentaries' },
    }),
  },
  {
    path: '/afterhours/allafterhours',
    redirect: () => ({
      name: 'category',
      params: { id: 435, categorySlug: 'after-hours' },
    }),
  },
  {
    path: '/play/live/shoeast',
    redirect: () => ({
      name: 'playLive',
      params: { channel: 'shoeast' },
    }),
  },
  {
    path: '/play/live/showest',
    redirect: () => ({
      name: 'playLive',
      params: { channel: 'showest' },
    }),
  },
];

if (__APP_TYPE__ === APP_TYPES.stat) {
  routes.push(
    {
      path: '/settings/activate',
      redirect: () => ({
        name: 'activate',
      }),
    }
  );

  routes.push(
    {
      path: '/home',
      redirect: () => ({
        name: 'home',
      }),
    }
  );
}

export default routes;
