const imageConfig = {
  RESUME_WATCHING: {
    series: {
      type: '98',
      hoverType: '01',
      width: '711',
      height: '400',
    },
    movies: {
      type: '00h',
      hoverType: '02',
      width: '711',
      height: '400',
    },
    fights: {
      type: '98',
      hoverType: '92',
      width: '711',
      height: '400',
    },
  },
  FREE_FULL_EPISODES  : {
    series: {
      type: '98',
      hoverType: '01',
      width: '711',
      height: '400',
    },
  },
  SERIES_SHELF: {
    series: {
      type: '99',
      hoverType: '99',
      width: '661',
      height: '494',
    },
  },
  SERIES_GRID: {
    series: {
      type: '99',
      hoverType: '99',
      width: '661',
      height: '494',
    },
  },
  MOVIES_SHELF: {
    movies: {
      type: '00',
      hoverType: '00',
      width: '400',
      height: '600',
    },
  },
  MOVIES_GRID: {
    movies: {
      type: '00',
      hoverType: '00',
      width: '400',
      height: '600',
    },
  },
  MIXED_SERIES_MOVIES_SHELF: {
    series: {
      type: '98',
      hoverType: '01',
      width: '711',
      height: '400',
    },
    movies: {
      type: '00h',
      hoverType: '02',
      width: '711',
      height: '400',
    },
  },
  MIXED_SERIES_MOVIES_GRID: {
    series: {
      type: '98',
      hoverType: '01',
      width: '711',
      height: '400',
    },
    movies: {
      type: '00h',
      hoverType: '02',
      width: '711',
      height: '400',
    },
  },
  MIXED_SERIES_MOVIES_DRAWER: {
    series: {
      type: '98',
      width: '250',
      height: '250',
    },
    movie: {
      type: '00',
      width: '179',
      height: '275',
    },
    fight: {
      type: '99',
      width: '180',
      height: '180',
    },
  },
};

export const imageBuilder = function (imageUrl, context, titleType, hover) {
  const type = titleType.toLowerCase();
  const useHoverType = hover || false;
  const imageType = useHoverType ? 'hoverType' : 'type';

  if (imageConfig[context]) {
    let imageMeta = imageConfig[context];
    if (imageConfig[context][type]) {
      imageMeta = imageMeta[type];
    } else {
      const firstType = Object.keys(imageMeta[0]);
      imageMeta = imageMeta[firstType];
    }
    return imageUrl.replace('{image_types}', imageMeta[imageType])
      .replace('{width}', imageMeta.width)
      .replace('{height}', imageMeta.height);
  } else {
    throw new Error(`Cannot construct URL for given image:${context} ${type}`);
  }
};

