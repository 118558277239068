import api from '@api/api.js';

const baseUrl = 'user/cancellationoffer';

export const actions = {
  /**
     * Submits a cancellation offer coupon code.
     * @async
     * @param {Object} context - Store context.
     * @param {Object} payload
     * @param {string} payload.code - Coupon code to validate.
     * @returns {Object} - Validated coupon metadata.
     * @throws {Object} - Throws on invalid coupon or API error.
     */
  applyOffer (context, couponCode) {
    return api.post(baseUrl, { couponCode });
  },
};

export default {
  namespaced: true,
  actions,
};
