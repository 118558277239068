import api from '@api/api.js';

const purchasesUrl = 'user/ppv/purchases';
const latestPurchaseUrl = 'user/ppv/latest-purchase';

export const getDefaults = () => ({
  purchases: {
    data: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  latestPurchase: {
    data: {
      amountInCents: '',
      billingType: '',
      created: null,
      description: '',
      eventDate: null,
      orderNumber: null,
      paymentMethod: '',
      transactionDate: null,
      transactionType: '',
      tveUserId: null,
    },
    fetching: false,
    fetched: false,
    error: null,
  },
});

const state = getDefaults();

export const actions = {
  /**
   * Fetch a list of User's PPV purchases
   * @param {Object} context - Vuex store context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   */
  async getPurchases ({ commit, state }, options = {}) {
    // Make an API call if fresh data requested,
    // or if data has never been fetched, and a fetch is not in progress
    if (!state.purchases.fetching && (options.cacheBust || !state.purchases.fetched)) {
      try {
        commit('setFetching', { type: 'purchases', fetching: true });
        const response = await api.get(purchasesUrl);
        commit('setPurchases', response);
      } catch (error) {
        commit('setError', { type: 'purchases', error });
      }
    }
  },
  /**
   * Fetch a User's latest PPV purchase
   * @param {Object} context - Vuex store context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   */
  async getLatestPurchase ({ commit, state }, options = {}) {
    // Make an API call if fresh data requested,
    // or if data has never been fetched, and a fetch is not in progress
    if (!state.latestPurchase.fetching && (options.cacheBust || !state.latestPurchase.fetched)) {
      try {
        commit('setFetching', { type: 'latestPurchase', fetching: true });
        const response = await api.get(latestPurchaseUrl);
        commit('setLatestPurchase', response);
      } catch (error) {
        commit('setError', { type: 'latestPurchase', error });
      }
    }
  },
  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  setPurchases (state, response) {
    state.purchases.data = response;
    state.purchases.fetched = true;
    state.purchases.fetching = false;
  },

  setLatestPurchase (state, response) {
    state.latestPurchase.data = response;
    state.latestPurchase.fetched = true;
    state.latestPurchase.fetching = false;
  },

  setFetching (state, { type, fetching }) {
    state[type].error = null;
    state[type].fetching = fetching;
  },

  setError (state, { type, error }) {
    state[type].error = error;
    state[type].fetching = false;
  },

  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

