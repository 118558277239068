/* eslint-disable camelcase */
const PPV = () => import('@pages/ppv/PPVPage.vue');
const PPVBoutDetailPage = () => import('@pages/ppv/PPVBoutDetailPage.vue');

// Purchase Routes
const PPVCreateAccountPage = () => import('@pages/ppv/PPVCreateAccountPage.vue');
const PPVCheckEmailPage = () => import('@pages/ppv/PPVCheckEmailPage.vue');
const PPVLoginPage = () => import('@pages/ppv/PPVLoginPage.vue');
const PPVPurchasePage = () => import('@pages/ppv/PPVPurchasePage.vue');

/**
 * PPV Routes
 */
export default [
  /**
   * Bout Detail
   */
  {
    path: '/ppv/bout/:boutId',
    name: 'boutDetails',
    props: (route) => ({ boutId: route.params.boutId }),
    component: PPVBoutDetailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, LG
   */
  {
    path: '/lgppv',
    alias: ['/lg-ppv', '/lgtvppv', '/lgtv-ppv'],
    name: 'lgppv',
    props: { deviceId: 'lg' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, Samsung
   */
  {
    path: '/samsungppv',
    alias: ['/samsung-ppv', '/samsungtvppv', '/samsungtv-ppv'],
    name: 'samsungppv',
    props: { deviceId: 'samsung' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, Xbox
   */
  {
    path: '/xboxppv',
    alias: ['/xboxoneppv', '/xboxone-ppv', '/xbox-ppv'],
    name: 'xboxppv',
    props: { deviceId: 'xbox' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, PS4
   */
  {
    path: '/ps4ppv',
    alias: '/ps4-ppv',
    name: 'ps4ppv',
    props: { deviceId: 'ps4' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, PS5
   */
  {
    path: '/ps5ppv',
    alias: '/ps5-ppv',
    name: 'ps5ppv',
    props: { deviceId: 'ps5' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, Xfinity
   */
  {
    path: '/xfinityppv',
    alias: '/xfinity-ppv',
    name: 'xfinityppv',
    props: { deviceId: 'xfinity' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, XClass
   */
  {
    path: '/xclassppv',
    alias: '/xclass-ppv',
    name: 'xclassppv',
    props: { deviceId: 'xclass' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },

  /**
   * Purchase Flow - Rendezvous Start, COX
   */
  {
    path: '/coxppv',
    alias: '/cox-ppv',
    name: 'coxppv',
    props: { deviceId: 'cox' },
    component: PPVCheckEmailPage,
    meta: {
      section: 'ppv',
    },
  },  

  /**
   * Purchase Flow - Create Account
   */
  {
    path: '/ppv/:deviceId?/signup',
    name: 'ppvSignup',
    component: PPVCreateAccountPage,
    props: true,
    meta: {
      section: 'ppv',
      access: {
        ott: {
          authorized: false,
          authenticated: false,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },

  /**
   * Purchase Flow - Login
   */
  {
    path: '/ppv/:deviceId?/login',
    name: 'ppvLogin',
    component: PPVLoginPage,
    props: true,
    meta: {
      section: 'ppv',
      access: {
        ott: {
          authorized: false,
          authenticated: false,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },  

  /**
   * Purchase Flow - Purchase Payment & Confirmation
   */
  {
    path: '/ppv/:deviceId?/purchase',
    name: 'ppvPurchase',
    component: PPVPurchasePage,
    props: true,
    meta: {
      section: 'ppv',
      access: {
        ott: {
          authorized: true,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },

  /**
   * Event Info
   */

  // NOTE: the ":section" wildcard means this route must be listed last; possibly add a /section/ type route to handle the matchup section link
  {
    path: '/ppv/:section?',
    name: 'ppv',
    component: PPV,
    // NOTE: Can't this just be props: true?
    props: (route) => ({ section: route.params.section }),
    meta: {
      section: 'ppv',
    },
  },

  // DirecTV Vanity Routes

  {
    path: '/directvstream',
    redirect: () => ({
      name: 'ppv',
      query: { s_cid: 'aff-ppv-24031' },
    }),
  },
  {
    path: '/directtvstream',
    redirect: () => ({
      name: 'ppv',
      query: { s_cid: 'aff-ppv-24031' },
    }),
  },
];
/* eslint-enable camelcase */
