/**
 * Upsell Modal state defaults.
 * @typedef {Object} UpsellModalDefaults
 * @property {boolean} [isVisible] - Upsell modal visibility.
 */
const getDefaults = () => ({
  body: '',
  template: '',
});

export const state = getDefaults();

export const getters = {
  /**
   * Inidicates if upsell modal is visible or not.
   * @param {Object} state - Module state
   * @returns {boolean}
   */
  isVisible (state) {
    return !!state.body;
  },
};

export const mutations = {
  setModal (state, { body, template }) {
    state.body = body;
    state.template = template;
  },

  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Show upsell modal action
   * @param {Object} context - Vuex module context.
   */
  show ({ commit }, { body = '', template = '' }) {
    commit('setModal', { body, template });
  },

  /**
   * Close upsell modal action
   * @param {Object} context - Vuex module context.
   */
  close ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
