/**
 * Checks state.errors for non-empty error messages.
 * @param {Object} state - Form store state object.
 * @param {Object} state.errors - Error messages keyed by field.
 */
export const errorCount = function (state) {
  if (!Object.prototype.hasOwnProperty.call(state, 'errors')) {
    throw new Error('No state errors object.');
  }

  const errors = Object.values(state.errors) || [];
  return errors.filter((error) => error).length;
};

/**
 * 
 * @param {Object} state - Form store state object.
 * @param {Object} payload 
 * @param {string} payload.field - Input field key.
 * @param {string} payload.error - Error message.
 */
export const setError = function (state, payload) {
  if (!Object.prototype.hasOwnProperty.call(state, 'errors')) {
    throw new Error('No state errors object.');
  }

  if (!Object.prototype.hasOwnProperty.call(state, payload.field)) {
    throw new Error(`Error key '${payload.field}' not present in form state.`);
  }

  state.errors[payload.field] = payload.error;
};

/**
 * Clears any form state error messages.
 * @param {Object} state - Form store state object.
 */
export const clearErrors = function (state) {
  if (!state.errors) {
    throw new Error('No state errors object.');
  }

  for (const key in state.errors) {
    state.errors[key] = null;
  }
};

/**
 * Sets form input fields to store state.
 * @param {Object} state - Form store state object.
 * @param {Object} payload 
 * @param {string} payload.field - Input field key. 
 * @param {*} payload.value - Input value.
 */
export const setField = function (state, payload) {
  if (!Object.prototype.hasOwnProperty.call(state, payload.field)) {
    throw new Error('Input key not present.');
  }

  state[payload.field] = payload.value;
};
