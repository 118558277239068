<template>
  <span class="sho-icon" :class="componentClass">
    <component :is="icon" :fill="fill" :aria-label="label" />
  </span>
</template>
<script>
import { defineAsyncComponent, markRaw } from 'vue';

export default {
  name: 'ShoIcon',
  props: {
    /**
     * Specifies icon file to load.
     */
    type: {
      type: String,
      required: true,
    },
    /**
     * SVG fill color. Defaults to match font color.
     */
    fill: {
      type: String,
      default: 'currentColor',
    },
    /**
     * Optional icon label for usability.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Size presets.
     */
    size: {
      type: String,
      default: null,
      validator (value) {
        return !value || ['small', 'medium', 'large'].indexOf(value) !== -1;
      },
    },
  },
  data () {
    return {
      icon: null,
    };
  },
  computed: {
    componentClass () {
      return [this.size];
    },
  },
  watch: {
    type: {
      immediate: true,
      handler () {
        if (this.type) {
          this.icon = markRaw(defineAsyncComponent(() =>
            import(`@assets/img/icons/svg/${this.type}.svg`)
          ));
        }
      },
    },
  },
};
</script>
<style scoped>
  .sho-icon {
    align-self: center;
    display: inline-flex;
  }

  .sho-icon svg {
    display: inline-block;
    height: 1em;
    position: relative;
    top: 0.125em;
    width: auto;
  }

  .sho-icon.small svg {
    height: 25px;
  }

  .sho-icon.medium svg {
    height: 75px;
  }

  .sho-icon.large svg {
    height: 100px;
  }
</style>
