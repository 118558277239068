import api from '@api/api.js';

export const newsletterUrl = 'user/newsletter/subscribe';

export const actions = {
  async toggleSubscription (context, subscribed) {
    await api.get(`${newsletterUrl}/${subscribed}`);
  },
};

export default {
  namespaced: true,
  actions,
};

