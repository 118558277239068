export default class EventBus {
  /**
     * Initialize a new event bus instance.
     */
  constructor () {
    this.queue = [];
  }

  /**
     * Add an event listener.
     */
  on (event, callback) {
    const eventSymbol = Symbol(event);
    const eventItem = {
      id: eventSymbol,
      eventName: event,
      callback,
    };
    this.queue.push(eventItem);
  }

  /**
     * Remove an event listener.
     */
  remove (event) {
    this.queue = this.queue.filter((item) => item.id !== event);
  }

  /**
     * Dispatch an event.
     */
  dispatch (event, detail) {
    this.queue.forEach((item) => {
      if (item.eventName === event) {
        item.callback.call(null, detail);
      }
    });
  }
}
