export const isMobileDevice = function () {
  const ua = navigator.userAgent && navigator.userAgent.toLowerCase();
  const isTouchDevice = 'ontouchstart' in document.documentElement;
  return (/(iphone|ipod|ipad)/).test(ua) ||
            (/(android)/).test(ua)  ||
            (/(iemobile)/).test(ua) ||
            (/blackberry/i).test(ua) ||
            (/bb/i).test(ua) ||
            (/rim/i).test(ua) ||
            (/bada/i).test(ua) ||
            (/silk/i).test(ua) ||
            ((/safari/).test(ua) && isTouchDevice);
};

export const isSupportedMobileDevice = function () {
  const ua = navigator.userAgent && navigator.userAgent.toLowerCase();
  return isMobileDevice() && ((/(iphone|ipod|ipad)/).test(ua) || (/iphone/i).test(ua) || (/ipad/i).test(ua) || (/ipod/i).test(ua) || (/android/i).test(ua) || (/silk/i).test(ua));
};

export const getDeviceType = function () {
  const ua = navigator.userAgent.toLowerCase();
  if ((/(iphone|ipod|ipad)/).test(ua) || (/iphone/i).test(ua) || (/ipad/i).test(ua) || (/ipod/i).test(ua)) {
    return 'ios';
  }

  if ((/kindle|silk|kfot|kfft|kfjwi|kfjwa/gi).test(ua)) {
    return 'amazon';
  }

  if ((/(android)/).test(ua)) {
    return 'android';
  }

  return 'unsupported';
};

export default {
  isMobileDevice,
  isSupportedMobileDevice,
  getDeviceType,
};
