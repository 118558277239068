import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('series-detail');

const seriesDetailUrl = 'series';

export const defaults = {
  fetching: false,
  fetched: false,
  error: null,
  bi: {},
  tuneIn: '',
  seasons: [],
  totalSeasons: 0,
  totalEpisodes: 0,
  seriesStatus: '',
  type: '',
  erotic: false,
  id: null,
  name: '',
  releaseYear: null,
  sortName: '',
  images: null,
  description: null,
  links: [],
  flags: [],
  genre: '',
  heroTrailer: null,
  social: null,
  created: null,
  credits: null,
};

const state = { ...defaults };

export const actions = {
  /**
   * Fetches series details
   * @param {Object} context 
   * @param {Number} seriesId 
   */
  async getSeriesDetail ({ commit }, seriesId) {
    try {
      commit('setFetching');
      const fetch = await api.get(`${seriesDetailUrl}/${seriesId}`);
      commit('setSeriesDetail', fetch);
    } catch (error) {
      logger.error(error);
      commit('setError', error);
    }
  },
  /**
   * Resets state
   * @param {Object} context 
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  setSeriesDetail (state, response) {
    Object.assign(state, response);
    state.fetching = false;
    state.fetched = true;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setError (state, error) {
    state.fetching = false;
    state.fetched = false;
    state.error = error;
  },
  reset (state) {
    Object.assign(state, { ...defaults });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
