export default class logger {
  constructor (prefix = null) {
    const silentEnvs = ['prod', 'production', 'test'];
    this.env = __BUILDENV__;
    this.prefix = prefix ? `[${prefix}]` : null;
    this.silentLogger = silentEnvs.includes(this.env) || typeof this.env === 'undefined';
  }

  log () {
    if (this.silentLogger) return;
    const args = this.prefix ? [this.prefix, ...arguments] : [...arguments];
    console.log(...args);
  }

  warn () {
    if (this.silentLogger) return;
    const args = this.prefix ? [this.prefix, ...arguments] : [...arguments];
    console.warn(...args);
  }

  error () {
    if (this.silentLogger) return;
    const args = this.prefix ? [this.prefix, ...arguments] : [...arguments];
    console.log(this.env);
    console.error(...args);
  }

  info () {
    if (this.silentLogger) return;
    const args = this.prefix ? [this.prefix, ...arguments] : [...arguments];
    console.info(...args);
  }

  debug () {
    if (this.silentLogger) return;
    const args = this.prefix ? ['[WS2K DEBUG]', this.prefix, ...arguments] : ['[WS2K DEBUG]', ...arguments];
    console.log(...args);
  }
}
