<template>
  <div class="my-list-skeleton">
    <ul>
      <li class="my-list-item series">
        <div class="poster" />
        <div class="meta">
          <div class="name" />
        </div>
      </li>
      <li class="my-list-item movie">
        <div class="poster" />
        <div class="meta">
          <div class="name" />
          <div class="release" />
        </div>
      </li>
      <li class="my-list-item series">
        <div class="poster" />
        <div class="meta">
          <div class="name" />
        </div>
      </li>
    </ul>
    <div class="gradient" />
  </div>
</template>

<script>
export default {
  name: 'MyListSkeleton',
};
</script>

<style scoped>
  .my-list-skeleton {
    position: relative;
  }

  .my-list-skeleton ul {
    list-style: none;
    margin: 0;
    opacity: 0.3;
    padding: 0;
  }

  .my-list-item {
    border-top: 1px solid var(--light_grey_40);
    display: flex;
    padding: 12px 0;
  }

  .poster {
    background: var(--medium_grey);
    height: 100px;
    width: 100px;
  }

  .meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
  }

  .name {
    background: var(--medium_grey);
    height: 18px;
    width: 120px;
  }

  .release {
    background: var(--medium_grey);
    height: 16px;
    margin-top: 8px;
    width: 75px;
  }

  .gradient {
    background: linear-gradient(180deg, rgba(51, 51, 51, 0%) 0%, rgba(51, 51, 51, 100%) 90%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
</style>
