import Logger from '@utils/logger.js';
const logger = new Logger('api');

const request = (url, method, body, skipCache) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-STAT-appVersion': __VERSION__,
    'X-STAT-contentVersion': `${__APP_TYPE__}`.toUpperCase(),
    'X-STAT-displayType': 'WEB',
  });

  const config = {
    headers,
    cache: 'default',
    credentials: 'include',
  };

  const payload = {
    method,
    ...config,
  };

  if (body !== undefined) {
    payload.body = JSON.stringify(body);
  }

  const fullURL = url[0] === '/' ? url : `${__APIBASE__}api/${url}`;

  if (!skipCache && fullURL in fetching) {
    return fetching[fullURL];
  } else {
    const fetchCall = new Promise((resolve, reject) => {
      fetch(fullURL, payload)
        .then((res) => {
          // Check for uncaught server error responses
          if (!res.ok) {
            reject({
              code: 'error.server.uncaught',
              body: 'It looks like we’re having some trouble contacting our server, please try again later.',
              exceptionType: 'HTTP Response',
              exception: `${res.status} (${res.statusText})`,
              call: ['OK'],
            });
            return;
          }

          parse(res).then((res) => {
            if (res && res.error) {
              // Server-caught API error
              reject(res.error);
            } else {
              if (!res) {
                logger.error(`Empty or undefined api response from ${fullURL}`);
              }
              resolve(res);
            }
          });
        })
        .catch((error) => {
          // JS Errors
          reject({
            code: 'error.client.uncaught',
            body: 'It looks like something went wrong, please try again later.',
            exceptionType: error.name,
            exception: error.message,
            call: ['OK'],
          });
        })
        .finally(() => {
          delete fetching[fullURL];
        });
    });
    fetching[fullURL] = fetchCall;
    return fetchCall;
  }
};

const fetching = {};
const parse = async (res) => {
  try {
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const text = await res.text();
      return text ? JSON.parse(text) : null;
    } else if ((contentType && (contentType.indexOf('application/octet-stream') !== -1) || contentType.indexOf('application/x-x509-ca-cert') !== -1) || contentType.indexOf('binary/octet-stream') !== -1) {
      return await res.arrayBuffer();
    }
  } catch (error) {
    // if an API results in a response that's not JSON, catch and return it as an error
    return { error };
  }
};

// These shouldn't be called from outside of stores
const get = (url, skipCache = false) => request(url, 'GET', undefined, skipCache);
const post = (url, body, skipCache = false) => request(url, 'POST', body, skipCache);
const put = (url, body, skipCache = false) => request(url, 'PUT', body, skipCache);
const del = (url, body, skipCache = false) => request(url, 'DELETE', body, skipCache);

const api = {
  get,
  post,
  put,
  del,
};
export default api;
