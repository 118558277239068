<template>
  <ShoModal
    v-if="modalVisible"
    :title="modalTitle"
    :body="modalBody"
    :buttons="modalButtons"
    @close="onModalCloseClick"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ShoModal from '@components/ui/ShoModal.vue';

/**
 * Vuex wrapper for the simple app-level modal.
 */
export default {
  name: 'AppModal',
  components: {
    ShoModal,
  },
  computed: {
    ...mapState('modal', { modalTitle: 'title', modalBody: 'body', modalButtons: 'buttons' }),
    ...mapGetters('modal', { modalVisible: 'visible' }),
  },
  methods: {
    /**
     * Handles the 'close' event from the modal.
     */
    onModalCloseClick () {
      this.$store.dispatch('modal/close');
    },
  },
};
</script>
