/**
 * PPV event store module. 
 */

import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('ppv-event');

export const url = 'ppv/event';

/**
 * Defaults for the PPV event store.
 * @typedef {Object} PPVEventDefaults
 * @property {Object} mainfightcard - Main fight card details
 * @property {Object} prelimfightcard - Preliminary fight card details
 * @property {Object} vodContent - VOD healine and shelves
 * @property {Object} faq - FAQ headline and questions
 * @property {string} moreWaysToBuyUrl - more ways to watch URL
 * @property {boolean} doubleHeader - is event a double header
 * @property {boolean} fetching - Indicates if an API interaction is in progress.
 * @property {boolean} fetched - Indicates if at least one successful API call has been made.
 * @property {Object} error - Contains any API error response.
 */

/**
 * Returns an immutable set of default data to be used in state.
 * @returns {PPVEventDefaults} - Default event data.
 */
export const getDefaults = () => ({
  mainfightcard: {
    headline: '',
    description: '',
    bouts: [],
  },
  prelimfightcard: {
    headline: '',
    description: '',
    bouts: [],
  },
  vodContent: {
    headline: '',
    shelves: [],
  },
  faq: {
    headline: '',
    items: [],
  },
  moreWaysToBuyUrl: 'https://www.sho.com/sports/fights/',
  doubleHeader: false,
  fetching: false,
  fetched: false,
  error: null,
});

export const state = getDefaults();

export const getters = {
  getBoutById: (state) => function (id) {
    let bout;
    
    // Search main fightcard for bout
    if (state.mainfightcard && state.mainfightcard.bouts && state.mainfightcard.bouts.length) {
      bout = state.mainfightcard.bouts.find((bout) => bout.id === id);
    }

    // Search prelim fightcard for bout
    if (!bout && state.prelimfightcard && state.prelimfightcard.bouts && state.prelimfightcard.bouts.length) {
      bout = state.prelimfightcard.bouts.find((bout) => bout.id === id);
    }

    return bout;
  },
};

export const mutations = {
  setFetching (state, fetching) {
    state.error = null;
    state.fetching = fetching;
  },
  setResponse (state, response) {
    logger.log('[PPV Event] event data fetched successfully');
    // Reset state to defaults and then set to API response
    Object.assign(state, getDefaults(), response);
    state.fetching = false;
    state.fetched = true;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Gets PPV event data.
   * @param {Object} context - Vuex context.
   * @param {Object} [options={}] - Optional request settings
   * @param {boolean} [options.cacheBust] - Forces an API hit regardless of existing state.
   */
  async getEventData ({ commit, state }, options = {}) {
    // Make an API call if user has requested fresh data,
    // or if data has never been fetched, and a fetch is not in progress
    if ((!state.fetching && !state.fetched) || options.cacheBust) {
      try {
        commit('setFetching', true);
        const response = await api.get(url);
        // remove 'navigation' - not used on web
        if (response.navigation) {
          delete response.navigation;
        }
        commit('setResponse', response);
      } catch (error) {
        commit('setError', error);
      }
    }
  },
  /**
   * Resets state to defaults.
   * @param {Object} context - Vuex context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
