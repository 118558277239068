import { createRouter, createWebHistory } from 'vue-router';

import { APP_TYPES } from '@utils/constants.js';
import routes from '@routes/__APP_TYPE__/routes.js';
import store from '@store/store.js';
import { isMobileDevice } from '@utils/mobile-detect.js';
import storeRoute from '@utils/route-storage.js';

// list of routes that redirect to static
// do a return false in the beforeEach
const staticIgnoreRoutes = ['connect', 'loginresult'];

// Register an event listener to detect back button navigation
window.isBackButtonNavigation = false;
window.addEventListener('popstate', () => {
  window.isBackButtonNavigation = true;
});

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition || isGetStartedRoute(to)) {
      // Routing occured via back/foward buttons
      return savedPosition;
    }

    if (to === from || to.path === from.path || to?.meta?.preventScroll) {
      return null;
    }

    return { el: '#app', x: 0, y: 0 };  
  },
});

/**
 * Returns boolean indicating if route's permissions will allow a user
 * to access that route, based on user's authorized/authenticated status, and
 * the route's permissions.
 * Returns false if any of user's authorized/authenticated mismatch with the route's
 * permissions.
 * The checks for ott's routes are separated from stat's routes.
 * @param {object} userMeta - user's authorized/authenticated state, and if they are on a mobile device
 * @param {object} routeAccess - route permissions
 */
export const isRouteAccessible = function (userMeta, routeAccess) {
  if (__APP_TYPE__ === APP_TYPES.ott) {
    if (userMeta.authorized && !routeAccess.authorized) {
      return false;
    }

    if (!userMeta.authorized && userMeta.authenticated && !routeAccess.authenticated) {
      return false;
    }

    if (!userMeta.authenticated && !routeAccess.unauthenticated) {
      return false;
    }

    return true;
  } else {
    // User is logged out, route blocks logged out users
    if (!userMeta.authenticated && !routeAccess.unauthenticated) {
      return false;
    }

    // User is logged in, route blocks logged in users
    if (userMeta.authenticated && !routeAccess.authenticated) {
      return false;
    }

    return true;
  }
};

export const isGetStartedRoute = function (route) {
  const getStartedSections = ['waystowatch', 'whatson', 'whatsincluded'];
  if (route.name === 'getstarted' && getStartedSections.indexOf(route.params.section) > -1) {
    return true;
  }
};

/**
 * Determines if a user has access to the provided route, and handles redirecting if not.
 * @param {Object} userMeta
 * @param {Object} routeMeta
 */
export const handleRouteAccess = function (userMeta, to) {
  const routeAccess = to.meta.access[__APP_TYPE__] || {};
  const routeSection = to.meta.section || null;

  // If route is not accessible via mobile device, redirect home
  // - 'Home' route contains mobile and auth logic
  if (userMeta.mobile && !routeAccess.mobile) {
    return { name: 'home', query: to.query };
  }

  // If route is not accessible via desktop device, redirect home
  if (!userMeta.mobile && routeAccess.desktop === false) {
    return { name: 'home', query: to.query };
  }

  // If route is accessible to current user state allow
  if (isRouteAccessible(userMeta, routeAccess)) {
    return true;
  }

  // The route is inaccessible to the user:
  // - PPV Section routes are sent to the PPV Event Info page
  // - Authenticated users hitting a registration route are sent back into the reg flow
  // - Defaults to the 'home' route, which contains auth & mobile access logic

  if (routeSection === 'ppv') {
    return { name: 'ppv', query: to.query };
  }

  if (__APP_TYPE__ === APP_TYPES.ott && !userMeta.authorized) {
    // OTT route access failure logic for unauthorized users

    if (!userMeta.authenticated && to.meta.authnFailureRoute) {
      // Check if route has an authentication-required failure redirect
      return to.meta.authnFailureRoute;
    }

    if (userMeta.authenticated && routeSection === 'paywall') {
      // Authenticated user hitting a paywall route, continue purchase
      return { name: 'continuePurchase', query: to.query };
    }

    // If unauthenticated, store route for login/purchase success and navigate Home
    if (!userMeta.authenticated) {
      storeRoute(to);
    }
  } else if (__APP_TYPE__ === APP_TYPES.stat) {
    // If STAT, check if the login modal should be displayed
    if (!userMeta.authenticated && routeSection === 'settings') {
      store.dispatch('msoPicker/show');
    }
  }

  return { name: 'home', query: to.query };
};

// Route Access Checking
router.beforeEach(async (to, from) => {
  const isBackButtonNavigation = window.isBackButtonNavigation;
  window.isBackButtonNavigation = false;

  // test incoming route for matches in static ignores
  const firstPathArg = to.path.split('/').filter((i) => i.length)[0];
  if (staticIgnoreRoutes.includes(firstPathArg)) {
    return false;
  }

  // Close App Drawer and player on route
  store.dispatch('setDrawerOpen', false);

  // Close MSO Picker on route
  if (__APP_TYPE__ === APP_TYPES.stat && isBackButtonNavigation) {
    store.dispatch('msoPicker/close');
  }

  if (to.fullPath.substr(0,2) === '/#') {
    const path = to.fullPath.substr(2);
    return path;
  }

  // if the user is not on a mobile device and is going to a 'play' route, store
  //  the title ID and the route the user came from and hide mini-sender if it's visible
  if (!isMobileDevice() && ((to && to.name && to.name === 'play') || to.path.indexOf('play') > -1)) {
    const titleId = to.params && to.params.titleId ? `${to.params.titleId}` : to.path.substr(6);
    store.dispatch('appPlayer/newTitleId', titleId);
    store.dispatch('appPlayer/storeOrigin', from);
    store.dispatch('appPlayer/maximizeSender');
    return true;
  }

  // TODO: WEB-474 Vue 3 doesn't like something here, it crashes the app
  if (to.meta && to.meta.access) {
    const userMeta = {
      authorized: store.state.user.isAuthorized,
      authenticated: store.getters['user/isLoggedIn'],
      mobile: isMobileDevice(),
    };

    return handleRouteAccess(userMeta, to);
  }

  return true;
});
