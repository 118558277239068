import { mapState } from 'vuex';
import { STORAGE_KEYS } from '@utils/constants.js';
import ls from '@utils/local-storage.js';

import Logger from '@utils/logger.js';
const logger = new Logger('ImpactMixin');

/**
 * Impact config
 * @const
 * @type {Object}
 * @property {number} storageTTL - localStorage expiration in days
 * @property {Object} params - Readable map of Impact query parameters
 */
export const CONFIG = {
  storageTTL: 10,
  params: {
    affiliateSource: 'aff_src',
    affiliateName: 'irmpname',
    affiliateId: 'irpid',
    campaignId: 'ircid',
    creativeId: 'iradid',
  },
};

/**
 * Impact Affiliate Marketing Mixin. Assumes global Impact lib included by index.html
 */
export default {
  computed: {
    ...mapState('user', ['tveUserId', 'optionalAttributes']),
    ...mapState('dictionary', ['impactId']),
  },
  watch: {
    /**
     * Calls 'identify' method when a user logs in.
     * TODO: WEB-1133 This doesn't need to be a per-component mixin; move to a util and possibly an app-level instance, and do the user check at the AppWrapper level.
     * @param {number} newVal - New tveUserId value
     * @param {number} oldVal - Previous tveUserId value
     */
    tveUserId (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.identify();
      }
    },
  },
  methods: {
    /**
     * Checks query params and makes initial identify call.
     */
    initImpact () {
      if (!window.ire) {
        logger.error('Impact library not found.');
        return;
      }

      this.checkParams();
      this.identify();
    },
    /**
     * Checks for expected Impact query parameters and sets cookie.
     */
    checkParams () {
      // this.$route.query is unavailable if calling this from main.created
      const params = new URLSearchParams(location.search);
      if (params.get(CONFIG.params.affiliateSource)) {
        const affiliateCookie = {};
        for (const [key, param] of Object.entries(CONFIG.params)) {
          affiliateCookie[key] = params.get(param);
        }
        ls.setItem(STORAGE_KEYS.impact, affiliateCookie, CONFIG.storageTTL);
      }
    },
    /**
     * Makes an 'identify' call to Impact if a cookie is present
     */
    identify () {
      if (!window.ire) {
        logger.error('Impact library not found.');
        return;
      }

      const affiliateCookie = this.getImpactData();
      if (affiliateCookie && affiliateCookie.affiliateSource === 'impact') {
        window.ire('identify', { customerId: this.tveUserId });
      }
    },
    /**
     * Makes a 'trackConversion' call to Impact.
     * Cookie clearing on success removed when adding PPV purchase tracking WEB-673.
     */
    impactConversion (customerId, orderId, impactId = this.impactId) {
      if (!window.ire) {
        logger.error('Impact library not found.');
        return;
      }
      
      const payload = { customerId, orderId };
      logger.log('Making Impact conversion call', impactId, payload);
      window.ire('trackConversion', impactId, payload);
    },
    /**
     * Returns Impact local storage data, if any.
     * @returns {Object|null}
     */
    getImpactData () {
      return ls.getItem(STORAGE_KEYS.impact);
    },
    /**
     * Clears impact localStorage data
     */
    clearImpactData () {
      ls.removeItem(STORAGE_KEYS.impact);
    },
  },
};
