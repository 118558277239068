/* class representing the abstraction of drm. */

import Fairplay from '@player/drm/fairplay.js';
import Widevine from '@player/drm/widevine.js';

export default class DRMChooser {
  /**
   * Calls each DRM's 'testSupported' function and on completion resolves the first successful response (or null)
   * @returns {Promise<any>}
   */
  static async getSupportedDRM () {
    // Media Types pull from a DASH manifest - used to test Widevine (Chrome, Ffx) and Playready (Edge) support
    const mediaFormats = [{
      initDataTypes: ['cenc'],
      audioCapabilities: [
        { contentType: 'audio/mp4;codecs="mp4a.40.2"' },
        { contentType: 'audio/mp4;codecs="ac-3"' },
        { contentType: 'audio/mp4;codecs="ec-3"' },
      ],
      videoCapabilities: [
        { contentType: 'video/mp4;codecs="avc1.42E01E"' },
        { contentType: 'video/mp4;codecs="avc1.42E01F"' },
      ],
    }];
    const videoEl = document.createElement('video');

    const drmClasses = [Widevine, Fairplay];
    const drmChecks = drmClasses.map(async (drmClass) => drmClass.testSupport(videoEl, mediaFormats));

    const responses = await Promise.all(drmChecks);
    let supportedDRM = null;

    for (const idx in responses) {
      if (responses[idx] === true) {
        supportedDRM = drmClasses[idx];
        break;
      }
    }

    return supportedDRM;
  }
}
