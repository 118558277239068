import api from '@api/api.js';

export const getUrl = 'devices?downloaders=1';
export const deleteUrl = 'device/deauthorize';

export const getDefaults = () => ({
  devices: [],
  error: null,
  fetching: false,
  fetched: false,
});

const state = getDefaults();

export const actions = {
  async getUserDownloads ({ commit }) {
    commit('setFetching', true);
    try {
      const response = await api.get(getUrl);
      commit('setUserDownloads', response);
    } catch (error) {
      commit('setError', error);
    }
    commit('setFetching', false);
    commit('setFetched', true);
  },

  async removeDevice ({ commit }, deviceId) {
    commit('setFetching', true);
    commit('setDeleting', { id: deviceId, value: true });
    try {
      await api.del(`${deleteUrl}/${deviceId}`);
      commit('removeDevice', deviceId);
    } catch (error) {
      commit('setError', error);
      commit('setDeleting', { id: deviceId, value: false });
    } finally {
      commit('setFetching', false);
    }
  },
};

export const mutations = {
  setUserDownloads (state, userDownloads) {
    state.devices = userDownloads && userDownloads.devices ?
      userDownloads.devices : [];
  },
  removeDevice (state, deviceId) {
    state.devices = state.devices.filter((device) => device.id !== deviceId);
  },
  setFetching (state, fetching) {
    state.fetching = fetching;
  },
  setFetched (state, fetched) {
    state.fetching = fetched;
  },
  setError (state, error) {
    state.error = error;
  },
  setDeleting (state, payload) {
    const deletingDevice = state.devices.find((device) => device.id === payload.id);
    deletingDevice.deleting = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
