import BaseShaka from '@player/drm/base-shaka.js';

export default class Widevine extends BaseShaka {
  constructor (videoEl) {
    super(videoEl);
    this.__serverCertificatePath = '/assets/common/drm-certs/cert_license_widevine_com.bin';
    this.__keySystem = Widevine.keySystem;
  }

  /**
   * Returns string for uri param on startplay request.
   * @returns {string}
   */
  static get uriRequestParam () {
    return 'WIDEVINE';
  }

  static get keySystem () {
    return 'com.widevine.alpha';
  }

  /**
    * Async DRM support check. Returns a boolean.
    * @returns {Boolean} true/false browser supports Widevine
   */
  static async testSupport (video, mediaTypeConfig) {
    if (video.mediaKeys) {
      // Not 100% sure what this check does, need to research - this was in every feature detection example I saw
      return false;
    } else {
      // Chrome and Firefox support Encrypted Media Extensions natively (this is doubled up with Playready)
      if (window.navigator.requestMediaKeySystemAccess && typeof window.navigator.requestMediaKeySystemAccess === 'function') {
        try {
          await window.navigator.requestMediaKeySystemAccess(Widevine.keySystem, mediaTypeConfig);
          return true;
        } catch {
          return false;
        }
      } else {
        // EME native not supported, Widevine is not usable
        return false;
      }
    }
  }

  /**
   * Returns the appropriate license server URL for hd or sd.
   * @returns {string}
   */
  get licenseServerUrl () {
    if (!this.__startPlay) {
      throw new Error('startPlay not set');
    }

    const licenseUrl = this.__startPlay.licenseUrl.split('.com')[1];

    if (this.__definition === 'hd') {
      if (process.env.NODE_ENV === 'development') {
        return `${licenseUrl}?refid=${this.__startPlay.refid}&authToken=${btoa('bite my shiny metal drm')}`;
      }
      return `${licenseUrl}?refid=${this.__startPlay.refid}&authToken=${btoa(this.__startPlay.entitlement)}`;
    } else {
      if (process.env.NODE_ENV === 'development') {
        return `${licenseUrl}?refid=${this.__startPlay.refid}&authToken=${btoa('bite my shiny metal drm')}&sd=true`;
      }
      return `${licenseUrl}?refid=${this.__startPlay.refid}&authToken=${btoa(this.__startPlay.entitlement)}&sd=true`;
    }
  }
}
