<template>
  <ShoDataElement name="page" :data="data" class="sho-page-data-element" />
</template>

<script>
import ShoDataElement from '@components/analytics/ShoDataElement.vue';

const SUBSECTION_DELIMITER = '|';

/**
 * Creates a ShoDataElement based on a page's biMetadata object.
 */
export default {
  name: 'ShoPageDataElement',
  components: {
    ShoDataElement,
  },
  props: {
    /**
     * Page BI metadata.
     * TODO: Validate value structure - siteSection, subSections
     */
    biMetadata: {
      type: Object,
      default: null,
    },

    /* OR... */

    siteSection: {
      type: String,
      default: null,
    },
    subSections: {
      type: [Array, String],
      default: null,
    },
    subSection: {
      type: String,
      default: null,
    },
  },
  computed: {
    /**
     * Formatted page biMetadata object for DataElement.
     * @returns {Object}
     */
    data () {
      if (this.biMetadata) {
        /* API generated biMetadata objects */
        const subSections = this.biMetadata.subSections;
        return {
          siteSection: this.biMetadata.siteSection,
          subSections: Array.isArray(subSections) ? subSections.join(SUBSECTION_DELIMITER) : subSections,
        };
      }

      /* Build biMetadata object from props */
      let subSections;
      if (this.subSection) {
        subSections = [this.subSection];
      } else if (!Array.isArray(this.subSections)) {
        subSections = [this.subSections];
      } else {
        subSections = this.subSections;
      }

      return {
        siteSection: this.siteSection,
        subSections: subSections.join(SUBSECTION_DELIMITER),
      };
    },
  },
};
</script>
