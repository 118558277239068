import api from '@api/api.js';
import { getSlug } from '@utils/string-manipulation.js';
import Logger from '@utils/logger.js';

const logger = new Logger('navigation');

export const url = 'menu';
const allCategoryType = 'allcategory';

export const defaults = {
  items: [],
};

/**
 * Parses, sanitizes, and constructs routes for the menu
 * @param {Array[Obj]} response - Successful menu API response
 * @returns {Array[Obj]} 
 */
export function parseNavigationResponse (response) {
  const parsed = [];
  response.forEach((item) => {
    const parsedItem = { ...item, route: {} };
    // TODO: Get a 'slug' or text id added to menu items that will never change, so decisions aren't based on 'name'
    const name = item.name && item.name.toLowerCase();
    const type = item.type && item.type.toLowerCase();
    const menuItems = item.menuItems || [];

    if (type === 'default') {
      // Pluck out 'id' from 'ALLCATEGORY' menuItem
      const allCategory = menuItems.find((menuItem) => menuItem.type && menuItem.type.toLowerCase()  === allCategoryType);
      const id = allCategory && allCategory.id;
      const categorySlug = getSlug(name);
      if (name === 'series') {
        parsedItem.route = { name: 'allSeries' };
      } else { // Movies and all genre categories
        parsedItem.route = {
          name: name === 'movies' ? 'movies' : 'category',
          params: name === 'movies' ? {} : { id, categorySlug },
        };
      }
      parsedItem.subMenu = parseSubmenu(categorySlug, id, menuItems);
      delete parsedItem.menuItems;
      parsed.push(parsedItem);
    } else if (type !== 'home' && type !== 'free_full_episodes' && type !== 'live_tv') {
      // Handles Coming Soon; Live is added separately; Home and FFE are ignored entirely
      parsedItem.route = { name: type };
      delete parsedItem.menuItems;
      parsed.push(parsedItem);
    }
  });
  return parsed;
}

/**
 * Order and generate routes for submenu items.
 * @param {string} categorySlug - Parent category, sanitized. For sorting.
 * @param {Array[Obj]} menuItems - Array of submenu objects for processing.
 * @returns {Array[Obj]} 
 */
function parseSubmenu (categorySlug, categoryId, menuItems) {
  // Fixed menu order for submenu items
  const subMenus = {
    ALLCATEGORY: [],
    CURRENT_SERIES: [],
    SERIES_LIST: [],
    CATEGORY: [],
    COLLECTION_LIST: [],
  };

  // Menu items come back sorted by name, categorize but otherwise leave sorting intact
  menuItems.forEach((menuItem) => {
    const type = menuItem.type;
    switch (type) {
      case 'ALLCATEGORY':
      case 'SERIES_LIST':
      case 'CATEGORY':  
        if (categorySlug === 'series') {
          menuItem.route = { name: 'allSeries' };
        } else if (categorySlug === 'movies') {
          menuItem.route = { name: 'movies', params: { collectionId: menuItem.id } };
        } else {
          const subCategorySlug = getSlug(menuItem.name);
          menuItem.route = { name: 'category', params: { id: categoryId, categorySlug, subCategorySlug } };
        }
        subMenus[type].push(menuItem);
        break;
      case 'CURRENT_SERIES':
        menuItem.route = { name: 'currentSeries' };
        subMenus[type].push(menuItem);
        break;
      case 'COLLECTION_LIST':
        menuItem.route = { name: 'curatedLists' };
        subMenus[type].push(menuItem);
        break;
    }
  });

  // Iterate through submenu categories, delete keys with empty arrays
  for (const key in subMenus) {
    if (subMenus[key].length === 0) {
      delete subMenus[key]; 
    } 
  }

  // Flatten the subMenus object since we're not dealing with the full nested menu
  return Object.values(subMenus).reduce((acc, val) => {
    acc.push(...val);
    return acc;
  }, []);
}

const state = { ...defaults };

export const getters = {
  collapsedItems (state) {
    return [
      ...state.items.map((item) => ({
        id: item.id,
        name: item.name,
        route: item.route,
        type: item.type,
      })),
      {
        id: 'live_tv',
        name: 'Live TV',
        route: { name: 'liveTV' },
      },
      {
        id: 'collections',
        name: 'Collections',
        route: { name: 'curatedLists' },
      },
    ];
  },
  allMenuItems (state) {
    return [
      ...state.items,
    ];
  },
};

export const actions = {
  getNavigation ({ commit, state }) {
    // Skip API hit if data already exists
    if (!state.items || !state.items.length) {
      api.get(url)
        .then((response) => {
          commit('setNavigation', parseNavigationResponse(response));
        })
        .catch((error) => {
          logger.error('Menu fetch error', error);
        });
    }
  },
};

export const mutations = {
  setNavigation (state, items) {
    state.items = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
