import api from '@api/api.js';
import { errorCount, setError, clearErrors, setField } from '@utils/forms.js';

import Logger from '@utils/logger.js';
const logger = new Logger('confirm-password');

export const passwordConfirmation = 'user/authentication';

export const state = {
  errors: {
    password: null,
  },
  password: null,
  success: false,
};

export const getters = {
  errorCount,
};

export const mutations = {
  setField,
  clearErrors,
  setError,
};

export const actions = {
  async submit ({ commit, state }, emailAddress) {
    commit('clearErrors');
    commit('setField', { field: 'success', value: false });
    try {
      const payload = {
        email: emailAddress,
        password: state.password,
      };
      const response = await api.post(passwordConfirmation, payload);
      logger.log('password confirm response:', response);
      commit('setField', { field: 'success', value: true });
    } catch (error) {
      logger.log('error?', error);
      commit('setError', { field: 'password', error });
    }
  },

  resetState ({ commit }) {
    commit('clearErrors');
    commit('setField', { field: 'success', value: false });
    commit('setField', { field: 'password', value: null });
  },

  setField ({ commit }, payload) {
    commit('setField', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
