import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('promos');

const promosUrl = 'promoted';

export const defaults = {
  promotions: null,
};

const state = { ...defaults };


const actions = {
  getPromos ({ commit, state }) {
    if (!state.promotions || !Object.keys(state.promotions).length) {
      api.get(`${promosUrl}`).then((response) => {
        commit('setPromos', response);
      })
        .catch((error) => {
          // log the error here; Series Page will not display 
          // promos carousel if this call fails, since it just
          // pulls data from state.
          logger.error(error);
        });
    }
  },
};

export const mutations = {
  setPromos (state, response) {
    state.promotions = response;
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
